import React from "react";
import PropTypes from "prop-types";

import * as advertisingService from "../../bl/advertisingService";

class AdvertisingBoundary extends React.Component {
  componentDidMount() {
    const { showLightVersion } = this.props;

    if (showLightVersion) {
      const settings = window.AtiUserData_AppSettings || {};
      settings.NoPrestige = true;
    }
  }

  componentDidUpdate() {
    const {
      showResults,
      showLightVersion,
    } = this.props;

    if (showResults && !showLightVersion) {
      advertisingService.rotateAdvertising();
    }
  }

  render() {
    return null;
  }
}

AdvertisingBoundary.propTypes = {
  showResults: PropTypes.bool.isRequired,
  showLightVersion: PropTypes.bool.isRequired,
};

export default AdvertisingBoundary;
