import cookies from "browser-cookies";

import { baseUrl } from "./urls";

export const COOKIE_NAME = "uicult";

let language = null;

export default {
  getLanguage: () => {
    if (!language) {
      language = cookies.get(COOKIE_NAME);
    }
    return language;
  },
  setLanguage: lang =>
    cookies.set(COOKIE_NAME, lang, {
      expires: new Date().setFullYear(new Date().getFullYear() + 1),
      domain: `.${baseUrl}`,
    }),
};
