import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";

import Card from "ati-ui-react/components/Card";

import FilterPanel from "../FilterPanel";
import RouteSummary from "../RouteSummary";
import RouteStatistics from "../RouteStatistics";
import FindButtonsPanel from "../FindButtonsPanel";
import Route from "../Route";
import RouteActions from "../RouteActions";
import Footer from "../Footer";
import SeoText from "../SeoText";
import ToggleRouteStatisticsPanelButton from "../ToggleRouteStatisticsPanelButton";
import Banner from "../Banner";

import styles from "./Sidebar.scss";

class Sidebar extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { showResults } = this.props;

    if (nextProps.showResults !== showResults) {
      this.scrollTop = true;
    }
  }

  componentDidUpdate() {
    const { showResults } = this.props;
    if (showResults && !!this.scrollTop) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.scrollTop = false;
    }
  }

  render() {
    const { showResults, sidebarToggled } = this.props;

    return (
      <div className={styles.sidebarWrapper}>
        {showResults && <RouteSummary />}
        <Card
          className={classnames(styles.sidebar, {
            [styles.searchResultsSidebar]: showResults,
            [styles.filterSidebar]: !showResults,
            [styles.taped]: sidebarToggled,
          })}
        >
          {!showResults && <FilterPanel />}
          {showResults && <RouteStatistics />}
          <div
            id="docRoute300x100"
            className={classnames(styles.doc300x100, {
              [styles.hideOnMapTap]: sidebarToggled,
            })}
          >
            <Banner bannerSizeId={16} />
          </div>

          {showResults && (
            <React.Fragment>
              <FindButtonsPanel className={styles.hideOnMapTap} />
              <RouteActions className={styles.hideOnMapTap} />
              <Route className={styles.hideOnMapTap} />
              <SeoText className={styles.hideOnMapTap} />
            </React.Fragment>
          )}
          <Footer className={styles.hideOnMapTap} />
          {sidebarToggled && <ToggleRouteStatisticsPanelButton />}
        </Card>
      </div>
    );
  }
}

Sidebar.propTypes = {
  showResults: PropTypes.bool.isRequired,
  sidebarToggled: PropTypes.bool.isRequired,
};

export default withNamespaces()(Sidebar);
