import ParsePointsUrlParametersResult from "./ParsePointsUrlParametersResult";
import ParsedPoint from "./ParsedPoint";

const URL_PARAMETERS_DEVIDER_SIGN = "+";

class PointUrlParameterParser {
  constructor(pointParams) {
    this.pointParams = pointParams;
  }

  static parse = paramsStr => {
    if (
      paramsStr == null ||
      paramsStr === "" ||
      paramsStr.length <= 1 ||
      !paramsStr.includes(URL_PARAMETERS_DEVIDER_SIGN)
    ) {
      return ParsePointsUrlParametersResult.fail();
    }
    const unparsedPoints = paramsStr.split(URL_PARAMETERS_DEVIDER_SIGN);
    if (unparsedPoints.length <= 1) {
      return ParsePointsUrlParametersResult.fail();
    }
    const parsedPoints = [];
    for (let i = 0; i < unparsedPoints.length; i += 1) {
      const unparsedPoint = unparsedPoints[i];
      const parseResult = ParsedPoint.parse(unparsedPoint);
      if (!parseResult.success) {
        return ParsePointsUrlParametersResult.fail();
      }
      parsedPoints.push(parseResult.point);
    }
    return ParsePointsUrlParametersResult.success(parsedPoints);
  };
}

export default PointUrlParameterParser;
