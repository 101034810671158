import React from "react";

import language from "../../utils/language";
import { LANGUAGES, LANGUAGES_FIRMS } from "../../constants";
import api from "../../api";
import styles from "./LanguageSwitcher.scss";

class LanguageSwitcher extends React.Component {
  setRussianLanguage = async () => {
    this.setNotificationLanguage(LANGUAGES_FIRMS.rus).finally(() => {
      this.setLanguage(LANGUAGES.rus);
    });
  }

  setEnglishLanguage = async () => {
    this.setNotificationLanguage(LANGUAGES_FIRMS.eng).finally(() => {
      this.setLanguage(LANGUAGES.eng);
    });
  }

  setLanguage = lang => {
    language.setLanguage(lang);
    window.location.reload();
  };

  setNotificationLanguage = async (lang) => {
    await api.firms.setLanguage(lang);
  }

  render() {
    return (
      <div className={styles.languageSwitcher}>
        <div className={styles.ru} onClick={this.setRussianLanguage} tabIndex={0} role="button">
          <div className={styles.icon} />
          <span className={styles.label}>RUS</span>
        </div>
        <div className={styles.en} onClick={this.setEnglishLanguage} tabIndex={0} role="button">
          <div className={styles.icon} />
          <span className={styles.label}>ENG</span>
        </div>
      </div>
    );
  }
}


export default LanguageSwitcher;
