import {LANGUAGES, MAP_POINT_TYPES} from "../../constants";

export const getSpecialIcon = (type, language) => {
  switch (type) {
    case MAP_POINT_TYPES.START_POINT:
      return {
        path:
          language === LANGUAGES.rus
            ? "https://files.ati.su/images/trace/map-start-icon.svg"
            : "https://files.ati.su/images/trace/map-start-icon-en.svg",
        size: [67, 63],
        iconAnchor: [37, 48],
        zIndex: 1100,
      };
    case MAP_POINT_TYPES.LOADING_POINT:
      return {
        path: "https://files.ati.su/images/trace/map-loading-icon.svg",
        size: [67, 60],
        iconAnchor: [39, 55],
        zIndex: 1200,
      };
    case MAP_POINT_TYPES.START_WITH_LOADING_POINT:
      return {
        path:
          language === LANGUAGES.rus
            ? "https://files.ati.su/images/trace/map-start-loading-icon.svg"
            : "https://files.ati.su/images/trace/map-start-loading-icon-en.svg",
        size: [67, 60],
        iconAnchor: [38, 56],
        zIndex: 1100,
      };
    case MAP_POINT_TYPES.END_POINT:
      return {
        path:
          language === LANGUAGES.rus
            ? "https://files.ati.su/images/trace/map-end-icon.svg"
            : "https://files.ati.su/images/trace/map-end-icon-en.svg",
        size: [67, 60],
        iconAnchor: [37, 46],
        zIndex: 1400,
      };
    case MAP_POINT_TYPES.UNLOADING_POINT:
      return {
        path: "https://files.ati.su/images/trace/map-unloading-icon.svg",
        size: [67, 60],
        iconAnchor: [39, 55],
        zIndex: 1300,
      };
    case MAP_POINT_TYPES.END_WITH_UNLOADING_POINT:
      return {
        path:
          language === LANGUAGES.rus
            ? "https://files.ati.su/images/trace/map-end-unloading-icon.svg"
            : "https://files.ati.su/images/trace/map-end-unloading-icon-en.svg",
        size: [67, 60],
        iconAnchor: [38, 56],
        zIndex: 1300,
      };
    case MAP_POINT_TYPES.INTERMEDIATE_POINT:
      return {
        size: [57, 43],
        iconAnchor: [30, 39],
        zIndex: 1000,
      };
    case MAP_POINT_TYPES.CUSTOMS:
      return {
        path: "https://files.ati.su/images/trace/map-customs-icon.svg",
        size: [57, 43],
        iconAnchor: [32, 40],
        zIndex: 1300,
      };
    case MAP_POINT_TYPES.COMPOSITE_POINT:
      return {
        path: "https://files.ati.su/images/trace/marker-icon-multiple.svg",
        size: [57, 43],
        iconAnchor: [32, 40],
        zIndex: 1000,
      };
    default:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-6.svg",
        size: [22, 22],
        zIndex: 900,
      };
  }
};

export const getCityIcon = size => {
  switch (size) {
    case 0:
    case 1:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-1.svg",
        size: [10, 10],
        zIndex: 0,
      };
    case 2:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-2.svg",
        size: [13, 13],
        zIndex: 200,
      };
    case 3:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-3.svg",
        size: [16, 16],
        zIndex: 400,
      };
    case 4:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-4.svg",
        size: [16, 16],
        zIndex: 600,
      };
    case 5:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-5.svg",
        size: [19, 19],
        zIndex: 800,
      };
    case 6:
    default:
      return {
        path: "https://files.ati.su/images/trace/cities/city-size-6.svg",
        size: [22, 22],
        zIndex: 900,
      };
  }
};

export const getMarkerIconType = routeSegmentCityPoint => {
  if(routeSegmentCityPoint.isCustomsPoint() && routeSegmentCityPoint.isCommonPoint()) {
    // таможню, что просто проезжаем мимо, показываем как таможню, а не как составной значок.
    return MAP_POINT_TYPES.CUSTOMS;
  }
  if (routeSegmentCityPoint.visits.length > 1 || routeSegmentCityPoint.visits[0].types.length > 1) {
    // если есть визит не в качестве "проезжаем мимо", а целенаправленный, только тогда ставим COMPOSITE_POINT
    if (routeSegmentCityPoint.isSpecialPoint())
      return MAP_POINT_TYPES.COMPOSITE_POINT;
    return MAP_POINT_TYPES.COMMON_POINT;
  }

  return routeSegmentCityPoint.visits[0].types[0];
};

export const getIconData = (routeSegmentCityPoint, language) => {
  const markerType = getMarkerIconType(routeSegmentCityPoint);

  return markerType === MAP_POINT_TYPES.COMMON_POINT
    ? getCityIcon(routeSegmentCityPoint.city.size)
    : getSpecialIcon(markerType, language);
};
