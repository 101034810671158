import memoize from "memoize-one";

const getRouteBounds = routeSegments => {
  if (!routeSegments || routeSegments.length === 0) {
    return null;
  }

  const bounds = {
    lonMin: 180,
    lonMax: -180,
    latMin: 90,
    latMax: -90
  };

  if(routeSegments.length === 2 && routeSegments[0].visualRouteSegments.length === 0) {
    // это сегменты от "пустого" маршрута, в случае, когда маршрут не найден
    const fromGeoPoint = routeSegments[0].startPoint.geoPoint;
    const toGeoPoint = routeSegments[1].startPoint.geoPoint;
    return [fromGeoPoint, toGeoPoint];
  }
  
  for (let i = 0; i < routeSegments.length; i += 1) {
    const routeSegment = routeSegments[i];
    for(let j = 0; j < routeSegment.visualRouteSegments.length; j += 1) {
      const visualRouteSegment = routeSegment.visualRouteSegments[j];
      for (let k = 0; k < visualRouteSegment.geoPoints.length; k += 1) {
        const point = visualRouteSegment.geoPoints[k];
        const lat = point[0];
        const lon = point[1];
        if(lon < bounds.lonMin) {
          bounds.lonMin = lon;
        }
        if(lon > bounds.lonMax) {
          bounds.lonMax = lon;
        }
        if(lat < bounds.latMin) {
          bounds.latMin = lat;
        }
        if(lat > bounds.latMax) {
          bounds.latMax = lat;
        }
      }
    }
  }

  return [[bounds.latMin, bounds.lonMin], [bounds.latMax, bounds.lonMax]];
};

const getMapBounds = memoize(points => getRouteBounds(points));

export default getMapBounds;
