import React from "react";
import styles from "../RouteRow/RouteRow.scss";

const RouteDepartureSeparator = ({ countryName }) => {
  return (
    <div className={styles.departureCountry}>
      <div className={styles.region}>{countryName}</div>
      <div className={styles.separator} />
    </div>
  );
};

export default RouteDepartureSeparator;
