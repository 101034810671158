/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import RouteRow from "../RouteRow";
import ReportError from "../ReportError";
import RouteDepartureSeparator from "../RouteDepartureSeparator";
import styles from "./Route.scss";
import {groupRouteSegmentsBySize} from "../../bl/routeService";

export default class Route extends React.Component {
  handleExcludeCity = cityId => {
    this.props.excludeCity(cityId);
  };

  renderRoutes(routeSegments) {
    const lastCountryId = routeSegments[routeSegments.length - 1].startPoint.country.id;
    return routeSegments.map((routeSegment, index) => {
      return (
          // eslint-disable-next-line react/no-array-index-key,react/jsx-first-prop-new-line
        <RouteRow key={`${routeSegment.startPoint.geoPoint[0]}_${routeSegment.startPoint.geoPoint[1]}_${index}`}
          routeSegment={routeSegment}
          handleExcludeCity={this.handleExcludeCity}
          lastCountryId={lastCountryId}
        />
      );
    });
  }

  render() {
    const {className, showOnlyLargeCities} = this.props;
    const routeSegments = showOnlyLargeCities ? groupRouteSegmentsBySize(this.props.routeSegments) : this.props.routeSegments;
    const firstPointCountryName = routeSegments[0].startPoint.country.name;

    return (
      <div className={classnames(styles.container, className)}>
        <RouteDepartureSeparator countryName={firstPointCountryName} />
        {
          this.renderRoutes(routeSegments)
        }
        <ReportError />
      </div>
    );
  }
}

Route.defaultProps = {
  className: "",
};

Route.propTypes = {
  className: PropTypes.string,
  excludeCity: PropTypes.func.isRequired
};
