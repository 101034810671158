import { connect } from "react-redux";

import RouteActions from "./RouteActions";

import {
  updatePreferencesAndCalculateRoute,
  resetPreferencesAndCalculateRoute,
  toggleShowOnlyLargeCities,
} from "../../store/actions";

const mapStateToProps = state => ({
  showOnlyLargeCities: state.restrictions.showOnlyLargeCities,
  preferences: {
    delays: state.delays,
    speeds: state.speeds,
  },
  calculations: state.calculations,
  showPreferencesPopup: state.showPreferencesPopup
});

const mapDispatchToProps = dispatch => ({
  toggleShowOnlyLargeCities: checked => dispatch(toggleShowOnlyLargeCities(checked)),
  updatePreferences: preferences => dispatch(updatePreferencesAndCalculateRoute(preferences)),
  resetPreferences: () => dispatch(resetPreferencesAndCalculateRoute()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteActions);
