import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";

import DatePicker from "ati-ui-react/components/DatePicker";
import DatePickerCustomInput from "ati-ui-react/components/DatePicker/DatePickerCustomInput";

import styles from "./FormikDatepicker.scss";

class FormikDatepicker extends Component {
  handleChange = value => {
    const { name, onChange } = this.props;

    onChange(name, value);
  };

  handleBlur = () => {
    const { name, onBlur } = this.props;

    onBlur(name, true);
  };

  render() {
    const { date, error, t, i18n } = this.props;

    const className = classnames(error ? styles.error : "", styles.datePicker);

    return (
      <div className={className}>
        <DatePicker
          name="departureDate"
          customInput={<DatePickerCustomInput />}
          selected={date}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          locale={i18n.language}
          isClearable
          placeholderText={t("trace-filters-filter-departure-placeholder", "выберите дату")}
          allowSameDay
          dateFormat="DD.MM.YYYY"
          minDate={moment()}
          maxDate={moment().add(10, "years")}
          // popperPlacement={this.state.width < 1050 ? 'bottom-end' : 'bottom-start'}
          popperModifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              padding: 0,
            },
          }}
        />
      </div>
    );
  }
}

FormikDatepicker.defaultProps = {
  date: null,
  error: false,
};

FormikDatepicker.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.any, // eslint-disable-line
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object, // eslint-disable-line
};

export default withNamespaces()(FormikDatepicker);
