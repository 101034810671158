import { generateRandomId } from "../utils";

export const mapKeyPointsToSuggestions = (direction = [], cities = []) => {
  const citiesMap = {};

  cities.forEach(city => {
    citiesMap[city.id] = city;
  });

  const suggestions = direction.map(point => {
    const { id, ...pointTypes } = point;
    const city = citiesMap[point.id];
    return {
      id: generateRandomId(),
      value: city.fullName,
      ...pointTypes,
      suggestion: {
        id: city.id,
        name: city.name,
        text: city.fullName,
        countryId: city.country.id,
      },
    };
  });

  return suggestions;
};

export const mergeRouteDirection = (newDirectionPartName, newDirectionPart, oldDirection) => {
  if (newDirectionPartName === "from") {
    return {
      ...oldDirection,
      from: {
        ...newDirectionPart[0],
      },
    };
  }
  if (newDirectionPartName === "to") {
    return {
      ...oldDirection,
      to: {
        ...newDirectionPart[0],
      },
    };
  }
  if (newDirectionPartName === "through") {
    let from = null;
    let to = null;
    const through = [...newDirectionPart];
    const firstPoint = through[0];
    if (firstPoint.isStartPoint || firstPoint.isLoadingPoint) {
      from = through.shift();
    } else {
      from = oldDirection.from; // eslint-disable-line
    }

    const lastPoint = through[through.length - 1];
    if (lastPoint && (lastPoint.isEndPoint || lastPoint.isUnloadingPoint)) {
      to = through.pop();
    } else {
      to = oldDirection.to; // eslint-disable-line
    }

    return {
      ...oldDirection,
      from,
      to,
      through,
    };
  }

  return oldDirection;
};
