import "leaflet/dist/leaflet.css";

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import L from "leaflet";
import { Map, TileLayer } from "react-leaflet";

import MapCities from "../MapCities";
import MapRoute from "../MapRoute";
import MapControls from "../MapControls";

import styles from "./Map.scss";

import getMapBounds from "./Map.bl";
import MIN_ZOOM from "../../constants/zoomValues";
import { headerBottomHeight } from "../../utils";
import {groupRouteSegmentsBySize} from "../../bl/routeService";

const MapDefaultPadding = {
  leftWithSidebar: 345,
  left: 60,
  right: 60,
  top: 32,
  bottom: 32,
};

const PhoneVersionWidth = 559;

class AtiMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 59.939738,
      lng: 30.334514,
      zoom: 13,
      isMobileVersion: this.isPhoneDimensions(),
    };

    const southWest = L.latLng(-85, Number.NEGATIVE_INFINITY);
    const northEast = L.latLng(85, Number.POSITIVE_INFINITY);
    this.maxBounds = L.latLngBounds(southWest, northEast);
    this.resizeTimeout = null;
    this.minZoom = this.getCurrentMinZoom();
  }

  componentDidMount() {
    const { props } = this;
    const { showLightVersion } = props;

    if (showLightVersion) {
      props.toggleSidebar();
    }

    const leafletMap = this.map.leafletElement;
    leafletMap.on("zoomend", () => {
      const zoom = leafletMap.getZoom();
      this.setState({ zoom });
    });
    window.addEventListener("resize", this.resizeThrottler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { routeNotFound, sidebarToggled, routeSegments, showOnlyLargeCities } = this.props;

    const { state } = this;

    const zoomChanged = state.zoom !== nextState.zoom;
    const versionChanged = nextState.isMobileVersion !== state.isMobileVersion;
    const showOnlyLargeCitiesChanged = showOnlyLargeCities !== nextProps.showOnlyLargeCities;

    this.zoomChanged = zoomChanged;
    this.versionChanged = versionChanged;

    const routeStatusChanged = nextProps.routeNotFound !== routeNotFound ||
      (nextProps.sidebarToggled !== sidebarToggled && nextState.isMobileVersion);

    if (zoomChanged || versionChanged || routeStatusChanged || showOnlyLargeCitiesChanged) {
      return true;
    }
    
    const notEq = routeSegments !== nextProps.routeSegments
    return notEq;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeThrottler);
  }

  getMapPadding = () => {
    const { sidebarToggled, showLightVersion } = this.props;
    let paddingLeft = showLightVersion ? MapDefaultPadding.left : MapDefaultPadding.leftWithSidebar;
    let paddingBottom = MapDefaultPadding.bottom;
    let paddingTop = MapDefaultPadding.top;
    let paddingRight = MapDefaultPadding.right;
    const mapVisiblePart = L.Browser.mobile ? 320 : 300;

    if (this.isPhoneDimensions()) {
      paddingLeft = 30;
      paddingTop = 110;
      paddingBottom = 100;
      if (!sidebarToggled) {
        paddingRight = 30;
        const height = L.Browser.mobile ? window.screen.height : window.innerHeight;
        paddingBottom = height - mapVisiblePart;
      }
    }
    return {
      paddingTopLeft: [paddingLeft, paddingTop],
      paddingBottomRight: [paddingRight, paddingBottom],
    };
  };

  getCurrentMinZoom = () => MIN_ZOOM;

  actualResizeHandler = () => {
    this.setState({ isMobileVersion: this.isPhoneDimensions() });
  };

  isPhoneDimensions = () => document.documentElement.clientWidth < PhoneVersionWidth;

  resizeThrottler = () => {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.actualResizeHandler();
      }, 66);
    }
  };

  handleReturnToRoute = (routeSegments) => {
    const leftPadding = this.getMapPadding();
    const bounds = getMapBounds(routeSegments);
    if (bounds) {
      this.map.leafletElement.fitBounds(bounds, leftPadding);
    }
  };

  handleZoomIn = () => {
    this.map.leafletElement.zoomIn();
  };

  handleZoomOut = () => {
    this.map.leafletElement.zoomOut();
  };

  toggleSidebar = e => {
    const { props } = this;
    const { showLightVersion } = props;

    if (!e.originalEvent.defaultPrevented && !showLightVersion) {
      props.toggleSidebar();
    }
  };

  getRouteSegmentCityPoints = routeSegments => {
    const set = new Set();
    for (let i = 0; i < routeSegments.length; i += 1) {
      const routeSegment = routeSegments[i];
      set.add(routeSegment.startPoint);
    }
    return Array.from(set);
  };

  render() {
    const { isWelcomeScreen, routeNotFound, sidebarToggled, showLightVersion, showOnlyLargeCities } = this.props;
    const routeSegments = showOnlyLargeCities && !routeNotFound ? groupRouteSegmentsBySize(this.props.routeSegments) : this.props.routeSegments;
    const points = this.getRouteSegmentCityPoints(routeSegments);
    const { lat, lng, zoom, isMobileVersion } = this.state;
    const position = [lat, lng];
    const showRoute = !routeNotFound && points.length > 0 && routeSegments;

    const blurMap = isWelcomeScreen && !showLightVersion;

    const headerHeight = headerBottomHeight();
    const size = `calc(100vh - ${headerHeight}px)`;

    const showZoomButtons = !blurMap && ((sidebarToggled && isMobileVersion) || !isMobileVersion);
    const routeBounds = getMapBounds(routeSegments);

    const mapPadding = this.getMapPadding();

    const bounds = !!this.zoomChanged || routeBounds === null ? undefined : routeBounds;

    if (this.map && !!this.versionChanged) {
      this.map.leafletElement.options.minZoom = this.getCurrentMinZoom();
    }

    const stickyStyle = showLightVersion ? styles.lightVersionSticky : styles.sticky;
    const mapControlsBottom = 29;

    return (
      <div className={L.Browser.mobile ? styles.mobileSticky : stickyStyle}>
        <Map
          fadeAnimation={false} // щоб нативная анимация не мелькала при закрытии попапов и тултипов
          center={position}
          zoom={zoom}
          zoomControl={false}
          style={{ height: size, width: "100%", position: "absolute" }}
          className={classnames(styles.map, {
            [styles.disabledMap]: blurMap,
          })}
          ref={map => {
            this.map = map;
          }}
          minZoom={this.minZoom}
          zoomSnap={0.5}
          zoomDelta={0.5}
          wheelPxPerZoomLevel={70}
          boundsOptions={mapPadding}
          bounds={bounds}
          onclick={this.toggleSidebar}
          maxBoundsViscosity={1.0}
          worldCopyJump
          maxBounds={this.maxBounds}
          scrollWheelZoom={!blurMap}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="//geo.ati.su/osm_tiles/{z}/{x}/{y}.png"
          />
          {showRoute && <MapRoute routeSegments={routeSegments} />}
          <MapCities zoom={zoom} points={points} routeNotFound={routeNotFound} />
          {showZoomButtons && (
            <div className={styles.buttonPanel} style={{ bottom: headerHeight + mapControlsBottom }}>
              <MapControls
                onZoomIn={this.handleZoomIn}
                onZoomOut={this.handleZoomOut}
                onReturnToRoute={() => this.handleReturnToRoute(routeSegments)}
              />
            </div>
          )}
        </Map>
        {blurMap && <div className={styles.disabledLayer} />}
      </div>
    );
  }
}

AtiMap.defaultProps = {
  sidebarToggled: false,
  showLightVersion: false,
};

AtiMap.propTypes = {
  isWelcomeScreen: PropTypes.bool.isRequired,
  routeNotFound: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  sidebarToggled: PropTypes.bool,
  showLightVersion: PropTypes.bool,
};

export default AtiMap;
