export default class ParsePointsResult {
  static success = point => new ParsePointsResult({ success: true, point });

  static fail = () => new ParsePointsResult({ success: false });

  constructor({ success, point }) {
    this.success = success || false;
    this.point = point || null;
  }
}
