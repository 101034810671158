import {VisualRouteSegmentType} from "../bl/RouteSegment";

const ROAD_COLORS = {
  common: "#5baaff",
  platon: "#f56445",
  toll: "#6f1fd9",
};
Object.freeze(ROAD_COLORS);

export default ROAD_COLORS;
export const getVisualSegmentColor = visualRouteSegment => {
  if (visualRouteSegment.type === VisualRouteSegmentType.Toll) {
    return ROAD_COLORS.toll;
  }
  if (visualRouteSegment.type === VisualRouteSegmentType.Platon) {
    return ROAD_COLORS.platon;
  }
  return ROAD_COLORS.common;
}