import logger from "../logger";

export const reachGoal = (goal, product) => {
  try {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    const event = { "event": goal };
    if (product) {
      event.product = product;
    }

    window.dataLayer.push(event);
  } catch (error) {
    logger.captureException(error);
  }
};

export const reachTraceGoal = (goal) => {
  const PRODUCT = "Trace";
  return reachGoal(goal, PRODUCT);
}

export const GOALS = {
  clickFreeRoadsButton: "click_free_roads_button",
  OpenPage: "OpenPage"
};
