import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces, Trans } from "react-i18next";

import Link from "ati-ui-react/components/Link";

import FooterMobileBanner from "../FooterMobileBanner";
import FooterTabletBanner from "../FooterTabletBanner";
import FooterDesktopBanner from "../FooterDesktopBanner";
import LanguageSwitcher from "../LanguageSwitcher";

import styles from "./Footer.scss";

const year = new Date().getFullYear();

const Footer = ({ className, t, showLightVersion }) => {

  return (
    <div className={classnames(styles.footer, className)}>
      <div className={styles.links}>
        <div className={styles.link}>
          <Link type="external" small gray href="/sitemap/trace" target="_blank">
            {t("trace-footer-links-distance-map", "Карта расстояний")}
          </Link>
        </div>
      </div>
      {!showLightVersion && <FooterMobileBanner />}
      {!showLightVersion && <FooterTabletBanner />}
      {!showLightVersion && <FooterDesktopBanner />}

      <div className={styles.copyright}>
        1998 − {year} © {t("trace-footer-copyright", "«ATI.SU»")}
      </div>
      <div className={styles.description}>
        <Trans i18nKey="trace-footer-policy">
          Алгоритм расчета расстояний базируется на&nbsp;данных, взятых из различных атласов автомобильных дорог.
          Администрация сайта не несёт ответственности за достоверность данной информации. Это необходимо учитывать
          при
          планировании маршрута и&nbsp;руководствоваться фактическими&nbsp; показателями счетчиков пробега
          при&nbsp;расчёте стоимости транспортных услуг.
        </Trans>
      </div>
      <LanguageSwitcher />
    </div>
  );
};


Footer.defaultProps = {
  className: "",
  showLightVersion: false,
};

Footer.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  showLightVersion: PropTypes.bool,
};

export default withNamespaces()(Footer);
