import ReduxQuerySync from "redux-query-sync";

import { ROUTE_POINT_TYPES, emptyArray } from "../../constants";

import { parseDirectionQueryString, mapDirectionToQueryString } from "../../utils";

import {
  setDirectionInitialValue,
  setRestrictionInitialValue,
  setExcludedCountriesInitialValue,
  setExcludedCitiesInitialValue,
  setShowLightVersion,
} from "../actions";

const enhancer = ReduxQuerySync.enhancer({
  params: {
    ExcludeCities: {
      selector: state => state.excludedCities,
      action: ids => setExcludedCitiesInitialValue(ids),
      stringToValue: str =>
        str
          .split(";")
          .filter(Number)
          .map(id => parseInt(id, 10)),
      valueToString: value => (value.length === 0 ? "" : value.map(city => city.cityId).join(";")),
      defaultValue: emptyArray,
    },
    ExcludeCountries: {
      selector: state => state.excludedCountries,
      action: ids => setExcludedCountriesInitialValue(ids),
      stringToValue: str =>
        str
          .split(";")
          .filter(Number)
          .map(id => parseInt(id, 10)),
      valueToString: value => (value.length === 0 ? "" : value.map(country => country.countryId).join(";")),
      defaultValue: emptyArray,
    },
    City1: {
      selector: () => undefined,
      action: value =>
        setDirectionInitialValue({
          name: "from",
          items: value,
        }),
      stringToValue: str => parseDirectionQueryString(`${ROUTE_POINT_TYPES.START_POINT}_${str}`),
      valueToString: value => value,
      defaultValue: undefined,
    },
    City5: {
      selector: () => undefined,
      action: value =>
        setDirectionInitialValue({
          name: "to",
          items: value,
        }),
      stringToValue: str => parseDirectionQueryString(`${ROUTE_POINT_TYPES.END_POINT}_${str}`),
      valueToString: value => value,
      defaultValue: undefined,
    },
    Cities: {
      selector: state => state.direction,
      action: value =>
        setDirectionInitialValue({
          name: "through",
          items: value,
        }),
      stringToValue: str => parseDirectionQueryString(str),
      valueToString: value => mapDirectionToQueryString(value),
      defaultValue: emptyArray,
    },
    Ferries: {
      selector: state => state.restrictions.allowFerries,
      action: value => {
        const name = "allowFerries";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: true,
    },
    Zimnik: {
      selector: state => state.restrictions.allowWinterRoads,
      action: value => {
        const name = "allowWinterRoads";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: false,
    },
    ExcludeTollRoads: {
      selector: state => state.restrictions.excludeTollRoads,
      action: value => {
        const name = "excludeTollRoads";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: false,
    },
    FastWay: {
      selector: state => state.restrictions.fastWay,
      action: value => {
        const name = "fastWay";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: true,
    },
    ExcludeMkad: {
      selector: state => state.restrictions.excludeMkad,
      action: value => {
        const name = "excludeMkad";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: false,
    },
    UseBelarusRule: {
      selector: state => state.restrictions.useBelarusRule,
      action: value => {
        const name = "useBelarusRule";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: false,
    },
    WithinCountry: {
      selector: state => state.restrictions.withinCountry,
      action: value => {
        const name = "withinCountry";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: true,
    },
    LargeCities: {
      selector: state => state.restrictions.showOnlyLargeCities,
      action: value => {
        const name = "showOnlyLargeCities";
        return setRestrictionInitialValue({ name, value });
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: true,
    },
    LightVersion: {
      selector: state => state.showLightVersion,
      action: value => {
        return setShowLightVersion(value);
      },
      stringToValue: str => Boolean(Number(str)),
      valueToString: value => (value ? 1 : 0),
      defaultValue: false,
    },
  },
  initialTruth: "location",
  replaceState: true,
});

export default enhancer;
