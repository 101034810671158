/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";

import Checkbox from "ati-ui-react/components/Checkbox";

import styles from "./RouteActions.scss";

class RouteActions extends React.Component {

  handleCheckboxChange = checked => {
    this.props.toggleShowOnlyLargeCities(checked);
  };

  render() {
    const {
      className,
      showOnlyLargeCities,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <div className={classnames(styles.routeParamsContainer, className)}>
          <div className={styles.routeTitle}>{t("trace-route-title", "Подробный маршрут")}</div>
          <div className={styles.routeCheckboxContainer}>
            <Checkbox
              name="showOnlyLargeCities"
              checked={showOnlyLargeCities}
              onChange={e => this.handleCheckboxChange(e.target.checked)}
              label={t("trace-route-show-only-large-cities-label", "Показывать только крупные пункты")}
              size="small"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RouteActions.defaultProps = {
  className: "",
};

RouteActions.propTypes = {
  className: PropTypes.string,
  showOnlyLargeCities: PropTypes.bool.isRequired,
  toggleShowOnlyLargeCities: PropTypes.func.isRequired,
  calculations: PropTypes.shape({
    workingDay: PropTypes.number.isRequired,
    workDayStartHour: PropTypes.number.isRequired,
    workDayStartMinute: PropTypes.number.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteActions);
