import React from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";

import Link from "ati-ui-react/components/Link";

import styles from "./RouteExcluded.scss";

const RouteExcluded = props => {
  const {
    excludedCities,
    excludedCountries,
    returnCityToRoute,
    returnCountryToRoute,
    resetExcludedAndCalculateRoute,
    t,
  } = props;

  if (excludedCities.length === 0 && excludedCountries.length === 0) {
    return null;
  }

  const renderExcludedCities = () =>
    excludedCities.map(({ cityName, cityId }) => (
      <div className={styles.excludedCity} key={cityId}>
        <div // eslint-disable-line
          className={styles.excludeIcon}
          title={t("trace-route-excluded-entries-remove-button-text", "Вернуть точку в маршрут")}
          role="button"
          onClick={() => returnCityToRoute(cityId)}
        />
        <div>{cityName}</div>
      </div>
    ));

  const renderExcludedCountries = () =>
    excludedCountries.map(({ countryName, countryId }) => (
      <div className={styles.excludedCountry} key={countryId}>
        <div // eslint-disable-line
          className={styles.excludeIcon}
          title={t("trace-route-excluded-entries-remove-button-text", "Вернуть точку в маршрут")}
          role="button"
          onClick={() => returnCountryToRoute(countryId)}
        />
        <div>{countryName}</div>
      </div>
    ));

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <Trans i18nKey="trace-route-excluded-entries-block-title">Исключено из маршрута</Trans>
        </div>
        <div className={styles.link}>
          <Link // eslint-disable-line
            small
            gray
            type="pseudo"
            onClick={resetExcludedAndCalculateRoute}
          >
            <Trans i18nKey="trace-route-excluded-entries-reset-button-text">сбросить</Trans>
          </Link>
        </div>
      </div>
      <div className={styles.excludedCities}>{renderExcludedCities()}</div>
      <div className={styles.excludedCountries}>{renderExcludedCountries()}</div>
    </div>
  );
};

RouteExcluded.propTypes = {
  excludedCities: PropTypes.arrayOf(
    PropTypes.shape({
      cityId: PropTypes.number.isRequired,
      cityName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  excludedCountries: PropTypes.arrayOf(
    PropTypes.shape({
      countryId: PropTypes.number.isRequired,
      countryName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  returnCityToRoute: PropTypes.func.isRequired,
  returnCountryToRoute: PropTypes.func.isRequired,
  resetExcludedAndCalculateRoute: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteExcluded);
