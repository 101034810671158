const { host } = window.location;

export const baseUrl = host.startsWith("trace.") ? host.substring(6) : host;

const isDevelopment = process.env.NODE_ENV === "development";

const buildUrl = (relativeUrl, subdomain) => {
  const prefix = isDevelopment || !subdomain ? "" : `${subdomain}.`;
  return `//${prefix}${baseUrl}${relativeUrl || ""}`;
};

export const getMainUrl = relativeUrl => buildUrl(relativeUrl);

export const getLoadsUrl = relativeUrl => buildUrl(relativeUrl, "loads");

export const getIdUrl = relativeUrl => buildUrl(relativeUrl, "id");

export const getApiUrl = relativeUrl => buildUrl(relativeUrl, "api");

export const getAboutUrl = relativeUrl => buildUrl(relativeUrl, "about");

export const getTrucksUrl = relativeUrl => buildUrl(relativeUrl, "trucks");
