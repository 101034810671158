import React from "react";
import PropTypes from "prop-types";

import Link from "ati-ui-react/components/Link";

import { generateRandomId } from "../../utils";

import styles from "./PopupLink.scss";

class PopupLink extends React.PureComponent {
  static defaultProps = {
    params: "top=150,left=200,width=1000,height=800,resizable=1,scrollbars=1,menubar=0",
    disabled: false,
    onOpenPopup: undefined,
  };

  openPopup = event => {
    const { disabled, href, params, onOpenPopup } = this.props;
    event.preventDefault();
    const popupId = generateRandomId();
    if (!disabled) {
      window.open(href, popupId, params);
      if (onOpenPopup) {
        onOpenPopup(popupId, href);
      }
    }
  };

  render() {
    const { onOpenPopup, params, children, ...linkProps } = this.props;

    return (
      <div // eslint-disable-line
        className={styles.popupLinkWrapper}
        onClick={this.openPopup}
        role="button"
        tabIndex={0}
      >
        <Link {...linkProps}>{children}</Link>
      </div>
    );
  }
}

PopupLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  params: PropTypes.string,
  onOpenPopup: PropTypes.func,
};

export default PopupLink;
