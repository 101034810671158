import pako from "pako";
import {
  ROUTE_POINT_TYPES,
  THIN_SPACE_CHAR
} from "../constants";
import logger from "../logger";
import PointUrlParameterParser from "./PointUrlParameter";
import ParsePointsUrlParametersResult from "./ParsePointsUrlParametersResult";

export const mapSuggestion = suggestion => {
  const {
    id,
    value,
    text,
    country_id, // eslint-disable-line
  } = suggestion;

  return {
    value,
    text,
    id: parseInt(id, 10),
    name: text.split(",")[0],
    countryId: country_id, // eslint-disable-line
  };
};

const hasType = (type, isLegacyLinkMode, expectedType, expectedLegacyType) =>
  isLegacyLinkMode ? type === expectedLegacyType : type === expectedType;

// расшифровывает параметры из encoded => base64 => gzip => text => ParsePointsUrlParametersResult
const decryptParameters = params => {
  const decodedParams = decodeURIComponent(params);
  let parseResult = PointUrlParameterParser.parse(decodedParams);

  if (parseResult.success) {
    return parseResult;
  }

  try {
    const ungzipedParams = pako.ungzip(atob(decodedParams), { to: "string" });
    parseResult = PointUrlParameterParser.parse(ungzipedParams);
  } catch (e) {
    parseResult = new ParsePointsUrlParametersResult({ success: false });
  }
  return parseResult;
};

const checkCoordinates = (geo1, geo2) => {
  return geo1 && geo2 && geo1.lat && geo1.lon && geo2.lat && geo2.lon
    ? geo1.lat === geo2.lat && geo1.lon === geo2.lon
    : false;
};
const isEqualGeoPoints = (p1, p2) => {
  if (!p1 || !p2) {
    return false;
  }
  const result =
    p1.id === p2.id &&
    p1.isEndPoint === p2.isEndPoint &&
    p1.isIntermediatePoint === p2.isIntermediatePoint &&
    p1.isLoadingPoint === p2.isLoadingPoint &&
    p1.isStartPoint === p2.isStartPoint &&
    p1.isUnloadingPoint === p2.isUnloadingPoint;
  // далее, если результат === true, сравниваем Geo-составляющую:
  // если координаты не null и равны координатам другой точки, считаем точки одинаковыми и на адрес не смотрим.
  if (!result) {
    return false;
  }
  return checkCoordinates(p1.geo_point, p2.geo_point) && p1.address === p2.address;
};

export const parseDirectionQueryString = param => {
  try {
    let previousPoint = null;
    let pairs;
    const decryptedParameters = decryptParameters(param);
    if (decryptedParameters.success) {
      pairs = decryptedParameters.parsedPoints;
    } else {
      pairs = param
        .split(/;|,|-/)
        .filter(Boolean)
        .map(pair => {
          const values = pair.split("_");

          if (values.length !== 2) {
            return null;
          }

          const id = parseInt(values[1], 10);
          const type = parseInt(values[0], 10);

          if (Number.isNaN(id)) {
            throw new Error(`${values[1]} is not a number`);
          }

          return {
            cityId: id,
            type,
          };
        });
    }

    const isLegacyLinkMode = pairs.some(
      pair =>
        pair.type === ROUTE_POINT_TYPES.LEGACY_PREBEGIN_POINT || pair.type === ROUTE_POINT_TYPES.LEGACY_POSTEND_POINT,
    );
      const map = pairs
          .map((item, index, array) => {
              const point = {
                  address: item.address,
                  geo_point: item.geo_point,
                  id: item.cityId,
                  isStartPoint:
                      index === 0 &&
                      (item.type === ROUTE_POINT_TYPES.START_POINT ||
                          item.type === ROUTE_POINT_TYPES.LOADING_POINT ||
                          item.type === ROUTE_POINT_TYPES.LEGACY_PREBEGIN_POINT),
                  isEndPoint:
                      index === array.length - 1 &&
                      (item.type === ROUTE_POINT_TYPES.END_POINT ||
                          item.type === ROUTE_POINT_TYPES.UNLOADING_POINT ||
                          item.type === ROUTE_POINT_TYPES.LEGACY_POSTEND_POINT),
                  isIntermediatePoint: item.type === ROUTE_POINT_TYPES.INTERMEDIATE_POINT,
                  isLoadingPoint: hasType(
                      item.type,
                      isLegacyLinkMode,
                      ROUTE_POINT_TYPES.LOADING_POINT,
                      ROUTE_POINT_TYPES.START_POINT,
                  ),
                  isUnloadingPoint: hasType(
                      item.type,
                      isLegacyLinkMode,
                      ROUTE_POINT_TYPES.UNLOADING_POINT,
                      ROUTE_POINT_TYPES.END_POINT,
                  ),
              };
              const pointsAreEqual = isEqualGeoPoints(previousPoint, point);
              if (pointsAreEqual) {
                  // СПБ (загрузка) - ЕКБ (разгрузка и загрузка) - МСК (разгрузка)
                  // e.g.: ?cities=7_1;8_60;7_60;8_3611;
                  previousPoint.isLoadingPoint = point.isLoadingPoint;
                  return null;
              }

              previousPoint = point;

              const skipPoint =
                  !point.isStartPoint &&
                  !point.isEndPoint &&
                  !point.isIntermediatePoint &&
                  !point.isLoadingPoint &&
                  !point.isUnloadingPoint;

              return skipPoint ? null : point;
          });
      const filteredMap = map.filter(Boolean);
      return filteredMap;
  } catch (e) {
    logger.captureException(e);
    return [];
  }
};

export const mapDirectionToQueryString = direction => {
  const items = direction
    .filter(item => item.suggestion)
    .reduce((result, current) => {
      const currentTypes = [];
      if (current.isStartPoint && !current.isLoadingPoint) {
        currentTypes.push(`${ROUTE_POINT_TYPES.START_POINT}_${current.suggestion.id}`);
      }
      if (current.isEndPoint && !current.isUnloadingPoint) {
        currentTypes.push(`${ROUTE_POINT_TYPES.END_POINT}_${current.suggestion.id}`);
      }
      if (current.isUnloadingPoint) {
        currentTypes.push(`${ROUTE_POINT_TYPES.UNLOADING_POINT}_${current.suggestion.id}`);
      }
      if (current.isLoadingPoint) {
        currentTypes.push(`${ROUTE_POINT_TYPES.LOADING_POINT}_${current.suggestion.id}`);
      }
      if (current.isIntermediatePoint) {
        currentTypes.push(`${ROUTE_POINT_TYPES.INTERMEDIATE_POINT}_${current.suggestion.id}`);
      }
      result.push(...currentTypes);
      return result;
    }, []);

  return items.join("-");
};

export const generateRandomId = () =>
  `${Math.random()
    .toString(36)
    .substr(2, 5)}`;

export const toThousandSeparatedNumber = (number, separator = THIN_SPACE_CHAR) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const joinWithThinSpace = (...args) => args.join(THIN_SPACE_CHAR);

export const toTitleCase = text => text !== null && text.substring(0, 1).toUpperCase() + text.substring(1, text.length);
export const headerBottomHeight = () => {
  const bottomMenu = document.getElementById("header-bottom-menu");
  return bottomMenu ? bottomMenu.offsetHeight : 0;
};
