export const THIN_SPACE_CHAR = " ";

export const INPUT_RESTRICTION = {
  none: 0,
  numbersOnly: 1,
  nonNegativeFloat: 2,
  phoneNumber: 3,
  zeroTo9999Float: 4,
  zeroTo99Float: 5,
  percents: 6,
  zeroTo99: 7,
};

export const traceDefaultDelays = {
  size0: 0,
  size1: 5,
  size2: 10,
  size3: 15,
  size4: 30,
  size5: 60,
  size6: 90,
  customs: 60,
  loadingPoint: 120,
};

export const traceDefaultSpeeds = {
  ferry: 20, // code=10
  winterRoad: 30, // code=6
  noInfo: 40, // code=0
  other: 40, // code=1
  main: 60, // code=3
  trunk: 70, // code=4
  motorway: 80, // code=5
};

export const traceDefaultWorkDay = {
  workDayStartHour: 9,
  workDayStartMinute: 0,
};

export const ROUTE_POINT_TYPES = {
  START_POINT: 1,
  END_POINT: 2,
  LEGACY_PREBEGIN_POINT: 3,
  LEGACY_POSTEND_POINT: 4,
  INTERMEDIATE_POINT: 5,
  LOADING_POINT: 7,
  UNLOADING_POINT: 8,
};

export const MAP_POINT_TYPES = {
  COMMON_POINT: 0,
  START_POINT: 1,
  END_POINT: 2,
  START_WITH_LOADING_POINT: 3,
  END_WITH_UNLOADING_POINT: 4,
  INTERMEDIATE_POINT: 5,
  CUSTOMS: 6,
  LOADING_POINT: 7,
  UNLOADING_POINT: 8,
  COMPOSITE_POINT: 9,
};

// redux-query-sync performs shallow equality check between
// previous and current params state so two empty arrays are different
// from point of view of this library and we should use this hack...
export const emptyArray = Object.freeze([]);

export const ROAD_TYPE_ID_NAME_MAP = {
  0: "noInfo",
  1: "other",
  3: "main",
  4: "trunk",
  5: "motorway",
  6: "winterRoad",
  10: "ferry",
};

export const CITY_SIZE_ID_NAME_MAP = {
  0: "size0",
  1: "size1",
  2: "size2",
  3: "size3",
  4: "size4",
  5: "size5",
  6: "size6",
  7: "customs",
  8: "loadingPoint",
};

export const THRESHOLD_ZOOM_VALUE_TO_HIDE_SMALL_CITIES = 7;
export const THRESHOLD_ZOOM_VALUE_TO_HIDE_MEDIUM_CITIES = 4;

export const LANGUAGES = {
  rus: "ru-RU",
  eng: "en-US",
};

export const LANGUAGES_FIRMS = {
  rus: "ru",
  eng: "en",
};

export const SUPPORT_EMAIL = "trace@ati.su";
