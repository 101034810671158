import { connect } from "react-redux";
import Map from "./Map";
import { toggleSidebar } from "../../store/actions";

const mapStateToProps = state => ({
  isWelcomeScreen: state.isWelcomeScreen,
  routeSegments: state.result.routeSegments,
  routeNotFound: state.routeNotFound,
  sidebarToggled: state.sidebarToggled,
  showLightVersion: state.showLightVersion,
  showOnlyLargeCities: state.restrictions.showOnlyLargeCities
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar(true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Map);
