import React from "react";
import { withNamespaces, Trans } from "react-i18next";
import Link from "ati-ui-react/components/Link";

import styles from "./ReportError.scss";
import { SUPPORT_EMAIL } from "../../constants";

const ReportError = () => (
  <div className={styles.report}>
    <Link
      type="pseudo"
      small
      href={`mailto:${SUPPORT_EMAIL}`}
      gray
    >
      <Trans i18nKey="trace-route-report-error">Нашли ошибку?</Trans>
    </Link>
  </div>
);

export default withNamespaces()(ReportError);
