import { connect } from "react-redux";

import RouteRegionSeparator from "./RouteRegionSeparator";

import { excludeCountry } from "../../store/actions";

const mapDispatchToProps = dispatch => ({
  excludeCountry: cityId => dispatch(excludeCountry(cityId)),
});

export default connect(
  null,
  mapDispatchToProps,
)(RouteRegionSeparator);
