import {getVisualSegmentColor} from "../constants/roadColors";
import ROAD_TYPES, {getVisualSegmentRoadType} from "../constants/roadTypes";
import Polyline from "../models/Polyline";

class PolylineHelper {
  constructor({ t }) {
    this.t = t;
  }

  getPolylineTooltip = ({ roadType}) => {
    switch (roadType) {
      case ROAD_TYPES.common:
        return this.t("trace-common-road-free", "Бесплатная дорога");
      case ROAD_TYPES.toll:
        return this.t("trace-common-road-toll", "Платная дорога");
      case ROAD_TYPES.platon:
        return this.t("trace-common-road-platon", "Платон");
      default:
        return this.t("trace-common-road-unknown", "Неизвестная дорога");
    }
  };
  
  mapRouteSegmentsToPolylines(routeSegments) {
    const polylines = [];
    let key = 0;
    for (let i = 0; i < routeSegments.length; i += 1) {
      const routeSegment = routeSegments[i];
      for (let j = 0; j < routeSegment.visualRouteSegments.length; j += 1) {
        const visualRouteSegment = routeSegment.visualRouteSegments[j];
        const dashed = routeSegment.isEmptyRun;
        const color = getVisualSegmentColor(visualRouteSegment);
        const roadType = getVisualSegmentRoadType(visualRouteSegment);
        const tooltip = this.getPolylineTooltip({
          roadType
        });
        
        const offset = 3;
        for (let k = 0; k < visualRouteSegment.geoPoints.length - 1; k += 1) {
          key += 1;
          const start = visualRouteSegment.geoPoints[k];
          const finish = visualRouteSegment.geoPoints[k + 1];
          const polyline = new Polyline({ key, positions: [start, finish], color, tooltip, dashed, offset });
          polylines.push(polyline);
        }
      }
    }
    return polylines;
  }
}

export default PolylineHelper;
