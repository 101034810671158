import React from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import { toTitleCase } from "../../utils";

import styles from "../RouteRow/RouteRow.scss";

const ROAD_TYPE_NAMES = {
  0: "trace-common-road-type-no-info", // "Нет информации",
  1: "trace-common-road-type-other", // "Другой тип",
  3: "trace-common-road-type-main", // "Главная",
  4: "trace-common-road-type-trunk", // "Магистраль",
  5: "trace-common-road-type-motorway", // "Автомагистраль",
  6: "trace-common-road-type-winter-road", // "Зимник",
  10: "trace-common-road-type-ferry", // "Паромная переправа",
};

const RouteRoadImage = props => {
  const { distance, distancesWithTypes, t } = props;

  return (
      distancesWithTypes.map((distanceWithType, index) => {
          const type = `roadType${distanceWithType.type}`;
          const roadCss = `${styles.traceRoad} ${styles[type]}`;
          const proportion = distanceWithType.distance / distance;
          const percents = Math.round(proportion * 100);
          const style = { height: `${percents}%` };
          const key = index;
          const roadTypeName = toTitleCase(t(ROAD_TYPE_NAMES[distanceWithType.type]));
          const roadTitle = `${roadTypeName}`;

          return <div key={key} className={roadCss} style={style} title={roadTitle} />;
      })
  );
};

RouteRoadImage.propTypes = {
  distance: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteRoadImage);
