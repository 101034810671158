import React from "react";
import PropTypes from "prop-types";

import Map from "../Map";
import Sidebar from "../Sidebar";
import GeneralInfo from "../GeneralInfo";
import CanonicalLink from "../CanonicalLink";
import RightFixedBanner from "../RightFixedBanner";
import LdJson from "../LdJson";

import styles from "./Root.scss";

class Root extends React.Component {
  componentDidMount() {
    const {
      checkIfAppIsInitialized
    } = this.props;

    // workaround to fix style blinking
    // we need to extract css from js bundle
    setTimeout(() => {
      checkIfAppIsInitialized();
    }, 100);
  }

  componentDidUpdate(prevProps) {
    const { isPreinitialized, init } = this.props;
    const prevIsPreInitialized = prevProps.isPreinitialized;
    if (prevIsPreInitialized === false && isPreinitialized) {
      init();
    }
  }

  render() {
    const { isInitialized, showLightVersion } = this.props;

    return (
      <React.Fragment>
        <LdJson />
        <Map />
        <CanonicalLink />
        {isInitialized && (
          <div className={styles.container}>
            {!showLightVersion && <Sidebar />}
            {!showLightVersion && <GeneralInfo />}
            {!showLightVersion && <RightFixedBanner />}
          </div>
        )}
        <div id="error-notification-container" />
      </React.Fragment>
    );
  }
}

Root.defaultProps = {
  showLightVersion: false,
};

Root.propTypes = {
  isPreinitialized: PropTypes.bool.isRequired,
  isInitialized: PropTypes.bool.isRequired,
  checkIfAppIsInitialized: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
  showLightVersion: PropTypes.bool,
};

export default Root;
