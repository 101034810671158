import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip, Polyline } from "react-leaflet";
import "leaflet-polylineoffset";
import { withNamespaces } from "react-i18next";
import PolylineHelper from "../../utils/polylineHelper";

class MapRoute extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {t, routeSegments } = this.props;
    const polylineHelper = new PolylineHelper({ t });
    const polylines = polylineHelper.mapRouteSegmentsToPolylines(routeSegments);
    return polylines.map(polyline => (
      <Polyline
        key={polyline.key}
        positions={polyline.positions}
        offset={polyline.offset}
        color={polyline.color}
        opacity={1}
        lineJoin="round"
        lineCap="round"
        stroke
        dashArray={polyline.isDashed ? "3 10" : null}
        weight={5}
      >
        <Tooltip sticky opacity={1.0}>
          <span>{polyline.tooltip}</span>
        </Tooltip>
      </Polyline>
    ));
  }
}

export default withNamespaces()(MapRoute);
