import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Banner extends PureComponent {
  mapping = {
    "doc-size-id": "bannerSizeId",
    "doc-preset": "docPreset",
    "max-screen-width": "maxScreenWidth",
    "min-screen-width": "minScreenWidth",
    "doc-extra-content": "docExtraContent",
    "doc-extra-content-container": "docExtraContentContainer",
    class: "className",
    "doc-scroll-changes": "scroll",
    "doc-extra-content-sizes": "extraContentSizes",
    "doc-close-type": "docCloseType",
  };

  static propTypes = {
    bannerSizeId: PropTypes.number,
    docPreset: PropTypes.string,
    maxScreenWidth: PropTypes.number,
    minScreenWidth: PropTypes.number,
    docExtraContent: PropTypes.string,
    docExtraContentContainer: PropTypes.string,
    className: PropTypes.string,
    scroll: PropTypes.bool,
    extraContentSizes: PropTypes.arrayOf(PropTypes.number),
    docCloseType: PropTypes.string,
  };

  static defaultProps = {
    bannerSizeId: null,
    docPreset: null,
    maxScreenWidth: null,
    minScreenWidth: null,
    docExtraContent: null,
    docExtraContentContainer: null,
    className: null,
    scroll: null,
    extraContentSizes: null,
    docCloseType: null,
  };

  getAttributes = () => {
    const attributeNames = Object.keys(this.mapping);
    const attributes = {};
    attributeNames.forEach(attributeName => {
      const propName = this.mapping[attributeName];
      let propValue = this.props[propName];
      if (propValue === null || propValue === undefined) {
        return;
      }
      if (attributeName === "doc-extra-content-sizes") {
        propValue = `[${propValue.join(",")}]`;
      }
      attributes[attributeName] = propValue;
    });
    return attributes;
  };

  makeUserDataInfo = () => {
    const attributes = this.getAttributes();
    return Object.keys(attributes)
      .map(attributeName => `${attributeName}=${attributes[attributeName]}`)
      .join(";");
  };

  render() {
    return <span ati-userdata-info={this.makeUserDataInfo()} />;
  }
}

export default Banner;
