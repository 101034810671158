import React from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import MapButton from "../MapButton";

import styles from "./MapControls.scss";
import strings from "./strings";

const icons = {
  plus: "https://files.ati.su/images/trace/map-icon-plus.svg",
  minus: "https://files.ati.su/images/trace/map-icon-minus.svg",
  geo: "https://files.ati.su/images/trace/map-icon-geo.svg",
};

const MapControls = ({ onZoomIn, onZoomOut, onReturnToRoute, t }) => (
  <React.Fragment>
    <MapButton
      iconUrl={icons.plus}
      onClick={onZoomIn}
      className={styles.plusButton}
      altText={t("trace-map-zoom-in-button-tooltip", strings.PLUS_BUTTON)}
      id="plusButton"
    />
    <MapButton
      iconUrl={icons.minus}
      onClick={onZoomOut}
      className={styles.minusButton}
      altText={t("trace-map-zoom-out-button-tooltip", strings.MINUS_BUTTON)}
      id="minusButton"
    />
    <MapButton
      iconUrl={icons.geo}
      onClick={onReturnToRoute}
      altText={t("trace-map-return-to-route-button-tooltip", strings.GEO_BUTTON)}
      id="routeButton"
    />
  </React.Fragment>
);

MapControls.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onReturnToRoute: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(MapControls);
