import React from "react";
import PropTypes from "prop-types";

import styles from "./ToggleRouteStatisticsPanelButton.scss";

const ArrowIcon = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path
      transform="rotate(-90 12.295000076293947,12) "
      fill="black"
      d="m8.59,16.59l4.58,-4.59l-4.58,-4.59l1.41,-1.41l6,6l-6,6l-1.41,-1.41z"
      id="arrowPath"
    />
  </svg>
);

const ToggleRouteStatisticsPanelButton = ({ toggleSidebar }) => (
  <div className={styles.arrowButtonContainer}>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div className={styles.arrowButton} onClick={toggleSidebar} role="button" tabIndex={0}>
      <ArrowIcon className={styles.arrow} />
    </div>
  </div>
);

ToggleRouteStatisticsPanelButton.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default ToggleRouteStatisticsPanelButton;
