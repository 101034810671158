import { connect } from "react-redux";

import GeneralInfo from "./GeneralInfo";

import { getCommonStats } from "../../store/actions";

const mapStateToProps = state => ({
  isWelcomeScreen: state.isWelcomeScreen,
  commonStats: state.commonStats,
});

const mapDispatchToProps = dispatch => ({
  getCommonStats: () => dispatch(getCommonStats()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralInfo);
