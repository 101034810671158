export default class SearchRouteParameters {
  constructor({ route, calculations, restrictions, excludedCities, excludedCountries, speeds, delays }) {
    this.route = route;
    this.calculations = calculations;
    this.restrictions = restrictions;
    this.excludedCities = excludedCities;
    this.excludedCountries = excludedCountries;
    this.speeds = speeds;
    this.delays = delays;
  }
}
