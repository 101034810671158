import { connect } from "react-redux";

import Root from "./Root";

import { init, checkIfAppIsInitialized } from "../../store/actions";

const mapStateToProps = state => ({
  isInitialized: state.isInitialized,
  isPreinitialized: state.pendingRequests === 0,
  showLightVersion: state.showLightVersion
});

const mapDispatchToProps = dispatch => ({
  checkIfAppIsInitialized: () => dispatch(checkIfAppIsInitialized()),
  init: () => dispatch(init()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
