import {VisualRouteSegmentType} from "../bl/RouteSegment";

const ROAD_TYPES = {
  common: "common",
  toll: "toll",
  platon: "platon",
};
Object.freeze(ROAD_TYPES);

export default ROAD_TYPES;
export const getVisualSegmentRoadType = visualRouteSegment => {
  if (visualRouteSegment.type === VisualRouteSegmentType.Toll) {
    return ROAD_TYPES.toll;
  }
  if (visualRouteSegment.type === VisualRouteSegmentType.Platon) {
    return ROAD_TYPES.platon;
  }
  return ROAD_TYPES.common;
}