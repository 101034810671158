import { connect } from "react-redux";

import FilterPanel from "./FilterPanel";

import {
  changeRoute,
  searchRoute,
  fetchSuggestions,
  clearSuggestions,
  updatePreferences,
  resetPreferences,
  hideErrorNotification,
  toggleWithinCountry,
} from "../../store/actions";

const mapStateToProps = state => ({
  route: state.route,
  suggestions: state.suggestions,
  restrictions: state.restrictions,
  calculations: state.calculations,
  preferences: {
    delays: state.delays,
    speeds: state.speeds,
  },
  showPreferencesPopup: state.showPreferencesPopup,
  routeNotFound: state.routeNotFound,
  isRouteSearching: state.isRouteSearching,
  isRouteSearchFailed: state.isRouteSearchFailed,
  isWithinCountryDisabled: state.isWithinCountryDisabled,
});

const mapDispatchToProps = dispatch => ({
  changeRoute: () => dispatch(changeRoute()),
  searchRoute: params => dispatch(searchRoute(params)),
  fetchSuggestions: query => dispatch(fetchSuggestions(query)),
  clearSuggestions: () => dispatch(clearSuggestions()),
  updatePreferences: preferences => dispatch(updatePreferences(preferences)),
  resetPreferences: () => dispatch(resetPreferences()),
  hideErrorNotification: () => dispatch(hideErrorNotification()),
  toggleWithinCountry: disabled => dispatch(toggleWithinCountry(disabled)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterPanel);
