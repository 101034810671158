import React from "react";
import PropTypes from "prop-types";

import styles from "../RouteRow/RouteRow.scss";

const RouteCityExclude = ({ city, onExcludeCity, tooltip }) => (
  <div // eslint-disable-line
    className={styles.cityExcludeIcon}
    title={tooltip}
    role="button"
    onClick={() => onExcludeCity({ cityId: city.id, cityName: city.name })}
  />
);

RouteCityExclude.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onExcludeCity: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default RouteCityExclude;
