import React from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";

import styles from "../RouteRow/RouteRow.scss";

const RouteToPointInfo = ({ distanceToFirst, timeToFirst, t }) => (
  <div className={styles.toFirstInfo}>
    <div className={styles.leftCol}>
      <span title={t("trace-route-point-distance-from-start-point-tooltip", "Расстояние от начала маршрута")}>
        {distanceToFirst}
      </span>
    </div>
    <div className={styles.rightCol}>
      <span title={t("trace-route-point-duration-from-start-point-tooltip", "Общее время в пути")}>
        <Trans i18nKey="trace-route-point-duration-from-start-point">{{ timeToFirst }} в пути</Trans>
      </span>
    </div>
  </div>
);

RouteToPointInfo.propTypes = {
  distanceToFirst: PropTypes.string.isRequired,
  timeToFirst: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteToPointInfo);
