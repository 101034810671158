// eslint-disable
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";

import styles from "./RouteRow.scss";
import routeStyles from "../Route/Route.scss";

import RouteToPoint from "../RouteToPointInfo";
import RouteFromPoint from "../RouteFromPointInfo";
import RouteRoadImage from "../RouteRoadImage";
import RoutePlaton from "../RoutePlaton";
import RouteRegionSeparator from "../RouteRegionSeparator";
import RouteCityExclude from "../RouteCityExclude";

import {getFormattedTimeSpan, getFormattedDistance, getDistanceInKm} from "../../bl/routeRowService";
import { toThousandSeparatedNumber } from "../../utils";

const citySizeTootipMap = {
  6: {
    key: "trace-route-city-tooltip-population-over-x",
    defaultValue: "Население свыше {{lowerBound}} человек.",
    lowerBound: 1000000,
    upperBound: null,
  },
  5: {
    key: "trace-route-city-tooltip-population-between-x-and-y",
    defaultValue: "Население от {{lowerBound}} до {{upperBound}} человек.",
    lowerBound: 500000,
    upperBound: 1000000,
  },
  4: {
    key: "trace-route-city-tooltip-population-between-x-and-y",
    defaultValue: "Население от {{lowerBound}} до {{upperBound}} человек.",
    lowerBound: 100000,
    upperBound: 500000,
  },
  3: {
    key: "trace-route-city-tooltip-population-between-x-and-y",
    defaultValue: "Население от {{lowerBound}} до {{upperBound}} человек.",
    lowerBound: 50000,
    upperBound: 100000,
  },
  2: {
    key: "trace-route-city-tooltip-population-between-x-and-y",
    defaultValue: "Население от {{lowerBound}} до {{upperBound}} человек.",
    lowerBound: 10000,
    upperBound: 50000,
  },
  1: {
    key: "trace-route-city-tooltip-population-up-to-x",
    defaultValue: "Население до {{upperBound}} человек.",
    lowerBound: null,
    upperBound: 10000,
  },
  0: {
    key: "trace-route-city-tooltip-population-up-to-x",
    defaultValue: "Население до {{upperBound}} человек.",
    lowerBound: null,
    upperBound: 10000,
  },
};

class RouteRow extends React.Component {
  getCityTitle = (citySize, t) => {
    const { key, defaultValue, lowerBound, upperBound } = citySizeTootipMap[citySize];
    const popupationTooltipPart = t(key, {
      defaultValue,
      lowerBound: toThousandSeparatedNumber(lowerBound),
      upperBound: toThousandSeparatedNumber(upperBound),
    });

    return `${popupationTooltipPart}`;
  };

  render() {
    const {
      routeSegment,
      handleExcludeCity,
      lastCountryId,
      t,
    } = this.props;

    const routeSegmentCityPoint = routeSegment.startPoint;
    const nextRegion = routeSegment.changedRegion;
    const nextCountry = routeSegment.changedCountry;

    const isToll = routeSegment.hasTollRoad;

    const isCustoms = routeSegmentCityPoint.isCustomsPoint();
    const {size} = routeSegmentCityPoint.city;
    const cityIconStyle = isCustoms ? "customs" : `city-size-${size}`;
    const cityNameStyle = isCustoms ? "customs" : `city-name-${size}`;
    const {platonFare, roadName} = routeSegment;
    const isCollectPlaton = routeSegment.platonDistance > 0;

    const timeSpanTranslations = {
      days: t("trace-common-units-days-abbreviation", "дн"),
      hours: t("trace-common-units-hours-abbreviation", "ч"),
      minutes: t("trace-common-units-minutes-abbreviation", "м"),
    };
    const kmLabel = t("trace-common-units-kilometers-abbreviation", "км");
    const timeToNextFormatted = getFormattedTimeSpan(routeSegment.duration, timeSpanTranslations);
    const timeToFirstFormatted = getFormattedTimeSpan(routeSegment.traveledDuration, timeSpanTranslations);
    const distanceToNextFormatted = getFormattedDistance(getDistanceInKm(routeSegment.distance), kmLabel);
    const distanceToFirstFormatted = getFormattedDistance(getDistanceInKm(routeSegment.traveledDistance), kmLabel);

    const cityNameTitle = this.getCityTitle(routeSegmentCityPoint.city.size, t);
    // eslint-disable-next-line no-nested-ternary
    const separatorText = nextCountry ? nextCountry.name : nextRegion ? nextRegion.name : "";
    const nextCountryId = nextCountry ? nextCountry.id : -1;
    const nextCountryName = nextCountry ? nextCountry.name : "";
    const canExcludeCountry = lastCountryId !== nextCountryId;

    const titleClasses = classnames(styles.cityName, styles[cityNameStyle], {
      [styles.bold]: routeSegmentCityPoint.isKeyPoint
    });

    return (
      <div className={styles.traceRowContainer}>
        <div className={styles.traceRow}>
          <div className={styles.traceImage}>
            <div className={classnames(styles.tracePoint, styles[cityIconStyle])} title={cityNameTitle} />
            <RouteRoadImage
              distance={routeSegment.distance}
              distancesWithTypes={routeSegment.distancesWithTypes}
            />
          </div>
          <div className={styles.traceDescription}>
            <div className={classnames(styles.cityRow, styles[cityNameStyle])}>
              <div className={titleClasses} title={cityNameTitle} role="button">
                {routeSegmentCityPoint.city.name}
              </div>
              {!routeSegmentCityPoint.isSpecialPoint() && (
                <RouteCityExclude
                  city={routeSegmentCityPoint.city}
                  onExcludeCity={handleExcludeCity}
                  tooltip={t("trace-route-exclude-point-tooltip", "Исключить из маршрута")}
                />
              )}
            </div>
            {routeSegmentCityPoint.address && <div>{routeSegmentCityPoint.address}</div>}

            <RouteToPoint timeToFirst={timeToFirstFormatted} distanceToFirst={distanceToFirstFormatted} />

            {!routeSegment.isLastSegment && (
              <RouteFromPoint timeToNext={timeToNextFormatted} distanceToNext={distanceToNextFormatted} />
            )}
            {isCollectPlaton && (
              <RoutePlaton platonFare={platonFare} roadName={roadName} />
            )}
            {isToll && <div className={styles.tollRoad}>{t("trace-common-road-toll", "платная дорога")}</div>}
            {separatorText !== "" && (
              <RouteRegionSeparator
                canExcludeCountry={canExcludeCountry}
                countryHasChanged={nextCountry !== undefined}
                separatorText={separatorText}
                countryId={nextCountryId}
                countryName={nextCountryName}
              />
            )}
          </div>
        </div>
        <div className={routeStyles.backdrop} />
      </div>
    );
  }
}

RouteRow.propTypes = {
  handleExcludeCity: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteRow);
