import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import autosuggestHighlightMatch from "autosuggest-highlight/match/index";
import autosuggestHighlightParse from "autosuggest-highlight/parse/index";
import { debounce } from "throttle-debounce";
import { withFormik } from "formik";
import * as Yup from "yup";
import leftPad from "left-pad";
import { withNamespaces, Trans } from "react-i18next";

import Autosuggest from "ati-ui-react/components/Autosuggest";
import Button from "ati-ui-react/components/Button";
import Checkbox from "ati-ui-react/components/Checkbox";
import QuestionIcon from "ati-ui-react/widgets/QuestionIcon";
import RestrictedInput from "ati-ui-react/components/RestrictedInput";
import ButtonGroup from "ati-ui-react/components/ButtonGroup";
import AtiTooltip from "ati-ui-react/components/Tooltip";
import Notification from "ati-ui-react/widgets/Notification";

import RouteNotFound from "../RouteNotFound";
import FormField from "../FormField";
import FormikDatePicker from "../FormikDatepicker";
import ErrorPanel from "../ErrorPanel";
import Portal from "../Portal";

import { INPUT_RESTRICTION } from "../../constants";
import { generateRandomId, headerBottomHeight } from "../../utils";
import * as routeService from "../../bl/routeService";

import styles from "./FilterPanel.scss";
import { GOALS, reachGoal } from "../../utils/gtm";

const TrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14">
    <path d="M8.637.988h2.601V2.5H.762V.988h2.601L4.137.25h3.726l.774.738zM3.328 6.895l1.617 1.617-1.582 1.582 1.055 1.054L6 9.566l1.582 1.582 1.055-1.054-1.582-1.582 1.582-1.617L7.582 5.84 6 7.457 4.418 5.84l-1.09 1.055zM1.5 12.238v-9h9v9c0 .399-.152.75-.457 1.055-.305.305-.656.457-1.055.457H3.012c-.399 0-.75-.152-1.055-.457-.305-.305-.457-.656-.457-1.055z" />
  </svg>
);

const resetKeyPointTypes = (point, isIntermediate = false) => ({
  ...point,
  isLoadingPoint: false,
  isUnloadingPoint: false,
  isIntermediatePoint: isIntermediate,
});

const resetRouteKeyPointsTypes = ({ from, to, through, temp }) => ({
  from: resetKeyPointTypes(from),
  to: resetKeyPointTypes(to),
  through: through.map(point => resetKeyPointTypes(point, true)),
  temp,
});

class FilterPanel extends React.Component {
  searchCities = debounce(300, query => {
    const { fetchSuggestions } = this.props;
    fetchSuggestions(query);
  });

  componentDidMount() {
    const { routeNotFound } = this.props;
    if(routeNotFound) {
      this.scrollToError = true;
    }
  }
  
  componentWillReceiveProps(nextProps) {
    const { routeNotFound: nexRouteNotFound } = nextProps;
    const { routeNotFound } = this.props;
    if (nexRouteNotFound !== routeNotFound) {
      this.scrollToError = true;
    }
  }

  componentDidUpdate() {
    const { routeNotFound, isRouteSearching } = this.props;
    if (routeNotFound && !isRouteSearching && !!this.scrollToError && !!this.RouteNotFoundBlock) {
      this.RouteNotFoundBlock.scrollIntoView();
      this.scrollToError = false;
    }
  }

  handleInputBlur = event => {
    const { setFieldValue, handleBlur } = this.props;

    let { value } = event.currentTarget;
    const { name } = event.currentTarget;

    if (Number.parseInt(value, 10) < 10) {
      value = `0${value}`.slice(-2);
    }

    setFieldValue(name, value);
    handleBlur(event);
  };

  handleRouteSearch = () => {
    const { searchRoute } = this.props;
    searchRoute(this.state);
  };

  handleSuggestionValueChanged = (value, id) => {
    const { setFieldValue } = this.props;

    if (!value) {
      setFieldValue(`${id}.suggestion`, undefined);
      setFieldValue(`${id}.address`, null);
      setFieldValue(`${id}.geo_point`, null);
    }
    setFieldValue(`${id}.value`, value);
  };

  handleSuggestionSelected = (event, { suggestion }, id) => {
    const {
      setFieldValue,
      values: { route },
      toggleWithinCountry,
    } = this.props;

    const nextFromTo = id === "route.from" ? [suggestion, route.to.suggestion] : [suggestion, route.from.suggestion];
    const suggestions = [...nextFromTo, ...route.through.map(i => i.suggestion)];
    const isWithinCountryShouldBeDisabled = routeService.checkWithinCountryShouldBeDisabled(suggestions);
    if (isWithinCountryShouldBeDisabled) {
      setFieldValue("withinCountry", false);
    }
    toggleWithinCountry(isWithinCountryShouldBeDisabled);

    const newDirection = resetRouteKeyPointsTypes(route);
    const directionName = id === "route.from" ? "from" : "to";
    newDirection[directionName].suggestion = suggestion;
    newDirection[directionName].value = suggestion.text;
    newDirection[directionName].address = null;
    newDirection[directionName].geo_point = null;

    setFieldValue("route", newDirection);

    event.preventDefault();
    event.stopPropagation();
  };

  handleThroughCitySuggestionSelected = (event, { suggestion }, id) => {
    const {
      values: {
        route: { from, to, through, temp },
      },
      setFieldValue,
      toggleWithinCountry,
    } = this.props;

    let intermediates = through;
    let intermediate = through.find(item => item.id === id);

    if (!intermediate) {
      intermediate = {
        id: generateRandomId(),
        value: suggestion.text,
        isStartPoint: false,
        isEndPoint: false,
        isIntermediatePoint: true,
        isLoadingPoint: false,
        isUnloadingPoint: false,
        suggestion,
      };
      intermediates = [...through];
      intermediates.push(intermediate);
    } else {
      intermediate.address = null;
      intermediate.geo_point = null;
      intermediate.value = suggestion.text;
      intermediate.suggestion = suggestion;
    }

    const suggestions = [to.suggestion, from.suggestion, ...intermediates.map(i => i.suggestion)];
    const isWithinCountryShouldBeDisabled = routeService.checkWithinCountryShouldBeDisabled(suggestions);
    if (isWithinCountryShouldBeDisabled) {
      setFieldValue("withinCountry", false);
    }
    toggleWithinCountry(isWithinCountryShouldBeDisabled);

    const newDirection = resetRouteKeyPointsTypes({
      from,
      to,
      temp,
      through: intermediates,
    });
    newDirection.temp.value = "";

    setFieldValue("route", newDirection);

    event.preventDefault();
    event.stopPropagation();
  };

  handleThroughCitySuggestionValueChanged = (value, id) => {
    const {
      values: {
        route: { through },
      },
      setFieldValue,
    } = this.props;

    const intermediates = through.map(item => {
      if (item.id === id) {
        return {
          id: item.id,
          value,
          isStartPoint: false,
          isEndPoint: false,
          isIntermediatePoint: true,
          isLoadingPoint: false,
          isUnloadingPoint: false,
          suggestion: value ? item.suggestion : undefined,
          address: null,
          geo_point: null,
        };
      }

      return item;
    });

    setFieldValue("route.through", intermediates);
  };

  handleRemoveIntermediateCity = id => {
    const {
      values: {
        route: { from, to, through, temp },
      },
      setFieldValue,
      toggleWithinCountry,
    } = this.props;

    const intermediates = [...through].filter(city => city.id !== id);

    const suggestions = [to.suggestion, from.suggestion, ...intermediates.map(i => i.suggestion)];
    const isWithinCountryShouldBeDisabled = routeService.checkWithinCountryShouldBeDisabled(suggestions);
    if (isWithinCountryShouldBeDisabled) {
      setFieldValue("withinCountry", false);
    }
    toggleWithinCountry(isWithinCountryShouldBeDisabled);

    const newDirection = resetRouteKeyPointsTypes({
      from,
      to,
      temp,
      through: intermediates,
    });

    setFieldValue("route", newDirection);
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.searchCities(value);
  };

  handleSuggestionsClearRequested = () => {
    const { clearSuggestions } = this.props;
    clearSuggestions();
  };

  handleAutosuggestBlur = (_, id) => {
    const { setFieldTouched } = this.props;

    setFieldTouched(id, true);
  };

  renderSuggestion = (suggestion, { query }) => {
    const splitted = suggestion.text.split(",");
    const matches = autosuggestHighlightMatch(splitted[0], query);
    const parts = autosuggestHighlightParse(splitted[0], matches);

    const highlighted = parts.map((part, index) => (
      <span key={`hl-id-${index.toLocaleString()}`} className={part.highlight ? styles.match : ""}>
        {part.text}
      </span>
    ));

    const sliced = splitted.slice(1);
    const slicedLength = sliced.length;
    const tailPart = sliced.map((el, i) => { return i !== slicedLength - 1 ? `${el}, ` : el });
    return (
      <div className="suggestion">
        {<b>{highlighted}</b>}
        {splitted.length > 1 && ","}
        {tailPart}
      </div>
    );
  };

  renderThroughCities = () => {
    const {
      suggestions,
      values: {
        route: { through, temp },
      },
      t,
    } = this.props;

    return (
      <React.Fragment>
        {through.map(item => (
          <div className={styles.through} key={item.id}>
            <Autosuggest
              id={item.id}
              suggestions={suggestions}
              placeholder={t("trace-filters-field-city-placeholder", "Введите город")}
              searchProperty="text"
              valueProperty="text"
              onChange={this.handleThroughCitySuggestionValueChanged}
              value={item.value}
              renderSuggestion={this.renderSuggestion}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              onSuggestionSelected={this.handleThroughCitySuggestionSelected}
              inputProps={{ autoComplete: "new-password" }}
              noFog
            />
            <div className={styles.tooltip}>
              <AtiTooltip tooltip={t("trace-filters-field-intermediate-city-remove-button-tooltip", "Удалить точку")}>
                <button
                  type="button"
                  className={styles.remove}
                  onClick={() => this.handleRemoveIntermediateCity(item.id)}
                >
                  <TrashIcon />
                </button>
              </AtiTooltip>
            </div>
          </div>
        ))}
        {through.length < 18 && (
          <Autosuggest
            id="route.temp"
            suggestions={suggestions}
            placeholder={t("trace-filters-field-city-placeholder", "Введите город")}
            searchProperty="text"
            valueProperty="text"
            onChange={this.handleSuggestionValueChanged}
            value={temp.value}
            renderSuggestion={this.renderSuggestion}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
            onSuggestionSelected={this.handleThroughCitySuggestionSelected}
            inputProps={{ autoComplete: "new-password" }}
          />
        )}
      </React.Fragment>
    );
  };

  render() {
    const {
      routeNotFound,
      suggestions,
      values,
      errors,
      touched,
      submitCount,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      isRouteSearching,
      isRouteSearchFailed,
      hideErrorNotification,
      isWithinCountryDisabled,
      t,
    } = this.props;

    const formHasErrors = Object.keys(errors).length > 0 && submitCount > 0;

    const fromFieldError = errors.route && errors.route.from && errors.route.from.suggestion;
    const fromFieldTouched = !!(touched.route && touched.route.from);

    const toFieldError = errors.route && errors.route.to && errors.route.to.suggestion;
    const toFieldTouched = !!(touched.route && touched.route.to);

    const searchButtonBottom = 10;

    return (
      <React.Fragment>
        <Portal id="error-notification-container">
          <Notification
            show={isRouteSearchFailed}
            type="error"
            delay={2000}
            message={t(
              "trace-search-error-message",
              "Произошла ошибка. Попробуйте рассчитать\u00A0маршрут\u00A0ещё\u00A0раз.",
            )}
            closeNotification={hideErrorNotification}
          />
        </Portal>
        <form>
          <div className={styles.filters}>
            <div className={styles.direction}>
              <div className={styles.row}>
                <span className={classnames(styles.label, styles.primaryLabel)}>
                  {t("trace-filters-field-from-city-label", "Откуда")}
                </span>
                <FormField error={fromFieldError} touched={fromFieldTouched}>
                  <Autosuggest
                    id="route.from"
                    suggestions={suggestions}
                    placeholder={t("trace-filters-field-city-placeholder", "Введите город")}
                    searchProperty="text"
                    valueProperty="text"
                    onBlur={this.handleAutosuggestBlur}
                    onChange={this.handleSuggestionValueChanged}
                    value={values.route.from.value}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    noFog
                    error={fromFieldError && fromFieldTouched}
                    inputProps={{ autoComplete: "new-password" }}
                  />
                </FormField>
              </div>
              <div className={styles.row}>
                <span className={classnames(styles.label, styles.primaryLabel)}>
                  {t("trace-filters-field-to-city-label", "Куда")}
                </span>
                <FormField error={toFieldError} touched={toFieldTouched}>
                  <Autosuggest
                    id="route.to"
                    suggestions={suggestions}
                    placeholder={t("trace-filters-field-city-placeholder", "Введите город")}
                    searchProperty="text"
                    valueProperty="text"
                    onBlur={this.handleAutosuggestBlur}
                    onChange={this.handleSuggestionValueChanged}
                    value={values.route.to.value}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    noFog
                    error={toFieldError && toFieldTouched}
                    inputProps={{ autoComplete: "new-password" }}
                  />
                </FormField>
              </div>
              <div className={styles.row}>
                <span className={styles.label}>{t("trace-filters-field-intermediate-cities-label", "Через")}</span>
                {this.renderThroughCities()}
              </div>
            </div>
            <div className={styles.route}>
              <div className={styles.row}>
                <span className={classnames(styles.label, styles.primaryLabel)}>
                  {t("trace-filters-field-route-label", "Маршрут")}
                </span>
                <ButtonGroup
                  className={styles.routeTypeButtons}
                  selectedValue={values.fastWay}
                  options={[
                    { value: true, label: t("trace-filters-field-route-fastest", "Быстрый") },
                    { value: false, label: t("trace-filters-field-route-shortest", "Короткий") },
                  ]}
                  valueProp="value"
                  displayProp="label"
                  onSelectedValueChanged={value => setFieldValue("fastWay", value)}
                />
              </div>
              <div className={styles.checkBoxPanel}>
                <div className={styles.row}>
                  <Checkbox
                    className={styles.routeCheckbox}
                    name="withinCountry"
                    checked={values.withinCountry}
                    onChange={({ target: { checked } }) => setFieldValue("withinCountry", checked)}
                    label={t("trace-filters-filter-within-country", "в пределах страны")}
                    size="small"
                    disabled={isWithinCountryDisabled}
                  />
                </div>
                <div className={styles.row}>
                  <Checkbox
                    className={styles.routeCheckbox}
                    name="excludeMkad"
                    checked={values.excludeMkad}
                    onChange={({ target: { checked } }) => setFieldValue("excludeMkad", checked)}
                    label={t("trace-filters-filter-exclude-mkad", "исключить МКАД")}
                    size="small"
                  />
                </div>
                <div className={styles.row}>
                  <Checkbox
                    className={styles.routeCheckbox}
                    name="allowFerries"
                    checked={values.allowFerries}
                    onChange={({ target: { checked } }) => setFieldValue("allowFerries", checked)}
                    label={t("trace-filters-filter-allow-ferries", "разрешить паромы")}
                    size="small"
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.flex}>
                    <div>
                      <Checkbox
                        name="allowWinterRoads"
                        checked={values.allowWinterRoads}
                        onChange={({ target: { checked } }) => setFieldValue("allowWinterRoads", checked)}
                        label={t("trace-filters-filter-allow-winter-roads", "разрешить зимники")}
                        size="small"
                      />
                    </div>
                    <div className={styles.hint}>
                      <QuestionIcon
                        className={styles.questionMark}
                        tooltip={
                          // eslint-disable-next-line
                          <Trans i18nKey="trace-filters-filter-allow-winter-roads-tooltip">
                            Дороги,&nbsp;по&nbsp;которым&nbsp;можно проехать&nbsp;только&nbsp;при&nbsp;минусовой
                            температуре,&nbsp;например, по&nbsp;замерзшей&nbsp;реке&nbsp;или&nbsp;озеру
                          </Trans>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <Checkbox
                    className={styles.routeCheckbox}
                    name="excludeTollRoads"
                    checked={values.excludeTollRoads}
                    onChange={({ target: { checked } }) => {
                      setFieldValue("excludeTollRoads", checked);
                      reachGoal(GOALS.clickFreeRoadsButton);
                    }}
                    label={t("trace-filters-filter-exclude-toll-roads", "без проезда по платным дорогам")}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.calculation}>
            <div className={styles.inputs}>
              <div className={styles.block}>
                <div className={styles.element}>
                  <span className={styles.primaryLabel}>{t("trace-filters-time-label", "Время в пути")}</span>
                  <span className={styles.label}>{t("trace-filters-filter-workday-label", "Раб. день водителя")}</span>
                  <FormField error={errors.workingDay} touched={touched.workingDay}>
                    <div className={styles.input}>
                      <RestrictedInput
                        name="workingDay"
                        inputRestriction={INPUT_RESTRICTION.numbersOnly}
                        maxLength={2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.workingDay}
                        error={errors.workingDay && touched.workingDay}
                      />
                    </div>
                    <span className={styles.unit}>{t("trace-common-units-hours-abbreviation", "ч")}</span>
                  </FormField>
                </div>
                <div className={styles.element}>
                  <span className={styles.label}>{t("trace-filters-filter-mileage-label", "Макс. пробег в день")}</span>
                  <FormField error={errors.dailyMileage} touched={touched.dailyMileage}>
                    <div className={styles.input}>
                      <RestrictedInput
                        name="dailyMileage"
                        inputRestriction={INPUT_RESTRICTION.zeroTo9999Float}
                        maxLength={5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.dailyMileage}
                        error={errors.dailyMileage && touched.dailyMileage}
                      />
                    </div>
                    <span className={styles.unit}>{t("trace-common-units-kilometers-abbreviation", "км")}</span>
                  </FormField>
                </div>
                <div className={styles.element}>
                  <span className={styles.label}>{t("trace-filters-filter-departure-label", "Отправление")}</span>
                  <div className={styles.departure}>
                    <FormField error={errors.departureDate} touched={!touched.departureDate}>
                      <FormikDatePicker
                        name="departureDate"
                        date={values.departureDate}
                        error={errors.departureDate && !touched.departureDate}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormField>
                    <span className={styles.at}>{t("trace-filters-filter-departure-at", "в")}</span>
                    <FormField
                      error={errors.departureHour || errors.departureMinute}
                      touched={touched.departureHour || errors.departureMinute}
                    >
                      <div className={styles.time}>
                        <div className={styles.input}>
                          <RestrictedInput
                            name="departureHour"
                            inputRestriction={INPUT_RESTRICTION.zeroTo99}
                            maxLength={2}
                            onChange={handleChange}
                            onBlur={this.handleInputBlur}
                            type="text"
                            value={values.departureHour}
                            error={errors.departureHour || errors.departureMinute}
                          />
                        </div>
                        <span className={styles.separator}>:</span>
                        <div className={styles.input}>
                          <RestrictedInput
                            name="departureMinute"
                            inputRestriction={INPUT_RESTRICTION.numbersOnly}
                            maxLength={2}
                            onChange={handleChange}
                            onBlur={this.handleInputBlur}
                            type="text"
                            value={values.departureMinute.toString()}
                            error={errors.departureHour || errors.departureMinute}
                            clearZeroOnBlur={false}
                          />
                        </div>
                      </div>
                    </FormField>
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.element}>
                  <span className={styles.primaryLabel}>
                    {t("trace-filters-fuel-consumption-label", "Расход топлива")}
                  </span>
                  <span className={styles.label}>{t("trace-filters-filter-fuel-consumption-label", "На 100 км")}</span>
                  <FormField error={errors.fuelConsumption} touched={touched.fuelConsumption}>
                    <div className={styles.input}>
                      <RestrictedInput
                        name="fuelConsumption"
                        inputRestriction={INPUT_RESTRICTION.numbersOnly}
                        maxLength={3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.fuelConsumption}
                        error={errors.fuelConsumption && touched.fuelConsumption}
                      />
                    </div>
                    <span className={styles.unit}>{t("trace-common-units-liters-abbreviation", "л")}</span>
                  </FormField>
                </div>
                <div className={styles.element}>
                  <span className={styles.label}>{t("trace-filters-filter-fuel-price-label", "За литр топлива")}</span>
                  <FormField error={errors.fuelPrice} touched={touched.fuelPrice}>
                    <div className={styles.input}>
                      <RestrictedInput
                        name="fuelPrice"
                        inputRestriction={INPUT_RESTRICTION.zeroTo9999Float}
                        maxLength={5}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.fuelPrice}
                        error={errors.fuelPrice && touched.fuelPrice}
                      />
                    </div>
                    <span className={styles.unit}>{t("trace-common-units-price-abbreviation", "₽")}</span>
                  </FormField>
                </div>
              </div>
            </div>
          </div>
          {routeNotFound && (
            <RouteNotFound
              containerRef={el => {
                this.RouteNotFoundBlock = el;
              }}
            />
          )}
          {formHasErrors && (
            <ErrorPanel message={t("trace-filters-validation-general-error-message", "Заполните все поля")} />
          )}
          <div className={styles.search} style={{ bottom: headerBottomHeight() + searchButtonBottom }}>
            <Button
              buttonType="submit"
              text={t("trace-filters-search-button-text", "Рассчитать маршрут")}
              className={styles.button}
              action={handleSubmit}
              isLoading={isRouteSearching}
            />
          </div>
        </form>
      </React.Fragment>
    );
  }
}

FilterPanel.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  calculations: PropTypes.any.isRequired, // eslint-disable-line
  isRouteSearching: PropTypes.bool.isRequired,
  isRouteSearchFailed: PropTypes.bool.isRequired,
  routeNotFound: PropTypes.bool.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
  searchRoute: PropTypes.func.isRequired,
  hideErrorNotification: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.any.isRequired, // eslint-disable-line
  errors: PropTypes.any.isRequired, // eslint-disable-line
  touched: PropTypes.any.isRequired, // eslint-disable-line
  isWithinCountryDisabled: PropTypes.bool.isRequired,
  toggleWithinCountry: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const FilterPanelWrapper = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    route: props.route,
    ...props.calculations,
    departureHour: leftPad(props.calculations.departureHour, 2, "0"),
    departureMinute: leftPad(props.calculations.departureMinute, 2, "0"),
    ...props.restrictions,
  }),
  validationSchema: ({ t }) =>
    Yup.object().shape({
      route: Yup.object().shape({
        from: Yup.object().shape({
          value: Yup.string(),
          suggestion: Yup.object().when("value", {
            is: value => value === "",
            then: Yup.object().required(t("trace-filters-validation-required-error-message", "Нужно заполнить")),
            otherwise: Yup.object().required(
              t("trace-filters-validation-not-selected-error-message", "Выберите город из списка"),
            ),
          }),
        }),
        to: Yup.object().shape({
          value: Yup.string(),
          suggestion: Yup.object().when("value", {
            is: value => value === "",
            then: Yup.object().required(t("trace-filters-validation-required-error-message", "Нужно заполнить")),
            otherwise: Yup.object().required(
              t("trace-filters-validation-not-selected-error-message", "Выберите город из списка"),
            ),
          }),
        }),
      }),
      workingDay: Yup.number()
        .required(t("trace-filters-validation-required-error-message", "Нужно заполнить"))
        .min(1, t("trace-filters-validation-invalid-hours-min-value-error-message", "Не менее, чем 1"))
        .max(24, t("trace-filters-validation-invalid-hours-max-value-error-message", "Не более, чем 24")),
      dailyMileage: Yup.number()
        .required(t("trace-filters-validation-required-error-message", "Нужно заполнить"))
        .min(1, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка")),
      fuelConsumption: Yup.number()
        .required(t("trace-filters-validation-required-error-message", "Нужно заполнить"))
        .min(1, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка")),
      fuelPrice: Yup.number()
        .required(t("trace-filters-validation-required-error-message", "Нужно заполнить"))
        .min(1, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка")),
      departureDate: Yup.mixed().required(t("trace-filters-validation-required-error-message", "Нужно заполнить")),
      departureHour: Yup.number()
        .required(t("trace-filters-validation-required-error-message", "Нужно заполнить"))
        .min(0, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка"))
        .max(23, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка")),
      departureMinute: Yup.number()
        .required(t("trace-filters-validation-required-error-message", "Нужно заполнить"))
        .min(0, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка"))
        .max(59, t("trace-filters-validation-invalid-value-error-message", "Тут ошибка")),
    }),
  handleSubmit: (values, context) => {
    const calculations = {
      departureDate: values.departureDate,
      departureHour: parseInt(values.departureHour, 10),
      departureMinute: parseInt(values.departureMinute, 10),
      fuelConsumption: parseFloat(values.fuelConsumption),
      fuelPrice: parseFloat(values.fuelPrice),
      workingDay: parseInt(values.workingDay, 10),
      dailyMileage: parseFloat(values.dailyMileage),
    };
    const restrictions = {
      allowFerries: values.allowFerries,
      allowWinterRoads: values.allowWinterRoads,
      excludeMkad: values.excludeMkad,
      excludeTollRoads: values.excludeTollRoads,
      fastWay: values.fastWay,
      withinCountry: values.withinCountry,
      showOnlyLargeCities: values.showOnlyLargeCities,
    };

    const { searchRoute } = context.props;
    const { route } = values;

    const request = {
      calculations,
      restrictions,
      route: {
        ...route,
        through: route.through.filter(i => i.suggestion),
      },
    };

    searchRoute(request);
  },
})(FilterPanel);

export default withNamespaces()(FilterPanelWrapper);
