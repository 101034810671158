import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";

import Button from "ati-ui-react/components/Button";

import styles from "./RouteSummary.scss";

const toTitleCase = text => text !== null && text.substring(0, 1).toUpperCase() + text.substring(1, text.length);
const maxStringLengthOn320width = 17; // максимальная длина строки в блоке "Откуда-Куда"
const maxCityLengthOn320width = maxStringLengthOn320width / 2 - 1; // Максимальная длина названия города
const maxBlockLengthOn320width = maxStringLengthOn320width * 2; // Максимальная длина блока (две строки)
const arrowSignLength = 3; // длина стрелочки

const getRestrictions = (restrictions, t) => {
  const result = [];

  if (restrictions.excludeTollRoads) {
    result.push(t("trace-filters-filter-exclude-toll-roads", "без проезда по платным дорогам"));
  }

  if (restrictions.withinCountry) {
    result.push(t("trace-filters-filter-within-country", "в пределах страны"));
  }

  if (restrictions.excludeMkad) {
    result.push(t("trace-filters-filter-exclude-mkad", "исключить МКАД"));
  }

  if (restrictions.allowFerries) {
    result.push(t("trace-filters-filter-allow-ferries", "разрешить паромы"));
  }

  if (restrictions.allowWinterRoads) {
    result.push(t("trace-filters-filter-allow-winter-roads", "разрешить зимники"));
  }

  return result;
};

const trimLongCityName = (name, maxLength = maxCityLengthOn320width) => {
  if (typeof name === "string") {
    if (maxLength <= 0) {
      return "";
    }
    return name.length > maxLength ? name.substring(0, maxLength).concat("...") : name;
  }
  return name;
};

const renderCity = (city, index, isLast = false) => (
  <React.Fragment key={`${city}_${index}`}>
    <span className={classnames(styles.directionText, styles.city)}>{city}</span>
    <span className={styles.directionText}> ({index + 1})</span>
    {!isLast && <span className={styles.directionText}>, </span>}
  </React.Fragment>
);

const renderThroughCitiesList = (cities, allowedSymbolsCount) =>
  cities.map((city, index) => {
    const isLast = index === cities.length - 1;
    return renderCity(city, index, isLast, allowedSymbolsCount);
  });

const renderThroughCitiesList320 = (cities, allowedSymbolsCount) => {
  let sumCount = 0;
  let renderedCitiesSymbolsCount = 0;
  const renderedCities = [];
  let renderAdditional = true;
  for (let i = 0; renderAdditional && i < cities.length; i += 1) {
    const isLast = i === cities.length - 1;
    let city = cities[i];
    const beforeIncrement = sumCount;
    sumCount += city.length + (isLast ? 4 : 5); // " (1)".length = 4, " (1),".length = 5
    let sliceLast = 0;
    if (sumCount >= allowedSymbolsCount) {
      renderAdditional = false;
      sliceLast = allowedSymbolsCount - beforeIncrement;
      city = trimLongCityName(city, sliceLast);
    }
    renderedCitiesSymbolsCount += city.length;
    renderedCities.push(
      // eslint-disable-line
      <React.Fragment key={city}>
        <span className={classnames(styles.directionText, styles.city)}>{city}</span>
        {renderAdditional && (
          <React.Fragment>
            <span className={styles.directionText}> ({i + 1})</span>
            {!isLast && <span className={styles.directionText}>, </span>}
          </React.Fragment>
        )}
      </React.Fragment>,
    );
  }
  renderedCities.showNeeded = renderedCitiesSymbolsCount > 0;
  return renderedCities;
};

const renderRestriction = (index, restriction, isLast = false, isFirst = false) => (
  <React.Fragment key={index}>
    <span>{isFirst ? toTitleCase(restriction) : restriction}</span>
    {!isLast && <span>,</span>}
    <br />
  </React.Fragment>
);

const renderRestrictions = (restrictions, t) => {
  const strings = getRestrictions(restrictions, t);
  const { length } = strings;

  return strings.map((restriction, index) => {
    const isFirst = index === 0;
    const isLast = index === length - 1;
    return renderRestriction(index, restriction, isLast, isFirst);
  });
};

const breakWord = word => {
  const index = word.indexOf(" ") > 0 ? word.indexOf(" ") : word.indexOf("-");
  return [word.substring(0, index + 1), word.substring(index + 1)];
};

const RouteSummary = ({ from, to, through, restrictions, onRouteChange, t }) => {
  const throughHasElements = through.length > 0;
  // "Откуда" должно отображаться на первой строке вместе со стрелкой, "Куда" можно переносить.
  // размер всего блока не должен больше 2 строк

  const trimmedFrom = trimLongCityName(from, maxStringLengthOn320width);
  const trimmedFromLength = trimmedFrom.length;
  const trimmedTo =
    trimmedFromLength + to.length > maxBlockLengthOn320width ? trimLongCityName(to, maxStringLengthOn320width) : to;
  const trimmedToLength = trimmedTo.length;
  let trimmedToWithCarry = trimmedTo;
  const fromToLength = trimmedFromLength + trimmedToLength;
  if (
    trimmedFromLength <= maxStringLengthOn320width - arrowSignLength &&
    fromToLength >= maxStringLengthOn320width - arrowSignLength
  ) {
    trimmedToWithCarry = breakWord(trimmedTo);
  }
  const allowedThroughtLength = maxBlockLengthOn320width - fromToLength - 5; // "через ".length = 6
  const renderedThroughTrimmed = renderThroughCitiesList320(through, allowedThroughtLength); // "(1)".length = 3
  const showThroughBlock = renderedThroughTrimmed.showNeeded;

  return (
    <div className={classnames(styles.sectionWrapper, styles.sticky)}>
      <div className={styles.section}>
        <div className={styles.direction}>
          <div className={styles.not320Width}>
            <h1 className={classnames(styles.directionText, styles.title)}>
              <span className={styles.city}>{from}</span>
              <span className={styles.arrow}> → </span>
              <span className={styles.city}>{to}</span>{" "}
              {throughHasElements && (
                <React.Fragment>
                  <span className={styles.through}>{t("trace-route-summary-through", "через")}&nbsp;</span>
                  {renderThroughCitiesList(through)}
                </React.Fragment>
              )}
            </h1>
          </div>
          <div className={styles.for320Width}>
            <h1 className={styles.directionText}>
              <span className={styles.city}>{trimmedFrom}</span>
              <span className={styles.arrow}> → </span>
              <span className={styles.city}>{trimmedToWithCarry}</span>{" "}
              {throughHasElements && showThroughBlock && (
                <React.Fragment>
                  <span className={classnames(styles.directionText, styles.noBold)}>
                    {t("trace-route-summary-through", "через")}&nbsp;
                  </span>
                  {renderedThroughTrimmed}
                </React.Fragment>
              )}
            </h1>
          </div>
        </div>
        <div className={styles.restrictions}>{renderRestrictions(restrictions, t)}</div>
        <div className={styles.action}>
          <Button
            text={t("trace-route-summary-change-button-text", "Изменить")}
            size="small"
            action={onRouteChange}
          />
        </div>
      </div>
    </div>
  );
};

RouteSummary.defaultProps = {
  through: [],
};

RouteSummary.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  through: PropTypes.arrayOf(PropTypes.string),
  restrictions: PropTypes.shape({
    excludeTollRoads: PropTypes.bool.isRequired,
    withinCountry: PropTypes.bool.isRequired,
    excludeMkad: PropTypes.bool.isRequired,
    allowFerries: PropTypes.bool.isRequired,
    allowWinterRoads: PropTypes.bool.isRequired,
    fastWay: PropTypes.bool.isRequired,
    showOnlyLargeCities: PropTypes.bool.isRequired,
  }).isRequired,
  onRouteChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteSummary);
