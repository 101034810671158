import React from "react";
import PropTypes from "prop-types";

import styles from "./ErrorPanel.scss";

const ErrorPanel = ({ message }) => <div className={styles.panel}>{message}</div>;

ErrorPanel.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorPanel;
