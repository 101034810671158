import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import Button from "ati-ui-react/components/Button";

import {getFormattedDistance, getFormattedDate, getDistanceInKm} from "../../bl/routeRowService";

import { MAP_POINT_TYPES } from "../../constants";

import styles from "./MapPopup.scss";

class MapPopup extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  getPointTypeProperties = (visitType, intermediatePointIndex) => {
    const { t } = this.props;

    let icon = <div className={styles.commonPoint} />;
    let title = t("trace-map-point-type-passing", "Попутный населённый пункт");
    switch (visitType) {
      case MAP_POINT_TYPES.INTERMEDIATE_POINT:
        icon = <div className={styles.intermediatePoint}>{intermediatePointIndex}</div>;
        title = t("trace-map-point-type-intermediate", "Промежуточная точка");
        break;
      case MAP_POINT_TYPES.START_POINT:
        title = t("trace-map-point-type-start", "Начало маршрута");
        break;
      case MAP_POINT_TYPES.END_POINT:
        title = t("trace-map-point-type-end", "Конец маршрута");
        break;
      case MAP_POINT_TYPES.START_WITH_LOADING_POINT:
        title = t("trace-map-point-type-start-with-loading", "Начало маршрута с загрузкой");
        icon = <div className={styles.loadingPoint} />;
        break;
      case MAP_POINT_TYPES.END_WITH_UNLOADING_POINT:
        title = t("trace-map-point-type-end-with-unloading", "Конец маршрута с рагрузкой");
        icon = <div className={styles.unloadingPoint} />;
        break;
      case MAP_POINT_TYPES.LOADING_POINT:
        title = t("trace-map-point-type-loading", "Загрузка");
        icon = <div className={styles.loadingPoint} />;
        break;
      case MAP_POINT_TYPES.UNLOADING_POINT:
        title = t("trace-map-point-type-unloading", "Разгрузка");
        icon = <div className={styles.unloadingPoint} />;
        break;
      case MAP_POINT_TYPES.CUSTOMS:
        title = t("trace-map-point-type-customs", "Пограничный переход");
        icon = <div className={styles.customsPoint} />;
        break;
      case MAP_POINT_TYPES.COMMON_POINT:
      default:
        break;
    }

    return {
      icon,
      title
    };
  };

  render() {
    const { point: routeSegmentCityPoint, t, lng } = this.props;

    const isSpecialPoint = routeSegmentCityPoint.isSpecialPoint();
    const canExcludePoint = !isSpecialPoint;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.primaryTitle}>{routeSegmentCityPoint.city.name}</div>
          <div className={styles.secondaryTitle}>
            {routeSegmentCityPoint.region.name}
            {routeSegmentCityPoint.region.name && routeSegmentCityPoint.country.name && <span>, </span>}
            {routeSegmentCityPoint.country.name}
          </div>
          {canExcludePoint && (
            <div className={styles.controls}>
              <Button
                className={styles.button}
                simpleDashed
                text={t("trace-map-point-popover-remove-button-tooltip", "Удалить точку (в объезд)")}
                action={event => {
                  window.onExcludeCity({ cityId: routeSegmentCityPoint.city.id, cityName: routeSegmentCityPoint.city.name }, event);
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.body}>
          {routeSegmentCityPoint.visits.map(visit => {
            const format = t("trace-map-point-popover-date-format", "D MMMM в HH:mm");
            const distanceUnit = t("trace-common-units-kilometers-abbreviation", "км");
            const distance = t("trace-map-point-popover-distance", {
              defaultValue: "{{distance}} от начала",
              distance: getFormattedDistance(getDistanceInKm(visit.distanceToFirst), distanceUnit),
            });
            return (
              <div className={styles.visit} key={visit.estimatedTimeOfArrival}>
                <div className={styles.date}>{getFormattedDate(visit.estimatedTimeOfArrival, format, lng)}</div>
                <div className={styles.distance}>{distance}</div>
                <div className={styles.types}>
                  {visit.types.map(visitType => {
                    const properties = this.getPointTypeProperties(visitType, visit.intermediatePointIndex);
                    return (
                      <div className={styles.type} key={visitType}>
                        <div className={styles.title}>
                          <div className={styles.icon}>{properties.icon}</div>
                          <div className={styles.text}>{properties.title}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(MapPopup);
