import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
/* eslint-disable-next-line */
import { composeWithDevTools } from "redux-devtools-extension";

import state from "./state";
import rootReducer from "./reducers";
import enhancers from "./enhancers";

const middlewares = [thunk];

if (process.env.NODE_ENV !== "production") {
  const logger = require("redux-logger").logger; // eslint-disable-line
  middlewares.push(logger);
}

export default function () {
  const store = createStore(
    rootReducer,
    state,
    composeWithDevTools(enhancers.ReduxQuerySyncEnhancer, applyMiddleware(...middlewares)),
  );

  return store;
};
