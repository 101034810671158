import React from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";
import Link from "ati-ui-react/components/Link";

import styles from "./RouteNotFound.scss";
import { SUPPORT_EMAIL } from "../../constants";

const RouteNotFound = ({ containerRef }) => (
  <div className={styles.alert} ref={containerRef}>
    <span className={styles.message}>
      <Trans i18nKey="trace-route-not-found">Мы не нашли дорогу между этими населёнными пунктами</Trans>
    </span>
    <div className={styles.report}>
      <Link
        type="pseudo"
        small
        href={`mailto:${SUPPORT_EMAIL}`}
        gray
      >
        <Trans i18nKey="trace-route-not-found-report-error">Сообщить об ошибке</Trans>
      </Link>
    </div>
  </div>
);

RouteNotFound.defaultProps = {
  containerRef: undefined,
};

RouteNotFound.propTypes = {
  containerRef: PropTypes.func,
};

export default withNamespaces()(RouteNotFound);
