import React from "react";

import Banner from "../Banner";

import styles from "./FooterDesktopBanner.scss";

const FooterDesktopBanner = () => (
  <div className={styles.docPosition768_max}>
    <Banner bannerSizeId={19} minScreenWidth={751} />
  </div>
);

export default FooterDesktopBanner;
