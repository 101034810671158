import {
  connect
} from "react-redux";

import ToggleRouteStatisticsPanelButton from "./ToggleRouteStatisticsPanelButton";
import {
  toggleSidebar
} from "../../store/actions";

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar(true)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ToggleRouteStatisticsPanelButton);
