import React from "react";
import { hot } from "react-hot-loader";

import AdvertisingBoundary from "./components/AdvertisingBoundary";
import Root from "./components/Root";
import Error from "./components/Error";
import { reachTraceGoal, GOALS } from "./utils/gtm"

import "./App.scss";

import logger from "./logger";

class App extends React.Component {
  state = {
    error: false,
  };

  componentDidMount() {
    reachTraceGoal(GOALS.OpenPage);
  }

  componentDidCatch(error) {
    logger.captureException(error);
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <Error />;
    }

    window.prerenderReady = false;

    window.AtiUserData_AppSettings = window.AtiUserData_AppSettings || {};
    window.AtiUserData_AppSettings.SiteSection = 4;

    return (
      <React.Fragment>
        <AdvertisingBoundary />
        <Root />
      </React.Fragment>
    );
  }
}

export default hot(module)(App);
