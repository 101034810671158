import {MAP_POINT_TYPES} from "../constants";
import {getDistanceInKm} from "./routeRowService";

/**
 * Сегмент маршрута от нас. пункта до нас. пункта
 */
export class RouteSegment {
    #platonRate = 0;
    
    constructor(startPoint, visualRouteSegments, roadName, distance, traveledDistance, platonDistance, distancesWithTypes, 
                duration, traveledDuration, platonRate, changedCountry, changedRegion, hasTollRoad, isLastSegment, isEmptyRun) {
        this.startPoint = startPoint;                                   // RouteSegmentCityPoint
        this.visualRouteSegments = visualRouteSegments;                 // VisualRouteSegment[]
        this.roadName = roadName;                                       // string
        this.distance = distance;                                       // number
        this.traveledDistance = traveledDistance;                       // number
        this.platonDistance = platonDistance;                           // number
        this.distancesWithTypes = distancesWithTypes;                   // DistanceWithType[]
        this.platonFare = getDistanceInKm(platonDistance) * platonRate; // number
        this.duration = duration;                                       // moment.duration (продолжительность езды по сегменту)
        this.traveledDuration = traveledDuration;                       // moment.duration (продолжительность всего уже пройденного пути)
        this.#platonRate = platonRate;                                  // number
        this.changedCountry = changedCountry;                           // RouteCountry (страна следующей точки, если страна меняется)
        this.changedRegion = changedRegion;                             // RouteRegion (регион следующей точки, если регион меняется)
        this.hasTollRoad = hasTollRoad;                                 // boolean
        this.isLastSegment = isLastSegment;                             // boolean
        this.isEmptyRun = isEmptyRun;                                   // boolean
    }
    
    clone() {
        return new RouteSegment(this.startPoint.clone(), this.visualRouteSegments.map(vrs => vrs.clone()),
            this.roadName, this.distance, this.traveledDistance, this.platonDistance, this.distancesWithTypes.map(dwt => dwt.clone()),
            this.duration.clone(), this.traveledDuration.clone(), this.#platonRate, this.changedCountry, this.changedRegion,
            this.hasTollRoad, this.isLastSegment, this.isEmptyRun);
    }
    
    appendSegment(routeSegment) {
        this.visualRouteSegments.push(...routeSegment.visualRouteSegments);
        this.distance += routeSegment.distance;
        this.platonDistance += routeSegment.platonDistance;
        this.distancesWithTypes.push(...routeSegment.distancesWithTypes);
        this.platonFare += routeSegment.platonFare;
        this.duration.add(routeSegment.duration);
        this.changedCountry = this.changedCountry || routeSegment.changedCountry;
        this.changedRegion = this.changedRegion || routeSegment.changedRegion;
        this.hasTollRoad = this.hasTollRoad || routeSegment.hasTollRoad;
        this.isLastSegment = this.isLastSegment || routeSegment.isLastSegment;
        this.isEmptyRun = this.isEmptyRun || routeSegment.isEmptyRun;
        this.roadName = this.roadName || routeSegment.roadName;
    }
    
    setChangedCountry(changedCountry) {
        this.changedCountry = changedCountry;
    }

    setChangedRegion(changedRegion) {
        this.changedRegion = changedRegion;
    }
}

export class RouteCountry {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}

export class RouteRegion {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}

export class RouteCity {
    constructor(id, name, size, attribute) {
        this.id = id;
        this.name = name;
        this.size = size;
        this.attribute = attribute;
    }
}

/**
 * Точка маршрута, представляющая из себя нас. пункт.
 */
export class RouteSegmentCityPoint {
    constructor(city, region, country, isKeyPoint, visits, geoPoint, address) {
        this.city = city;               // RouteCity
        this.region = region;           // RouteRegion
        this.country = country;         // RouteCountry
        this.isKeyPoint = isKeyPoint;   // boolean
        this.visits = visits;           // RouteSegmentCityPointVisit[]
        this.geoPoint = geoPoint;       // [lat, lon]
        this.address = address;         // string
    }

    clone() {
        return new RouteSegmentCityPoint(this.city, this.region, this.country, this.isKeyPoint, this.visits, this.geoPoint, this.address);
    }
    
    addVisit(visit) {
        // visit: RouteSegmentCityPointVisit
        this.visits.push(visit);
    }
    
    isSpecialPoint() {
        return this.visits.some(v => v.isSpecialPoint());
    }

    isCommonPoint() {
        return this.visits.some(v => v.isCommonPoint());
    }
    
    isCustomsPoint() {
        return this.visits.some(v => v.isCustomsPoint());
    }
    
    isIntermediatePoint() {
        return this.visits.some(v => v.isIntermediatePoint());
    }
}

/**
 * Тип дороги и длина участка.
 */
export class DistanceWithType {
    constructor(type, distance) {
        this.type = type;           // ROAD_TYPE_ID_NAME_MAP
        this.distance = distance;   // number
    }
    
    clone() {
        return new DistanceWithType(this.type, this.distance);
    }
}

/**
 * Сегмент для отрисовки маршрута
 */
export class VisualRouteSegment {
    geoPoints = []; // [lat = 0.0, lon = 0.0]
 
    constructor(type) {
        this.type = type;   // VisualRouteSegmentType (бесплатная/платная/платон)
    }
    
    clone() {
        const visualRouteSegment = new VisualRouteSegment(this.type);
        visualRouteSegment.geoPoints = this.geoPoints;
        return visualRouteSegment;
    }

    addPoint(lat, lon){
        this.geoPoints.push([lat, lon]);
    }
}

/**
 * Информация о посещении нас. пункта во время движения по маршруту
 */
export class RouteSegmentCityPointVisit {
    types = []; // MAP_POINT_TYPES[] (загрузка/разгрузка/старт/финиш/таможня/попутная точка)
    
    constructor(estimatedTimeOfArrival, distanceToFirst, intermediatePointIndex) {
        this.estimatedTimeOfArrival = estimatedTimeOfArrival;   // moment
        this.distanceToFirst = distanceToFirst;                 // number
        this.intermediatePointIndex = intermediatePointIndex;   // number
    }
    
    addType(type) {
        this.types.push(type);
    }

    setIntermediatePointIndex(intermediatePointIndex) {
        this.intermediatePointIndex = intermediatePointIndex;
    }

    isSpecialPoint() {
        return this.types.some(type => type !== MAP_POINT_TYPES.COMMON_POINT) || this.isCustomsPoint();
    }

    isCommonPoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.COMMON_POINT);
    }

    isCustomsPoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.CUSTOMS);
    }

    isIntermediatePoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.INTERMEDIATE_POINT);
    }

    isLoadingPoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.LOADING_POINT);
    }

    isUnloadingPoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.UNLOADING_POINT);
    }

    isStartPoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.START_POINT);
    }

    isEndPoint() {
        return this.types.some(type => type === MAP_POINT_TYPES.END_POINT);
    }
}

export const VisualRouteSegmentType = {
    Free: 1,
    Toll: 2,
    Platon: 3
};