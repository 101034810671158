class Polyline {
  constructor({key, positions, color, tooltip, dashed, offset }) {
    this.key = key;
    this.positions = [positions];
    this.color = color;
    this.isDashed = dashed;
    this.tooltip = tooltip;
    this.offset = offset || 0;
  }
}

export default Polyline;
