// eslint-disable
import moment from "moment/moment";
import L from "leaflet";
import { getRouteAllPoints, createSearchRouteRequest } from "./routeService";
import api from "../api";
import SearchRouteResult from "../models/SearchRouteResult";
import {
  DistanceWithType, RouteCity, RouteCountry, RouteRegion,
  RouteSegment,
  RouteSegmentCityPoint,
  RouteSegmentCityPointVisit,
  VisualRouteSegment,
  VisualRouteSegmentType
} from "./RouteSegment";
import {MAP_POINT_TYPES} from "../constants";
import {getDistanceInKm} from "./routeRowService";

const makeDistancerRequestCity = point => {
  const result = {
    city_id: point.suggestion.id,
  };

  if (point.address) {
    result.address = point.address;
  }

  if (!point.geo_point) {
    return result;
  }
  const { lat, lon } = point.geo_point;
  if (!lat || !lon) {
    return result;
  }
  result.geo_point = {
    lon,
    lat,
  };
  return result;
};

const createSearchRouteByRoadsInCityRequest = ({ route, ...rest }) => {
  const allPoints = getRouteAllPoints(route);
  const cities = allPoints.map(makeDistancerRequestCity);

  return createSearchRouteRequest({
    allPoints,
    cities,
    ...rest,
  });
};

function getRouteSegmentMetrics(points, startIndex) {
  let platonRoadName = "";
  let segmentDistance = 0;
  const segmentDuration = moment.duration(0);
  let platonDistance = 0;
  const distancesWithTypes = []; // DistanceWithType[]
  let currentType = -1;
  let currentTypeLength = 0;
  let hasTollRoad = false;

  for (let i = startIndex; i < points.length; i += 1) {
    const point = points[i];
    if(!point.isIncityPoint && i !== startIndex){
      // Дошли до следующего населенного пункта
      // Нужно положить в массив посчитанный фрагмент по типам
      distancesWithTypes.push(new DistanceWithType(currentType, currentTypeLength));
      break;
    }

    const {way} = point;
    if(currentType !== way.type) {
      if(currentType !== -1) {
        // Сменился тип дороги, нужно положить в массив посчитанный фрагмент
        distancesWithTypes.push(new DistanceWithType(currentType, currentTypeLength));
      }
      currentType = way.type;
      currentTypeLength = 0;
    }

    hasTollRoad = hasTollRoad || way.isTollRoad;
    segmentDistance += point.distanceToNext;
    segmentDuration.add(moment.duration(point.timeSpanToNext));
    currentTypeLength += point.distanceToNext;
    if(way.isCollectPlaton) {
      platonRoadName = platonRoadName || way.name;
      platonDistance += point.distanceToNext;
    }
  }
  return {platonRoadName, platonDistance, segmentDistance, segmentDuration, distancesWithTypes, hasTollRoad};
}

function isSegmentStartPointEqualsKeyPointDirection(segmentStartPoint, keyPointDirection) {
  if(!segmentStartPoint.isKeyPoint) {
    return false;
  }
  if(keyPointDirection.geo_point && keyPointDirection.geo_point.lat !== 0 && keyPointDirection.geo_point.lon !== 0) {
    // координату (0, 0) рассматриваем как отсутствие координаты.
    const exactlyEquals = keyPointDirection.geo_point.lat === segmentStartPoint.geoPoint[0] && keyPointDirection.geo_point.lon === segmentStartPoint.geoPoint[1];
    if(exactlyEquals) {
      return exactlyEquals;
    }
    
    // на бэкенде есть оптимизация, что если точка в пределах 100 метров от центра, то бэкенд проставляет центр города вместо этой точки и координаты будут от центра.
    return L.latLng(keyPointDirection.geo_point.lat, keyPointDirection.geo_point.lon).distanceTo(L.latLng(segmentStartPoint.geoPoint[0], segmentStartPoint.geoPoint[1])) < 100;
  }
  return segmentStartPoint.city.id === keyPointDirection.suggestion.id;
}

function addRouteSegmentCityPointVisits(segmentStartPoint, point, keyPointDirection, intermediatePointIndex) {
  const estimatedTimeOfArrival = moment(point.arrivationDateTime);

  const visit = new RouteSegmentCityPointVisit(estimatedTimeOfArrival, point.distanceToFirst, undefined);
  segmentStartPoint.addVisit(visit);

  const isCustom = (point.city.attribute & 16) !== 0;
  if (isCustom) {
    visit.addType(MAP_POINT_TYPES.CUSTOMS);
  }

  const equals = isSegmentStartPointEqualsKeyPointDirection(segmentStartPoint, keyPointDirection);
  if(!equals) {
    visit.addType(MAP_POINT_TYPES.COMMON_POINT);
    return visit;
  }

  if(keyPointDirection.isIntermediatePoint) {
    // eslint-disable-next-line no-param-reassign
    intermediatePointIndex[0] += 1;
    visit.addType(MAP_POINT_TYPES.INTERMEDIATE_POINT);
    visit.setIntermediatePointIndex(intermediatePointIndex[0]);
    return visit;
  }

  if(keyPointDirection.isStartPoint) {
    const type = keyPointDirection.isLoadingPoint ? MAP_POINT_TYPES.START_WITH_LOADING_POINT : MAP_POINT_TYPES.START_POINT;
    visit.addType(type);
    return visit;
  }

  if(keyPointDirection.isEndPoint) {
    const type = keyPointDirection.isUnloadingPoint ? MAP_POINT_TYPES.END_WITH_UNLOADING_POINT : MAP_POINT_TYPES.END_POINT;
    visit.addType(type);
    return visit;
  }

  if(keyPointDirection.isLoadingPoint && keyPointDirection.isUnloadingPoint) {
    visit.addType(MAP_POINT_TYPES.UNLOADING_POINT);
    visit.addType(MAP_POINT_TYPES.LOADING_POINT);
    return visit;
  }

  if(keyPointDirection.isLoadingPoint) {
    visit.addType(MAP_POINT_TYPES.LOADING_POINT);
    return visit;
  }

  if(keyPointDirection.isUnloadingPoint) {
    visit.addType(MAP_POINT_TYPES.UNLOADING_POINT);
    return visit;
  }

  visit.addType(MAP_POINT_TYPES.COMMON_POINT);
  return visit;
}

/**
 * являются ли идентичными два пути по признакам платности дороги
 */
function isWaysEquals(way1, way2){
  // segment1, segment2: VisualRouteSegment
  if(way1 === undefined && way2 === undefined) {
    return true;
  }
  if(way1 === undefined || way2 === undefined) {
    return false;
  }
  return way1.isCollectPlaton === way2.isCollectPlaton && way1.isTollRoad === way2.isTollRoad;
}

function getVisualRouteSegmentType(way) {
  if (way.isCollectPlaton) {
    return VisualRouteSegmentType.Platon;
  }
  if (way.isTollRoad) {
    return VisualRouteSegmentType.Toll;
  }
  return VisualRouteSegmentType.Free;
}

function getVisualRouteSegments(points, startIndex){
  const visualRouteSegments = [];
  // eslint-disable-next-line no-undef-init
  let prevWay = undefined;
  // eslint-disable-next-line no-undef-init
  let visualRouteSegment = undefined;

  for (let i = startIndex; i < points.length; i += 1) {
    const point = points[i];
    if(!point.isIncityPoint && i !== startIndex){
      // дошли до следующего населенного пункта
      visualRouteSegment.addPoint(point.latitude, point.longitude);
      break;
    }

    const {way} = point;
    if(!isWaysEquals(prevWay, way)) {
      if(visualRouteSegment) {
        // добавить точку для завершения сегмента типа prevWay
        visualRouteSegment.addPoint(point.latitude, point.longitude);
      }

      prevWay = way;
      const visualRouteSegmentType = getVisualRouteSegmentType(way);
      visualRouteSegment = new VisualRouteSegment(visualRouteSegmentType);
      visualRouteSegments.push(visualRouteSegment);
      visualRouteSegment.addPoint(point.latitude, point.longitude);
    }
    else {
      if(!visualRouteSegment) {
        console.error("visualRouteSegment is null");
      }
      visualRouteSegment.addPoint(point.latitude, point.longitude);
    }
  }
  return visualRouteSegments;
}

function getRouteSegments(data, direction) {
  const { route: {points}, platonRate  } = data;
  const routeSegments = []; // RouteSegment[]
  const visitedCityPoints = new Map();
  const keyPoints = [direction.from, ...direction.through, direction.to];
  const routeMayHaveEmptyRun = keyPoints.some(point => point.isLoadingPoint || point.isUnloadingPoint);
  let currentKeyPointIndex = 0;
  const intermediatePointIndex = [0]; // массивом сделано для того, чтобы можно было передать параметром по ссылке в метод и там изменить. 
  let keyPointDirection;
  let isEmptyRunSegment = false;
  
  for (let i = 0; i < points.length; i += 1) {
    const point = points[i];
    if (point.isIncityPoint) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const city = new RouteCity(point.city.id, point.city.name, point.city.size);
    const region = new RouteRegion(point.region.id, point.region.name);
    const country = new RouteCountry(point.country.id, point.country.name);

    if(point.isKeyPoint) {
      // eslint-disable-next-line no-plusplus
      keyPointDirection = keyPoints[currentKeyPointIndex++];
    }
    
    const lat = point.latitude;
    const lon = point.longitude;
    const mapKey = `${lat}, ${lon}`;
    let segmentStartPoint = visitedCityPoints.get(mapKey);
    if(!segmentStartPoint){
      segmentStartPoint = new RouteSegmentCityPoint(city, region, country, point.isKeyPoint, [], [lat, lon], point.address);
      visitedCityPoints.set(mapKey, segmentStartPoint);
    }

    const currentVisit = addRouteSegmentCityPointVisits(segmentStartPoint, point, keyPointDirection, intermediatePointIndex);
    const isLoadingPoint = currentVisit.isLoadingPoint();
    const isUnloadingPoint = currentVisit.isUnloadingPoint();
    const isStartPoint = currentVisit.isStartPoint();
    const isEndPoint = currentVisit.isEndPoint();
    
    
    if(point.isKeyPoint) {
      if (isEmptyRunSegment) {
        isEmptyRunSegment = routeMayHaveEmptyRun && !isLoadingPoint;
      } else {
        isEmptyRunSegment = routeMayHaveEmptyRun && (isStartPoint && !isLoadingPoint || isUnloadingPoint && !isEndPoint);

        if (isLoadingPoint && isUnloadingPoint) {
          isEmptyRunSegment = false;
        }
      }
    }
    
    const visualRouteSegments = getVisualRouteSegments(points, i);
    const {platonRoadName, platonDistance, segmentDistance, segmentDuration, 
      distancesWithTypes, hasTollRoad} = getRouteSegmentMetrics(points, i);
    const traveledDuration =  moment.duration(point.timeToFirst);
    const traveledDistance =  point.distanceToFirst;
   
    const isLastSegment = i === points.length - 1;
    const routeSegment = new RouteSegment(segmentStartPoint, visualRouteSegments, platonRoadName, segmentDistance, traveledDistance, platonDistance, distancesWithTypes,
        segmentDuration, traveledDuration, platonRate, undefined, undefined, hasTollRoad, isLastSegment, isEmptyRunSegment);
    routeSegments.push(routeSegment);
  }

  for (let i = 0; i < routeSegments.length; i += 1) {
    const routeSegment = routeSegments[i];
    const nextRouteSegment = routeSegments[i + 1];
    let changedCountry;
    let changedRegion;
    
    if(nextRouteSegment) {
      changedCountry = routeSegment.startPoint.country.id !== nextRouteSegment.startPoint.country.id ? nextRouteSegment.startPoint.country : undefined;
      changedRegion = routeSegment.startPoint.region.id !== nextRouteSegment.startPoint.region.id ? nextRouteSegment.startPoint.region : undefined;
      routeSegment.setChangedCountry(changedCountry);
      routeSegment.setChangedRegion(changedRegion);
    }
  }
  return routeSegments;
}

const splitDuration = duration => {
  const { days, hours, minutes } = duration._data; /* eslint-disable-line no-underscore-dangle */
  return {days, hours, minutes};
};

const splitAndRoundDuration = duration => {
  const { days, hours, minutes } = duration._data; /* eslint-disable-line no-underscore-dangle */

  const precision = 10;
  let roundedMinutes = minutes > precision ? Math.round(minutes / precision) * precision : Math.round(minutes);
  let roundedHours = hours;
  if (roundedMinutes === 60) {
    roundedHours = hours + 1;
    roundedMinutes = 0;
  } else if (days > 0) {
    roundedHours = minutes > 0 ? hours + 1 : hours;
    roundedMinutes = 0;
  }

  let roundedDays = days;
  if (roundedHours === 24) {
    roundedDays = days + 1;
    roundedHours = 0;
  }

  return {roundedDays, roundedHours, roundedMinutes};
};

function calculateMetrics(routeSegments, calculations, platonRate, platonRoadsLength) {
  const { fuelPrice, fuelConsumption, departureDate } = calculations;
  const lastRouteSegment = routeSegments[routeSegments.length - 1];
  const totalDistance = getDistanceInKm(lastRouteSegment.traveledDistance);
  const totalFuelConsumption = Math.round((totalDistance / 100) * fuelConsumption);
  const totalFuelCosts = Math.round(totalFuelConsumption * fuelPrice);

  const totalDuration = lastRouteSegment.traveledDuration;
  const { days, hours, minutes } = splitDuration(totalDuration);
  const { roundedDays, roundedHours, roundedMinutes} = splitAndRoundDuration(totalDuration);
  const totalHours = days * 24 + hours;
  const {estimatedTimeOfArrival} = lastRouteSegment.startPoint.visits[lastRouteSegment.startPoint.visits.length - 1];
  
  return {
    totalDistance,
    totalDuration,

    platonRate,
    platonRoadsLength,
    totalPlatonCosts: Math.round(platonRate * platonRoadsLength),

    departureDate,
    fuelPrice,
    fuelConsumption,
    totalFuelCosts,
    totalFuelConsumption,

    days,
    hours,
    minutes,
    roundedDays,
    roundedHours,
    roundedMinutes,
    totalHours,
    
    estimatedTimeOfArrival
  };
}

/**
 * Поиск маршрута по заданным параметрам поиска.
 * Маршрут будет включать дополнительные точки по дорогам внутри городов.
 * @param {SearchRouteParameters} searchParameters Объект с параметрами поиска
 */
export const searchRouteByRoadsInCities = searchParameters => {
  const request = createSearchRouteByRoadsInCityRequest(searchParameters);
  return api.distancer.searchCityRoute(request).then(response => {
    const { data } = response;
    const routeIsFound = data.route.points.length > 0;
    if (!routeIsFound) {
      return new SearchRouteResult({
        success: false,
        route: data.route,
      });
    }
    const direction = searchParameters.route;
    const {calculations} = searchParameters;
    const routeSegments = getRouteSegments(data, direction);
    const metrics = calculateMetrics(routeSegments, calculations, data.platonRate, data.platonRoadsLength);
    return new SearchRouteResult({
      success: true,
      route: {routeSegments, metrics}
    });
  });
};

export default searchRouteByRoadsInCities;
