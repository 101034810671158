import i18n from "i18next";
import XHR from "i18next-xhr-backend";

export const i18nextOptions = {
  backend: {
    loadPath: "/locales/trace.{{lng}}.json",
  },
};

export const i18nInstance = i18n.use(XHR);
