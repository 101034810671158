import { connect } from "react-redux";

import Sidebar from "./Sidebar";

import { toggleSidebar } from "../../store/actions";

const mapStateToProps = state => ({
  showResults: state.showResults,
  sidebarToggled: state.sidebarToggled,
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar(true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
