import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import L from "leaflet";

import Tooltip from "ati-ui-react/components/ReactTooltip";

import styles from "./MapButton.scss";

export default class MapButton extends React.PureComponent {
  componentDidMount() {
    const { id } = this.props;
    const button = document.getElementById(id);
    L.DomEvent.disableClickPropagation(button);
  }

  render() {
    const { id, className, onClick, iconUrl, altText } = this.props;
    return (
      <React.Fragment>
        <button
          id={id}
          data-tip
          data-for={id}
          className={classnames(styles.button, className)}
          onClick={onClick}
          type="button"
        >
          <img src={iconUrl} className={styles.content} alt={altText} />
        </button>
        {!L.Browser.mobile && (
          <Tooltip id={id} effect="solid" place="left">
            {altText}
          </Tooltip>
        )}
      </React.Fragment>
    );
  }
}

MapButton.defaultProps = {
  className: null,
};

MapButton.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  altText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
