/* eslint-disable no-underscore-dangle */

import moment from "moment";

import { toThousandSeparatedNumber, joinWithThinSpace } from "../utils";

export const getTime = (mergedData, postPoint) => {
  const groupDuration = moment.duration(mergedData.timeSpanToNext);
  const postPointDuration = moment.duration(postPoint.timeSpanToNext);
  const result = groupDuration.add(postPointDuration);

  const { days } = result._data;
  const timeSpanToNext = `${days}.${moment.utc(result.asMilliseconds()).format("HH:mm:ss.SSS")}`;
  const timeToNext = `${days}.${moment.utc(result.asMilliseconds()).format("HH:mm")}`;

  return {
    timeSpanToNext,
    timeToNext,
  };
};

export const hasToll = point => {
  const { way, isGroup } = point;

  return isGroup ? point.isTollRoad : way.isTollRoad;
};

export const hasPlaton = point => {
  const { way, isGroup } = point;

  if (isGroup) {
    return point.isCollectPlaton;
  }

  return !hasToll(point) && way.isCollectPlaton;
};

export const getRoadName = (groupRoadHasName, groupRoadName, postPointRoadName) => {
  if (groupRoadHasName && groupRoadName !== postPointRoadName) {
    return "";
  }
  if (!groupRoadHasName && groupRoadName === "" && postPointRoadName !== "") {
    return postPointRoadName;
  }
  return postPointRoadName;
};

// преобразует расстояние из метров в километры
export const getDistanceInKm = distance => {
  return Math.round(distance / 1000);
}

export const getFormattedDistance = (distance, unit) => joinWithThinSpace(toThousandSeparatedNumber(distance), unit); // "км"
export const getFormattedDate = (date, format, lng) => {
  const locale = lng === "en-US" ? "en" : "ru";
  return moment(date)
    .local()
    .locale(locale)
    .format(format);
};

export const getFormattedPrice = (price) => {
  return joinWithThinSpace(toThousandSeparatedNumber(price), "₽");
};

export const getFormattedTimeSpan = (time, translations) => {
  const hour = time._data.hours;
  const minute = time._data.minutes;
  const day = time._data.days;

  const days = day === 0 ? "" : `${joinWithThinSpace(day, translations.days)} `; // "д"
  const hours = hour === 0 ? "" : `${joinWithThinSpace(hour, translations.hours)} `; // "ч"
  const minutes = minute === 0 ? "" : `${joinWithThinSpace(minute, translations.minutes)}`; // "мин"

  return hours === "" && minutes === "" ? `${joinWithThinSpace(0, translations.minutes)}` : days + hours + minutes;
};

