import React from "react";
import L, {divIcon} from "leaflet";
import {Marker, Popup, Tooltip} from "react-leaflet";
import PropTypes from "prop-types";
import {withNamespaces} from "react-i18next";

import styles from "./MapCity.scss";

import MapPopup from "../MapPopup";

import {getIconData} from "./MapCity.bl";

const createHtmlIcon = (icon, routeSegmentCityPoint, buttonTooltip, intermediatePointIndex) =>
  divIcon({
    className: "my-div-icon",
    html: `<div class=${styles.intermediate}>
          <div class=${styles.marker}>
            <div class=${styles.content}>
              <div class=${styles.index}>${intermediatePointIndex}</div>
              <button type="button" class=${styles.exclude} onClick='return window.onExcludeCity({cityId:${routeSegmentCityPoint.city.id
      }, cityName:"${routeSegmentCityPoint.city.name}"}, event)' title="${buttonTooltip}">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14">
                  <path d="M8.637.988h2.601V2.5H.762V.988h2.601L4.137.25h3.726l.774.738zM3.328 6.895l1.617 1.617-1.582 1.582 1.055 1.054L6 9.566l1.582 1.582 1.055-1.054-1.582-1.582 1.582-1.617L7.582 5.84 6 7.457 4.418 5.84l-1.09 1.055zM1.5 12.238v-9h9v9c0 .399-.152.75-.457 1.055-.305.305-.656.457-1.055.457H3.012c-.399 0-.75-.152-1.055-.457-.305-.305-.457-.656-.457-1.055z" />
                </svg>
              </button>
            </div>
          </div>
        </div>`,
    iconAnchor: icon.iconAnchor,
    iconSize: icon.size,
  });

const createImageIcon = icon =>
  L.icon({
    iconUrl: icon.path,
    iconSize: icon.size,
    iconAnchor: icon.iconAnchor,
  });

class MapCity extends React.Component {
  static defaultProps = {
    showPopup: true
  };

  render() {
    const {
      point: routeSegmentCityPoint,
      showPopup,
      markerRef,
      t,
      i18n: { language }
    } = this.props;

    const latlng = L.latLng(routeSegmentCityPoint.geoPoint[0], routeSegmentCityPoint.geoPoint[1]);
    
    let fullName = routeSegmentCityPoint.city.name;
    if (routeSegmentCityPoint.region && routeSegmentCityPoint.region.name) {
      fullName += `, ${routeSegmentCityPoint.region.name}`;
    }
    if (routeSegmentCityPoint.country && routeSegmentCityPoint.country.name) {
      fullName += `, ${routeSegmentCityPoint.country.name}`;
    }

    const iconData = getIconData(routeSegmentCityPoint, language);
    const buttonTooltip = t("trace-map-city-icon-remove-point-tooltip", "Удалить точку (в объезд)");
    const {visits} = routeSegmentCityPoint;
    const icon = routeSegmentCityPoint.isIntermediatePoint() && visits.length === 1
        ? createHtmlIcon(iconData, routeSegmentCityPoint, buttonTooltip, visits[0].intermediatePointIndex) 
        : createImageIcon(iconData);

    return (
      <Marker position={latlng} draggable={false} icon={icon} zIndexOffset={1000} ref={markerRef}>
        {showPopup && (
          <Popup className={styles.popup} autoPan={false}>
            <MapPopup point={routeSegmentCityPoint} />
          </Popup>
        )}
        {!L.Browser.mobile && (
          <Tooltip direction="bottom" opacity={1.0}>
            <div className={styles.tooltipText}>{fullName}</div>
          </Tooltip>
        )}
      </Marker>
    );
  }
}

MapCity.defaultProps = {
  markerRef: undefined,
};

MapCity.propTypes = {
  t: PropTypes.func.isRequired,
  showPopup: PropTypes.bool,
  markerRef: PropTypes.shape()
};

export default withNamespaces()(MapCity);
