class Logger {
  constructor() {
    this.isLoggingEnabled = typeof Raven !== "undefined";

    if (this.isLoggingEnabled) {
      const dsn = process.env.SENTRY_DSN;
      Raven.config(dsn, {
        release: process.env.APP_VERSION,
      }).install();
    }
  }

  captureMessage = (message) => {
    if (this.isLoggingEnabled) {
      Raven.captureMessage(message);
    }
  }

  captureException = (exception) => {
    if (this.isLoggingEnabled) {
      Raven.captureException(exception);
    }
  }

  setUserContext = (context) => {
    if (this.isLoggingEnabled) {
      Raven.setUserContext(context);
    }
  };
}

export default new Logger();
