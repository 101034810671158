import { connect } from "react-redux";

import RouteSummary from "./RouteSummary";

import { changeRoute } from "../../store/actions";

const mapStateToProps = state => ({
  from: state.route.from.suggestion.name,
  to: state.route.to.suggestion.name,
  through: state.route.through.map(c => c.suggestion.name),
  restrictions: state.restrictions,
  calculations: state.calculations
});

const mapDispatchToProps = dispatch => ({
  onRouteChange: () => dispatch(changeRoute()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteSummary);
