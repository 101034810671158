import React from "react";

import Banner from "../Banner";

import styles from "./FooterTabletBanner.scss";

const FooterTabletBanner = () => (
  <div className={styles.docPositionContainer560_767}>
    <div id="docLeft560_767" className={styles.docPosition560_767Left}>
      <Banner bannerSizeId={19} minScreenWidth={543} maxScreenWidth={750} />
    </div>
    <div id="docRight560_767" className={styles.docPosition560Right}>
      <Banner bannerSizeId={19} minScreenWidth={543} maxScreenWidth={750} />
    </div>
  </div>
);

export default FooterTabletBanner;
