import { connect } from "react-redux";

import SeoText from "./SeoText";

const mapStateToProps = state => ({
  from: state.route.from.suggestion.name,
  to: state.route.to.suggestion.name,
  through: state.route.through.map(p => p.suggestion.name),
  metrics: state.result.metrics
});

export default connect(mapStateToProps)(SeoText);
