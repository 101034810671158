import React from "react";
import PropTypes from "prop-types";

import styles from "./FormField.scss";

const FormField = ({ children, error, touched }) => (
  <div>
    {children}
    {error && touched && <span className={styles.error}>{error}</span>}
  </div>
);

FormField.defaultProps = {
  error: undefined,
  touched: undefined,
};

FormField.propTypes = {
  error: PropTypes.string,
  children: PropTypes.node.isRequired,
  touched: PropTypes.bool,
};

export default FormField;
