import React from "react";
import PropTypes from "prop-types";
import { v4 as uid } from "uuid";
import classnames from "classnames";
import { withNamespaces, Trans } from "react-i18next";

import { toThousandSeparatedNumber } from "../../utils";

import styles from "./SeoText.scss";
import SeoHelmet from "../SeoHelmet/SeoHelmet";

const SeoText = ({
  className,
  from,
  to,
  through,
  metrics: {
    totalDistance,
    totalPlatonCosts,
    fuelConsumption,
    totalFuelConsumption,
    fuelPrice,
    totalFuelCosts,
    roundedDays,
    roundedHours,
    roundedMinutes,
  },
  t,
}) => {
  let durationText = "";

  const daysAbbreviation = t("trace-common-units-days-abbreviation", "дн");
  const hoursAbbreviation = t("trace-common-units-hours-abbreviation", "ч");
  const minutesAbbreviation = t("trace-common-units-minutes-abbreviation", "мин");

  if (roundedDays > 0) {
    durationText += `${roundedDays}${"\u00A0"}${daysAbbreviation}`;
  }
  if (roundedHours > 0) {
    if (durationText) {
      durationText += "\u00A0";
    }
    durationText += `${roundedHours}${"\u00A0"}${hoursAbbreviation}`;
  }
  if (roundedDays === 0 && roundedMinutes > 0) {
    if (durationText) {
      durationText += "\u00A0";
    }
    durationText += `${roundedMinutes}${"\u00A0"}${minutesAbbreviation}`;
  }

  const throughTemplate = through.length > 0 ? t("trace-meta-through-part", {
    defaultValue: " через {{through}}",
    through: through.join(", "),
  }) : "";

  const title =
    "Расстояние {{from}} - {{to}}{{through}} на машине в километрах — Сервис расчета расстояний АТИ";

  const description =
    "Расстояние {{from}} - {{to}}{{through}} составляет {{totalDistance}} км по трассе. Ориентировочное время преодоления расстояния на машине составляет {{duration}}";

  const IntermediatePoints = () =>
    through.map((p, i) => (
      <span key={uid()}>
        {i > 0 && ", "}
        <span key={uid()}>{p}</span>
      </span>
    ));

  const totalDistanceText = toThousandSeparatedNumber(totalDistance);
  const fuelConsumptionText = toThousandSeparatedNumber(totalFuelConsumption);
  const fuelCostsText = toThousandSeparatedNumber(totalFuelCosts);
  const platonCostsText = toThousandSeparatedNumber(totalPlatonCosts);
  const seoTitle = t("trace-meta-title-route", {
    defaultValue: title,
    from,
    to,
    through: throughTemplate,
  });
  const seoDescription = t("trace-meta-description-route", {
    defaultValue: description,
    from,
    to,
    through: throughTemplate,
    totalDistance,
    duration: durationText,
  });

  const seoSiteName = t(
    "trace-meta-og-site-name",
    "TRACE.ATI.SU – Расчет расстояний автомобильных дорог",
  );


  return (
    <section className={classnames(styles.seoSection, className)}>
      <h2 className={styles.title}>
        <Trans i18nKey="trace-sidebar-seo-route-title">
          Расстояние {{ from }} - {{ to }}
        </Trans>
      </h2>
      <p className={styles.description}>
        {through.length > 0 && (
          <span className={styles.intermediate}>
            <Trans i18nKey="trace-sidebar-seo-route-additional-points">
              Маршрут {{ from }} - {{ to }} проходит через следующие промежуточные точки:{" "}
              <IntermediatePoints />.
            </Trans>
          </span>
        )}
        <Trans i18nKey="trace-sidebar-seo-route-on-map">
          На&nbsp;интерактивной карте маршрут {{ from }} - {{ to }} показан линией.
        </Trans>
      </p>
      <p className={styles.description}>
        <Trans i18nKey="trace-sidebar-seo-text">
          Расстояние {{ from }} - {{ to }} составляет{" "}
          <span className={styles.nowrap}>
            <strong>{{ totalDistanceText }}&nbsp;км</strong>
          </span>{" "}
          по&nbsp;трассе. Ориентировочное время преодоления расстояния на&nbsp;машине составляет{" "}
          <span className={styles.nowrap}>
            <strong>{{ durationText }}</strong>
          </span>
          . Средний расход топлива составит{" "}
          <span className={styles.nowrap}>
            <strong>{{ fuelConsumptionText }}&nbsp;л</strong>
          </span>{" "}
          при&nbsp;затратах{" "}
          <span className={styles.nowrap}>
            <strong>{{ fuelCostsText }}&nbsp;₽</strong>
          </span>{" "}
          (Из&nbsp;расчёта:{" "}
          <span className={styles.nowrap}>
            {{ fuelConsumption }}&nbsp;л/100&nbsp;км, {{ fuelPrice }}&nbsp;₽/л)
          </span>
          . Плата по&nbsp;системе «Платон» составит{" "}
          <span className={styles.nowrap}>
            <strong>{{ platonCostsText }}&nbsp;₽</strong>
          </span>
          .
        </Trans>
      </p>
      
      <SeoHelmet
        seoTitle={seoTitle}
        seoSiteName={seoSiteName}
        seoDescription={seoDescription}
      />
    </section>
  );
};

SeoText.defaultProps = {
  className: "",
};

SeoText.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  through: PropTypes.arrayOf(PropTypes.string).isRequired,
  metrics: PropTypes.shape({
    totalDistance: PropTypes.number.isRequired,
    totalPlatonCosts: PropTypes.number.isRequired,
    fuelConsumption: PropTypes.number.isRequired,
    totalFuelConsumption: PropTypes.number.isRequired,
    fuelPrice: PropTypes.number.isRequired,
    totalFuelCosts: PropTypes.number.isRequired,
    roundedDays: PropTypes.number.isRequired,
    roundedHours: PropTypes.number.isRequired,
    roundedMinutes: PropTypes.number.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(SeoText);
