export default class ParsePointsUrlParametersResult {
  static fail = () => new ParsePointsUrlParametersResult({ success: false });

  static success = parsedPoints => new ParsePointsUrlParametersResult({ success: true, parsedPoints });

  constructor({ success, parsedPoints }) {
    this.success = success;
    this.parsedPoints = parsedPoints;
  }
}
