import {
  connect
} from "react-redux";

import AdvertisingBoundary from "./AdvertisingBoundary";

const mapStateToProps = state => ({
  showResults: state.showResults,
  showLightVersion: state.showLightVersion,
});

export default connect(mapStateToProps)(AdvertisingBoundary);
