import React from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";

import RouteRoadName from "../RouteRoadName";
import styles from "../RouteRow/RouteRow.scss";

import { getFormattedPrice } from "../../bl/routeRowService";

const RoutePlaton = ({platonFare, roadName, t }) => {
  const regionPrice = getFormattedPrice(Math.round(platonFare));

  return (
    <div className={styles.platon}>
      <div title={t("trace-route-region-platon-fee-tooltip", "Плата за участок")} className={styles.platonText}>
        <Trans i18nKey="trace-route-region-platon-fee">{{ regionPrice }} за Платон</Trans>
      </div>
      <RouteRoadName roadName={roadName} />
    </div>
  );
};

RoutePlaton.propTypes = {
  platonFare: PropTypes.number.isRequired,
  roadName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RoutePlaton);
