import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";

import { getLoadsUrl, getMainUrl } from "../../utils/urls";

import styles from "./FindButtonsPanel.scss";

const createLoadsLink = (from, to) => {
  const city = 2;
  const searchOptions = {
    exactFromGeos: true,
    exactToGeos: true,
    from: from.suggestion.text,
    fromGeo: `${city}_${from.suggestion.id}`,
    to: to.suggestion.text,
    toGeo: `${city}_${to.suggestion.id}`,
  };

  const jsonOptions = encodeURIComponent(JSON.stringify(searchOptions));
  return getLoadsUrl(`/#?filter=${jsonOptions}`);
};

const createTrucksLink = (from, to) => {
  const city = 2;
  const fromGeo = `${city}_${from.suggestion.id}`;
  const toGeo = `${city}_${to.suggestion.id}`;

  return getMainUrl(`/Tables/Default.aspx?EntityType=Truck&FromGeo=${fromGeo}&ToGeo=${toGeo}`);
};

const FindButtonsPanel = ({ from, to, className, t }) => (
  <div className={classnames(styles.findButtonsSection, className)}>
    <div className={styles.services}>
      <a className={styles.loads} target="_blank" rel="noopener noreferrer" href={createLoadsLink(from, to)}>
        <div className={styles.badge}>
          <div className={styles.icon} />
          <div className={styles.text}>{t("trace-related-services-loads-find", "Найти")}</div>
        </div>
        <div className={styles.link}>
          <div className={styles.link}>{t("trace-related-services-loads-passing-loads", "попутные грузы")}</div>
        </div>
      </a>
      <a className={styles.trucks} target="_blank" rel="noopener noreferrer" href={createTrucksLink(from, to)}>
        <div className={styles.badge}>
          <div className={styles.icon} />
          <div className={styles.text}>{t("trace-related-services-trucks-find", "Найти")}</div>
        </div>
        <div className={styles.link}>
          <div className={styles.link}>{t("trace-related-services-trucks-passing-trucks", "попутные машины")}</div>
        </div>
      </a>
    </div>
  </div>
);

FindButtonsPanel.defaultProps = {
  className: "",
};

FindButtonsPanel.propTypes = {
  className: PropTypes.string,
  from: PropTypes.shape({
    suggestion: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    value: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    suggestion: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    value: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(FindButtonsPanel);
