import ParsePointsResult from "./ParsePointsResult";

const POINT_PARAMETER_DEVIDER_SIGN = "|";
const POINT_PARAMETER_COUNT = 5;

export default class ParsedPoint {
  constructor({ success, type, cityId, lat, lon, address }) {
    this.success = success;
    this.type = type;
    this.cityId = cityId;
    this.geo_point = {
      lat,
      lon,
    };
    this.address = address;
  }

  static parse = parseStr => {
    const pointParams = parseStr.split(POINT_PARAMETER_DEVIDER_SIGN);
    if (pointParams.length !== POINT_PARAMETER_COUNT) {
      return ParsePointsResult.fail();
    }
    try {
      return ParsePointsResult.success(
        new ParsedPoint({
          type: parseInt(pointParams[0], 10),
          cityId: parseInt(pointParams[1], 10),
          lat: parseFloat(pointParams[2]),
          lon: parseFloat(pointParams[3]),
          address: pointParams[4],
        }),
      );
    } catch (e) {
      return ParsePointsResult.fail();
    }
  };
}
