import applyCaseMiddleware from "axios-case-converter";
import axios from "axios";
import language from "../utils/language";

import * as urls from "../utils/urls";

const createClient = () => axios.create({
  headers: {
    "Accept-Language": language.getLanguage(),
  },
});

const caseConverterClient = applyCaseMiddleware(createClient());
class Distancer {
  searchCityRoute = params => caseConverterClient({
    method: "POST",
    url: `${process.env.DISTANCER_API_URL}/city_route`,
    data: params,
  });
  
  getCityCoordinates = cityIds =>
    caseConverterClient({
      method: "POST",
      url: process.env.GEO_CITIES_COORDINATES_API_URL, 
      data: {
        ids: cityIds
      }
    });
}

class Geo {
  searchCities = query =>
    caseConverterClient({
      method: "POST",
      url: process.env.GEO_AUTOCOMPLETE_API_URL,

      data: {
        prefix: query,
        geo_types: 1,
        language: language.getLanguage() === "en-US" ? "eng" : "ru",
      },
    });

  getCities = ids =>
    caseConverterClient({
      url: urls.getMainUrl(`${process.env.GEO_CITIES_API_URL}?ids=${ids.join(",")}`),
    });

  getCountries = ids =>
    caseConverterClient({
      url: urls.getMainUrl(`${process.env.GEO_COUNTRIES_API_URL}?ids=${ids.join(",")}`),
    });
}

class Stats {
  getCommonStats = () =>
    axios({
      method: "GET",
      url: urls.getMainUrl(process.env.COUNTERS_API_URL),
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });
}

class Firms {
  setLanguage = lang =>
    axios({
      method: "PATCH",
      url: urls.getMainUrl(process.env.CHANGE_LOCALIZATION_API_URL),
      withCredentials: true,
      data: JSON.stringify(lang),
      headers: {
        "Content-Type": "application/json",
      },
    });
}

class SiteFeature {
  getSiteFeature = id => {
    const url = `${urls.getApiUrl(process.env.SITE_FEATURE_API_URL)}?id=${id}`;
    return axios.get(url, { withCredentials: true })
      .then(response => response && response.data && response.data[id]);
  };
}

class API {
  constructor() {
    this.geo = new Geo();
    this.distancer = new Distancer();
    this.stats = new Stats();
    this.firms = new Firms();
    this.siteFeature = new SiteFeature();
  }
}

const api = new API();

export default api;
