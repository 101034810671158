import React from "react";
import L from "leaflet";
import { CircleMarker } from "react-leaflet";
import PropTypes from "prop-types";
import MapCity from "../MapCity";

const MapCityWrapper = props => {
  const { point: routeSegmentCityPoint, showPopup } = props;

  const marker = React.createRef();
  const commonMarker = <MapCity point={routeSegmentCityPoint} showPopup={showPopup} markerRef={marker} />;
  const clickMarker = e => {
    e.originalEvent.preventDefault();
    marker.current.leafletElement.openPopup();
  };

  const lat = routeSegmentCityPoint.geoPoint[0];
  const lon = routeSegmentCityPoint.geoPoint[1];
  const phoneMarker = (
    <CircleMarker
      center={L.latLng(lat, lon)}
      stroke={false}
      fillOpacity={0}
      radius={14}
      onClick={clickMarker}
      pane="tooltipPane"
    >
      {commonMarker}
    </CircleMarker>
  );

  const isCommonPoint = routeSegmentCityPoint.isCommonPoint();
  const isPhoneMarker = L.Browser.mobile && routeSegmentCityPoint.city.size < 2 && isCommonPoint;
  return isPhoneMarker ? phoneMarker : commonMarker;
};

MapCity.defaultProps = {
  cityNumber: 0,
  showPopup: true,
};

MapCity.propTypes = {
  showPopup: PropTypes.bool
};

export default MapCityWrapper;
