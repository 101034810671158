import queryString from "query-string";

const asyncParams = ["ExcludeCountries", "ExcludeCities", "City1", "City5", "Cities"];

let initialQueryString = window.location.search;

export const setInitialQueryString = query => {
  initialQueryString = query;
};

export const isInited = () => {
  const qs = queryString.parse(initialQueryString);
  let isAppInited = true;

  asyncParams.forEach(param => {
    if (qs[param]) {
      isAppInited = false;
    }
  });

  return isAppInited;
};
