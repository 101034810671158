import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces, Trans } from "react-i18next";

import Link from "ati-ui-react/components/Link";

import PopupLink from "../PopupLink";

import Banner from "../Banner";

import styles from "./GeneralInfo.scss";

import { toThousandSeparatedNumber } from "../../utils";
import { getMainUrl, getLoadsUrl, getIdUrl, getTrucksUrl } from "../../utils/urls";
import SeoHelmet from "../SeoHelmet/SeoHelmet";

const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z" id="arrowPath" />
    <path fill="none" d="M0,0h24v24H0V0z" />
  </svg>
);

class GeneralInfo extends React.Component {
  componentDidMount() {
    this.props.getCommonStats();
  }

  render() {
    const {
      isWelcomeScreen,
      commonStats: { loads, trucks, members },
      t,
    } = this.props;

    if (!isWelcomeScreen) return null;

    const seoTitle = t(
      "trace-meta-title-default",
      "Расстояние между городами и пунктами на автомобиле в км на карте России, СНГ и Европы",
    );

    const seoDescription = t(
      "trace-meta-description-default",
      "Сервис расчета расстояний автомобильных дорог в км на карте России, СНГ и Европы. Рассчитайте расстояние между городами с промежуточной точкой по самому короткому пути.",
    );

    const seoSiteName = t(
      "trace-meta-og-site-name",
      "TRACE.ATI.SU – Расчет расстояний автомобильных дорог",
    );

    return (
      <div className={classnames(styles.general, styles.sticky)}>
        <SeoHelmet
          seoTitle={seoTitle}
          seoSiteName={seoSiteName}
          seoDescription={seoDescription}
        />
        <div className={styles.arrow} />

        <h1 className={styles.title}>
          <Trans i18nKey="trace-general-title">Расчёт расстояний на&nbsp;АТИ</Trans>
        </h1>

        <div className={styles.description}>
          <Trans i18nKey="trace-general-full-description">
            Бытовые навигаторы прекрасно строят маршрут, но&nbsp;заточены
            <br />
            под легковушки. Чтобы спланировать рейс на&nbsp;грузовике, используйте Расчёт&nbsp;расстояний&nbsp;ATI.SU.
            В нём учитывается скорость движения грузового транспорта,
            <br />
            стоимость топлива, Платона и&nbsp;платных дорог.
          </Trans>
        </div>

        <div className={styles.descriptionShort}>
          <Trans i18nKey="trace-general-short-description">
            Учитывает скорость движения грузового транспорта, стоимость топлива, Платона и&nbsp;платных дорог.
          </Trans>
        </div>

        <div className={styles.ati}>
          <Trans i18nKey="trace-general-who-is-ati">АТИ — крупнейший сайт РФ по&nbsp;автоперевозкам</Trans>
        </div>

        <div className={styles.now}>
          <Trans i18nKey="trace-general-counters-title">Сейчас на АТИ</Trans>
        </div>

        <div className={styles.blocks}>
          <div className={styles.block}>
            <div className={styles.infoContainer}>
              <div className={styles.iconContainer}>
                <div className={styles.cargoIcon} />
              </div>
              <div className={styles.numberLabelHolder}>
                <div className={styles.number}>{toThousandSeparatedNumber(loads)}</div>
                <div className={styles.label}>
                  <div className={styles.cargos}>
                    {t("trace-general-loads-label", { defaultValue: "грузов", count: loads })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.refsContainer}>
              <Link
                small
                type="underline"
                className={styles.actionLink}
                href={getLoadsUrl("/")}
                target="_blank"
                newTabLink
              >
                <span className={styles.linkLabel}>{t("trace-general-loads-go-to-loads", "Посмотреть")}</span>
                <ArrowIcon className={styles.linkArrow} />
              </Link>
              <div className={styles.secondLink}>
                <PopupLink
                  href={getLoadsUrl(
                    "/EditPages/EditLoad.aspx?ID=00000000-0000-0000-0000-000000000000&Action=Add&WindowMode=Popup",
                  )}
                  small
                  type="underline"
                  className={styles.actionLink}
                >
                  <span>{t("trace-general-loads-add-new-load", "Добавить")}</span>
                </PopupLink>
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.infoContainer}>
              <div className={styles.iconContainer}>
                <div className={styles.carsIcon} />
              </div>
              <div className={styles.numberLabelHolder}>
                <div className={styles.number}>{toThousandSeparatedNumber(trucks)}</div>
                <div className={styles.label}>
                  {t("trace-general-trucks-label", { defaultValue: "машин", count: trucks })}
                </div>
              </div>
            </div>
            <div className={styles.refsContainer}>
              <Link
                small
                className={styles.actionLink}
                type="underline"
                href={getTrucksUrl()}
                target="_blank"
                newTabLink
              >
                <span className={styles.linkLabel}>{t("trace-general-trucks-go-to-trucks", "Посмотреть")}</span>
                <ArrowIcon className={styles.linkArrow} />
              </Link>
              <div className={styles.secondLink}>
                <PopupLink
                  href={getMainUrl(
                    "/EditPages/EditTruck.aspx?ID=00000000-0000-0000-0000-000000000000&Action=Add&WindowMode=Popup",
                  )}
                  small
                  type="underline"
                  className={styles.actionLink}
                >
                  <span>{t("trace-general-trucks-add-new-truck", "Добавить")}</span>
                </PopupLink>
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.infoContainer}>
              <div className={styles.iconContainer}>
                <div className={styles.membersIcon} />
              </div>
              <div className={styles.numberLabelHolder}>
                <div className={styles.number}>{toThousandSeparatedNumber(members)}</div>
                <div className={styles.label}>
                  <div className={styles.members}>
                    {t("trace-general-members-label", { defaultValue: "участников", count: members })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.refsContainer}>
              <Link href={getIdUrl("/register")} small type="underline" className={styles.actionLinkOne}>
                <span className={styles.linkLabel}>
                  {t("trace-general-members-go-to-register-page", "Быстрая регистрация")}
                </span>
                <ArrowIcon className={styles.linkArrow} />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.docPosition785_1200}>
          <Banner bannerSizeId={20} minScreenWidth={768} maxScreenWidth={1183} docCloseType="2" />
        </div>

        <div id="docNoTrace1201_1404" className={styles.docNoTracePosition}>
          <Banner bannerSizeId={13} minScreenWidth={1184} maxScreenWidth={1387} docCloseType="2" />
        </div>

        <div id="docNoTrace1405_1500" className={styles.docNoTracePosition}>
          <Banner docPreset="allpages_940_200" minScreenWidth={1388} maxScreenWidth={1483} docCloseType="2" />
        </div>

      </div>
    );
  }
}

GeneralInfo.propTypes = {
  isWelcomeScreen: PropTypes.bool.isRequired,
  commonStats: PropTypes.shape({
    loads: PropTypes.number.isRequired,
    trucks: PropTypes.number.isRequired,
    members: PropTypes.number.isRequired,
  }).isRequired,
  getCommonStats: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(GeneralInfo);
