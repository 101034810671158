export const FETCH_SUGGESTIONS_STARTED = "FETCH_SUGGESTIONS_STARTED";
export const FETCH_SUGGESTIONS_SUCCEEDED = "FETCH_SUGGESTIONS_SUCCEEDED";
export const FETCH_SUGGESTIONS_FAILED = "FETCH_SUGGESTIONS_FAILED";

export const CLEAR_SUGGESTIONS = "CLEAR_SUGGESTIONS";

export const SEARCH_ROUTE_STARTED = "SEARCH_ROUTE_STARTED";
export const SEARCH_ROUTE_SUCCEEDED = "SEARCH_ROUTE_SUCCEEDED";
export const SEARCH_ROUTE_FAILED = "SEARCH_ROUTE_FAILED";

export const SET_ROUTE_SEARCH_PARAMS = "SET_ROUTE_SEARCH_PARAMS";

export const TOGGLE_PREFERENCES_POPUP = "TOGGLE_PREFERENCES_POPUP";

export const FETCH_COMMON_STATS = "FETCH_COMMON_STATS";
export const FETCH_COMMON_STATS_SUCCEEDED = "FETCH_COMMON_STATS_SUCCEEDED";
export const FETCH_COMMON_STATS_STARTED = "FETCH_COMMON_STATS_STARTED";
export const FETCH_COMMON_STATS_FAILED = "FETCH_COMMON_STATS_FAILED";

export const CHANGE_ROUTE = "CHANGE_ROUTE";
export const ROUTE_NOT_FOUND = "ROUTE_NOT_FOUND";
export const SHOW_RESULTS = "SHOW_RESULTS";

export const TOGGLE_ROUTE_GROUP = "TOGGLE_ROUTE_GROUP";
export const TOGGLE_SHOW_ONLY_LARGE_CITIES = "TOGGLE_SHOW_ONLY_LARGE_CITIES";

export const UPDATE_EXCLUDED_CITIES = "UPDATE_EXCLUDED_CITIES";
export const ADD_EXCLUDED_CITY = "ADD_EXCLUDED_CITY";
export const REMOVE_EXCLUDED_CITY = "REMOVE_EXCLUDED_CITY";

export const UPDATE_EXCLUDED_COUNTRIES = "UPDATE_EXCLUDED_COUNTRIES";
export const ADD_EXCLUDED_COUNTRY = "ADD_EXCLUDED_COUNTRY";
export const REMOVE_EXCLUDED_COUNTRY = "REMOVE_EXCLUDED_COUNTRY";

export const UPDATE_ROUTE_DIRECTION = "UPDATE_ROUTE_DIRECTION";

export const RESET_ALL_EXCLUDED = "RESET_ALL_EXCLUDED";

export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export const RESET_PREFERENCES = "RESET_PREFERENCES";

/* query string sync */
export const SET_RESTRICTION_INITIAL_VALUE = "SET_RESTRICTION_INITIAL_VALUE";
export const SET_DIRECTION_INITIAL_VALUE = "SET_DIRECTION_INITIAL_VALUE";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const SET_SHOW_LIGHT_VERSION = "SET_SHOW_LIGHT_VERSION";

export const HIDE_ERROR_NOTIFICATION = "HIDE_ERROR_NOTIFICATION";

export const TOGGLE_WITHIN_COUNTRY = "TOGGLE_WITHIN_COUNTRY";
export const SET_BEGIN_END_POINTS = "SET_BEGIN_END_POINTS";

export const SET_APP_INITIALIZED = "SET_APP_INITIALIZED";

export const INC_PENDING_REQUESTS = "INC_PENDING_REQUESTS";
export const DEC_PENDING_REQUESTS = "DEC_PENDING_REQUESTS";

