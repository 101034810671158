// eslint-disable
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withNamespaces, Trans } from "react-i18next";

import Link from "ati-ui-react/components/Link";
import QuestionIcon from "ati-ui-react/widgets/QuestionIcon";

import sideBarStyles from "../Sidebar/Sidebar.scss";

import { THIN_SPACE_CHAR } from "../../constants";
import { toThousandSeparatedNumber } from "../../utils";
import RouteExcluded from "../RouteExcluded";
import { getFormattedDate } from "../../bl/routeRowService";

import styles from "./RouteStatistics.scss";

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
    <path
      id="x"
      d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
    />
    <path id="y" d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const RouteStatistics = ({ metrics, onRouteChange, t, i18n }) => {
  const {estimatedTimeOfArrival, totalHours, minutes} = metrics;
  const date = getFormattedDate(
    estimatedTimeOfArrival,
    t("trace-route-statistics-duration-format", "D MMMM в HH:mm"),
    i18n.language,
  );

  const DurationTooltip = () => (
    <Trans i18nKey="trace-route-statistics-duration-tooltip">
      <span>
        Всего:{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          {{ totalHours }}
          {{ THIN_SPACE_CHAR }}ч{{ THIN_SPACE_CHAR }}
          {{ minutes }}
          {{ THIN_SPACE_CHAR }}мин.
        </span>
        <br />
        Прибытие&nbsp;с&nbsp;учётом рабочего&nbsp;дня&nbsp;водителя {{ date }}
      </span>
    </Trans>
  );

  const kmLabel = t("trace-common-units-kilometers-abbreviation", "км");
  const priceLabel = t("trace-common-units-price-abbreviation", "₽");
  const litersLabel = t("trace-common-units-liters-abbreviation", "л");

  return (
    <div className={classnames(styles.statisticsSection, sideBarStyles.hideOnMapTap)}>
      <RouteExcluded />
      <div className={styles.summary}>
        <div className={styles.row}>
          <div className={classnames(styles.column, styles.first)}>
            <div className={classnames(styles.block, styles.distance)}>
              <span className={styles.label}>
                <Trans i18nKey="trace-route-statistics-distance-label">Расстояние</Trans>
              </span>
              <div className={styles.container}>
                <div className={styles.metrics}>
                  <span className={classnames(styles.text, styles.value)}>
                    {toThousandSeparatedNumber(metrics.totalDistance)}
                  </span>
                  {THIN_SPACE_CHAR}
                  <span className={classnames(styles.text, styles.unit)}>{kmLabel}</span>
                </div>
              </div>
            </div>
            <div className={classnames(styles.block, styles.fuel)}>
              <span className={styles.label}>
                <Trans i18nKey="trace-route-statistics-fuel-label">Расход топлива</Trans>
              </span>
              <div className={styles.container}>
                <div className={styles.metrics}>
                  <span className={classnames(styles.text, styles.value)}>
                    {toThousandSeparatedNumber(metrics.totalFuelConsumption)}
                  </span>
                  {THIN_SPACE_CHAR}
                  <span className={styles.unit}>{litersLabel}</span>
                </div>
                <div className={styles.price}>
                  <span className={styles.value}>{toThousandSeparatedNumber(metrics.totalFuelCosts)}</span>
                  {THIN_SPACE_CHAR}
                  <span className={styles.unit}>{priceLabel}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={classnames(styles.block, styles.duration)}>
              <span className={styles.label}>
                <Trans i18nKey="trace-route-statistics-duration-label">Время</Trans>
              </span>
              <div className={styles.hint}>
                <QuestionIcon className={styles.question} tooltip={<DurationTooltip />} />
                {/* durationHintText */}
              </div>
              <div className={styles.container}>
                {metrics.roundedDays > 0 && (
                  <div className={styles.metrics}>
                    <span className={classnames(styles.text, styles.value)}>{metrics.roundedDays}</span>
                    {THIN_SPACE_CHAR}
                    <span className={classnames(styles.text, styles.unit)}>
                      {t("trace-common-units-days-abbreviation", "дн")}
                    </span>
                  </div>
                )}
                {metrics.roundedHours > 0 && (
                  <div className={styles.metrics}>
                    <span className={classnames(styles.text, styles.value)}>{metrics.roundedHours}</span>
                    {THIN_SPACE_CHAR}
                    <span className={classnames(styles.text, styles.unit)}>
                      {t("trace-common-units-hours-abbreviation", "ч")}
                    </span>
                  </div>
                )}
                {metrics.roundedDays === 0 && metrics.roundedMinutes > 0 && (
                  <div className={styles.metrics}>
                    <span className={classnames(styles.text, styles.value)}>{metrics.roundedMinutes}</span>
                    {THIN_SPACE_CHAR}
                    <span className={classnames(styles.text, styles.unit)}>
                      {t("trace-common-units-minutes-abbreviation", "м")}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className={classnames(styles.block, styles.platon)}>
              <span className={styles.label}>
                <Trans i18nKey="trace-route-statistics-platon-label">За Платон</Trans>
              </span>
              <div className={styles.container}>
                <div className={styles.metrics}>
                  <span className={classnames(styles.text, styles.value)}>
                    {toThousandSeparatedNumber(metrics.totalPlatonCosts)}
                  </span>
                  {THIN_SPACE_CHAR}
                  <span className={classnames(styles.text, styles.currency)}>{priceLabel}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.formula}>
        <span className={styles.label}>
          {t("trace-route-statistics-fuel-consumption-formula-label", "Из расчёта")}:
        </span>
        <span className={styles.link}>
          <Link // eslint-disable-line
            small
            gray
            type="pseudo"
            before={<EditIcon />}
            classNameContainer={styles.icon}
            onClick={onRouteChange}
          >
            {metrics.fuelConsumption}
            {THIN_SPACE_CHAR}
            {litersLabel}/100{THIN_SPACE_CHAR}
            {kmLabel}, {metrics.fuelPrice}
            {THIN_SPACE_CHAR}
            {priceLabel}/{litersLabel}
          </Link>
        </span>
      </div>
      <div className={styles.legend}>
        <div className={styles.title}>{t("trace-route-statistics-roads-title", "Дороги")}:</div>
        <div className={styles.item}>
          <span className={classnames(styles.figure, styles.distanceFigure)} />
          <span className={styles.text}>{t("trace-route-statistics-roads-category-free", "Бесплатные")}</span>
        </div>
        <div className={styles.item}>
          <span className={classnames(styles.figure, styles.tollRoadFigure)} />
          <span className={styles.text}>{t("trace-route-statistics-roads-category-toll", "Платные")}</span>
        </div>
        <div className={styles.item}>
          <span className={classnames(styles.figure, styles.platonFigure)} />
          <span className={styles.text}>{t("trace-route-statistics-roads-category-platon", "Платон")}</span>
        </div>
      </div>
    </div>
  );
};

RouteStatistics.propTypes = {
  metrics: PropTypes.shape({
    totalDistance: PropTypes.number.isRequired,
    totalPlatonCosts: PropTypes.number.isRequired,
    fuelPrice: PropTypes.number.isRequired,
    fuelConsumption: PropTypes.number.isRequired,
    totalFuelCosts: PropTypes.number.isRequired,
    totalFuelConsumption: PropTypes.number.isRequired,
    totalHours: PropTypes.number,
    minutes: PropTypes.number,
    roundedDays: PropTypes.number,
    roundedHours: PropTypes.number,
    roundedMinutes: PropTypes.number,
  }).isRequired,
  onRouteChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteStatistics);
