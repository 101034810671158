import { connect } from "react-redux";

import RouteStatistics from "./RouteStatistics";

import { changeRoute } from "../../store/actions";

const mapStateToProps = state => ({
  from: state.route.from.suggestion.name,
  to: state.route.to.suggestion.name,
  metrics: state.result.metrics
});

const mapDispatchToProps = dispatch => ({
  onRouteChange: () => dispatch(changeRoute()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteStatistics);
