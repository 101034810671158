// eslint-disable
import {connect} from "react-redux";

import MapCities from "./MapCities";
import {excludeCity} from "../../store/actions";
import {
  THRESHOLD_ZOOM_VALUE_TO_HIDE_MEDIUM_CITIES,
  THRESHOLD_ZOOM_VALUE_TO_HIDE_SMALL_CITIES
} from "../../constants";

const isVisibleMapPoint = (routeSegmentCityPoint, zoom) => {
  const isSpecialPoint = routeSegmentCityPoint.isSpecialPoint();

  const {size} = routeSegmentCityPoint.city;
  const hideWithCurrentZoom =
      ((size === 0 || size === 1) && zoom < THRESHOLD_ZOOM_VALUE_TO_HIDE_SMALL_CITIES) ||
      ((size === 2 || size === 3) && zoom < THRESHOLD_ZOOM_VALUE_TO_HIDE_MEDIUM_CITIES);

  return isSpecialPoint || !hideWithCurrentZoom;
};

const mapStateToProps = (state, ownProps) => {
  const visiblePoints = ownProps.points.filter(point => isVisibleMapPoint(point, ownProps.zoom));
  return {
    points: visiblePoints
  };
};

const mapDispatchToProps = dispatch => ({
  excludeCity: cityId => dispatch(excludeCity(cityId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapCities);
