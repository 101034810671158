import ReactDOM from "react-dom";

import PropTypes from "prop-types";

const Portal = ({ id, children }) => {
  const element = document.getElementById(id);
  return ReactDOM.createPortal(children, element);
};

Portal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Portal;
