import moment from "moment";

import { traceDefaultDelays, traceDefaultSpeeds, traceDefaultWorkDay, emptyArray } from "../constants";

export default {
  isInitialized: false,
  isWelcomeScreen: true,
  isRouteSearching: false,
  isRouteSearchFailed: false,
  isWithinCountryDisabled: false,
  showPreferencesPopup: false,
  showResults: false,
  showLightVersion: false,

  suggestions: [],

  // watched by ReduxQuerySyncEnhancer to keep query string in sync
  direction: emptyArray,

  route: {
    inCityPoints: [],
    from: {
      point_value: {},
      value: "",
      isStartPoint: true,
      isEndPoint: false,
      isIntermediatePoint: false,
      isLoadingPoint: false,
      isUnloadingPoint: false,
    },
    to: {
      point_value: {},
      value: "",
      isStartPoint: false,
      isEndPoint: true,
      isIntermediatePoint: false,
      isLoadingPoint: false,
      isUnloadingPoint: false,
    },
    temp: {
      point_value: {},
      value: "",
    },
    through: emptyArray,
  },

  restrictions: {
    excludeTollRoads: false,
    withinCountry: true,
    excludeMkad: false,
    allowFerries: true,
    allowWinterRoads: false,
    fastWay: true,
    showOnlyLargeCities: true,
    useBelarusRule: false,
  },

  calculations: {
    departureDate: moment(),
    departureHour: 9,
    departureMinute: 0,
    fuelConsumption: 28,
    fuelPrice: 59,
    workingDay: 8,
    dailyMileage: 500,
    ...traceDefaultWorkDay,
  },

  speeds: traceDefaultSpeeds,
  delays: traceDefaultDelays,

  excludedCities: emptyArray,
  excludedCountries: emptyArray,

  commonStats: {
    trucks: 0,
    loads: 0,
    members: 0,
  },

  routeNotFound: false,

  result: {
    routeSegments: [], // RouteSegment[]
    metrics: {}
  },
  
  sidebarToggled: false,

  pendingRequests: 0,
};
