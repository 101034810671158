import { reactI18nextModule } from "react-i18next";
import { i18nInstance, i18nextOptions } from "./i18n.config";
import language from "../utils/language";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  lng: language.getLanguage(),
  fallbackLng: "ru-RU",
  load: "currentOnly",
  saveMissing: !isProduction,
  updateMissing: false, // !isProduction, //
  appendNamespaceToCIMode: true,
  ns: ["trace"],
  defaultNS: "trace",

  debug: !isProduction,

  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },

  react: {
    bindStore: false,
    bindI18n: false,
    wait: true,
  },
};

i18nInstance.use(reactI18nextModule).init({
  ...options,
  ...i18nextOptions,
});

export default i18nInstance;
