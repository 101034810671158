import React from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import styles from "../RouteRow/RouteRow.scss";

const RouteRegionSeparator = ({
  countryId,
  countryName,
  canExcludeCountry,
  excludeCountry,
  countryHasChanged,
  separatorText,
  t,
}) => {
  const containerClass = countryHasChanged ? styles.countrySeparator : styles.regionSeparator;

  const country = {
    countryId,
    countryName,
  };

  return (
    <div className={containerClass}>
      <div className={styles.region}>{separatorText}</div>
      {countryHasChanged && canExcludeCountry && (
        <div
          className={styles.countryExcludeIcon}
          title={t("trace-route-region-exclude-tooltip", "Исключить из маршрута")}
          role="button"
          onClick={() => excludeCountry(country)}
        />
      )}
      <div className={styles.separator} />
    </div>
  );
};

RouteRegionSeparator.propTypes = {
  canExcludeCountry: PropTypes.bool.isRequired,
  countryHasChanged: PropTypes.bool.isRequired,
  separatorText: PropTypes.string.isRequired,
  countryId: PropTypes.number.isRequired,
  countryName: PropTypes.string.isRequired,
  excludeCountry: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteRegionSeparator);
