import { connect } from "react-redux";

import Route from "./Route";

import { excludeCity } from "../../store/actions";

const mapStateToProps = state => ({
  routeSegments: state.result.routeSegments,
  showOnlyLargeCities: state.restrictions.showOnlyLargeCities
});

const mapDispatchToProps = dispatch => ({
  excludeCity: cityId => dispatch(excludeCity(cityId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Route);
