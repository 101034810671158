import * as types from "./action-types";
import * as utils from "../utils";
import initialState from "./state";
import { emptyArray } from "../constants";

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SUGGESTIONS_SUCCEEDED:
      return {
        ...state,
        isRouteSearching: false,
        suggestions: action.suggestions.map(s => utils.mapSuggestion(s)),
      };
    case types.CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
      };
    case types.TOGGLE_PREFERENCES_POPUP:
      return {
        ...state,
        showPreferencesPopup: action.isOpen,
      };
    case types.CHANGE_ROUTE:
    case types.SHOW_RESULTS:
      return {
        ...state,
        showResults: action.show,
      };
    case types.ROUTE_NOT_FOUND:
      return {
        ...state,
        isRouteSearching: false,
        routeNotFound: action.routeNotFound,
      };
    case types.SET_BEGIN_END_POINTS:
      return {
        ...state,
        isWelcomeScreen: false,
        result: {
          ...state.result,
          routeSegments: action.routeSegments,
        },
      };
    case types.SET_ROUTE_SEARCH_PARAMS:
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          ...action.params.restrictions,
        },
        calculations: {
          ...state.calculations,
          ...action.params.calculations,
        },
      };
    case types.SEARCH_ROUTE_STARTED:
      return {
        ...state,
        isRouteSearching: true,
        isRouteSearchFailed: false,
      };
    case types.SEARCH_ROUTE_SUCCEEDED:
      return {
        ...state,
        isWelcomeScreen: false,
        isRouteSearching: false,
        routeNotFound: initialState.routeNotFound,
        result: {
          ...state.result,
          ...action.result,
        },
      };
    case types.SEARCH_ROUTE_FAILED:
      return {
        ...state,
        isRouteSearching: false,
        isRouteSearchFailed: true,
      };
    case types.TOGGLE_SHOW_ONLY_LARGE_CITIES:
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          showOnlyLargeCities: action.showOnlyLargeCities,
        },
      };
    case types.FETCH_COMMON_STATS_SUCCEEDED:
      return {
        ...state,
        commonStats: {
          loads: action.stats.active_loads,
          trucks: action.stats.active_trucks,
          members: action.stats.active_firms,
        },
      };
    case types.ADD_EXCLUDED_CITY:
      return {
        ...state,
        excludedCities:
          state.excludedCities.map(city => city.cityId).indexOf(action.city.cityId) === -1
            ? [...state.excludedCities, action.city]
            : [...state.excludedCities],
      };
    case types.ADD_EXCLUDED_COUNTRY:
      return {
        ...state,
        excludedCountries:
          state.excludedCountries.map(country => country.countryId).indexOf(action.country.countryId) === -1
            ? [...state.excludedCountries, action.country]
            : [...state.excludedCountries],
      };
    case types.REMOVE_EXCLUDED_CITY:
      return {
        ...state,
        excludedCities:
          state.excludedCities.length === 1
            ? emptyArray
            : [...state.excludedCities.filter(city => city.cityId !== action.cityId)],
      };
    case types.REMOVE_EXCLUDED_COUNTRY:
      return {
        ...state,
        excludedCountries:
          state.excludedCountries.length === 1
            ? emptyArray
            : [...state.excludedCountries.filter(country => country.countryId !== action.countryId)],
      };
    case types.RESET_ALL_EXCLUDED:
      return {
        ...state,
        excludedCities: emptyArray,
        excludedCountries: emptyArray,
      };
    case types.UPDATE_ROUTE_DIRECTION:
      return {
        ...state,
        route: action.route,
        direction: action.direction,
      };
    case types.UPDATE_PREFERENCES:
    case types.RESET_PREFERENCES:
      return {
        ...state,
        speeds: action.preferences.speeds,
        delays: action.preferences.delays,
        calculations: {
          ...state.calculations,
          workDayStartHour: action.preferences.workDay.workDayStartHour,
          workDayStartMinute: action.preferences.workDay.workDayStartMinute,
        },
        showPreferencesPopup: false,
      };
    case types.SET_RESTRICTION_INITIAL_VALUE:
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.SET_DIRECTION_INITIAL_VALUE:
      return {
        ...state,
        route: action.route,
      };
    case types.UPDATE_EXCLUDED_CITIES:
      return {
        ...state,
        excludedCities: action.payload,
      };
    case types.UPDATE_EXCLUDED_COUNTRIES:
      return {
        ...state,
        excludedCountries: action.payload,
      };
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarToggled: !state.sidebarToggled,
      };
    case types.HIDE_ERROR_NOTIFICATION:
      return {
        ...state,
        isRouteSearchFailed: false,
      };
    case types.TOGGLE_WITHIN_COUNTRY:
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
        },
        isWithinCountryDisabled: action.disabled,
      };
    case types.SET_APP_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
      };
    case types.INC_PENDING_REQUESTS:
      return {
        ...state,
        pendingRequests: state.pendingRequests + 1,
      };
    case types.DEC_PENDING_REQUESTS:
      return {
        ...state,
        pendingRequests: state.pendingRequests - 1,
      };
    case types.SET_SHOW_LIGHT_VERSION:
      return {
        ...state,
        showLightVersion: action.showLightVersion,
      };
    default:
      return state;
  }
};
