import React from "react";
import { Helmet } from "react-helmet";

import PropTypes from "prop-types";

const SeoHelmet = ({
  seoDescription,
  seoTitle,
  seoSiteName, }) => (
    <Helmet>
      <title>
        {seoTitle}
      </title>
      <meta
        name="description"
        content={seoDescription}
      />
      <meta property="og:title" content={seoTitle} />
      <meta property='og:type' content="website" />
      <meta property="og:url" content={window.location} />
      <meta property="og:image" content="https://files.ati.su/images/trace/ati_logo-trace-400x300-gorizont.png" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:site_name" content={seoSiteName} />
      <meta property="og:description" content={seoDescription} />
    </Helmet>
);

SeoHelmet.propTypes = {
  seoTitle: PropTypes.string.isRequired,
  seoSiteName: PropTypes.string.isRequired,
  seoDescription: PropTypes.string.isRequired,
};

export default SeoHelmet;
