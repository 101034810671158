import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "../RouteRow/RouteRow.scss";

const RouteRoadName = ({ roadName }) => {
  if (!roadName || roadName === "") {
    return null;
  }

  const roadType = roadName.charAt(0);
  let roadClass = "";
  switch (roadType) {
    case "А":
      roadClass = classnames(styles.roadA, styles.roadName);
      break;
    case "Р":
      roadClass = classnames(styles.roadR, styles.roadName);
      break;
    case "М":
    default:
      roadClass = classnames(styles.roadM, styles.roadName);
      break;
  }

  return <div className={roadClass}>{roadName}</div>;
};

RouteRoadName.propTypes = {
  roadName: PropTypes.string.isRequired,
};

export default RouteRoadName;
