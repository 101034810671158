import React from "react";
import Helmet from "react-helmet";

function CanonicalLink() {
  const { href, origin } = window.location;
  const isRoot = href.replace(origin, "") === "/";
  if (isRoot) {
    return null;
  }
  return (
    <Helmet>
      <link rel="canonical" href="https://trace.ati.su" />
    </Helmet>
  );
}

export default CanonicalLink;
