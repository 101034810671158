import React from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";

import styles from "../RouteRow/RouteRow.scss";

const RouteFromPointInfo = ({ distanceToNext, timeToNext, t }) => (
  <div className={styles.toNextInfo}>
    <div className={styles.leftCol}>
      <span title={t("trace-route-point-distance-to-next-point-tooltip", "Расстояние участка")}>+{distanceToNext}</span>
    </div>
    <div className={styles.rightCol}>
      <span title={t("trace-route-point-duration-to-next-point-tooltip", "Время проезда участка")}>+{timeToNext}</span>
    </div>
  </div>
);

RouteFromPointInfo.propTypes = {
  distanceToNext: PropTypes.string.isRequired,
  timeToNext: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(RouteFromPointInfo);
