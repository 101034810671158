import React from "react";
import PropTypes from "prop-types";

import MapCityWrapper from "../MapCityWrapper";

export default class MapCities extends React.Component {
  constructor(props) {
    super(props);
    window.onExcludeCity = (city, event) => {
      if (event) {
        event.stopPropagation();
      }
      // eslint-disable-next-line react/destructuring-assignment
      this.props.excludeCity(city);
    };
  }

  render() {
    const { routeNotFound, points } = this.props;
    return (
      <React.Fragment>
        {points.map( (point, i) => {
          const keySuffix = `${i}`;
          return (
            <MapCityWrapper 
              point={point} 
              key={`${point.geoPoint[0]}_${point.geoPoint[1]}_${keySuffix}`}
              showPopup={!routeNotFound} 
            />
          );
        })}
      </React.Fragment>
    );
  }
}

MapCities.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  excludeCity: PropTypes.func.isRequired,
  routeNotFound: PropTypes.bool.isRequired,
};
