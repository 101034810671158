import { connect } from "react-redux";

import RouteExcluded from "./RouteExcluded";
import { returnCityToRoute, returnCountryToRoute, resetExcludedAndCalculateRoute } from "../../store/actions";

const mapStateToProps = state => ({
  excludedCities: state.excludedCities,
  excludedCountries: state.excludedCountries,
});

const mapDispatchToProps = dispatch => ({
  returnCityToRoute: cityId => dispatch(returnCityToRoute(cityId)),
  returnCountryToRoute: countryId => dispatch(returnCountryToRoute(countryId)),
  resetExcludedAndCalculateRoute: () => dispatch(resetExcludedAndCalculateRoute()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RouteExcluded);
