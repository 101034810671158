import React from "react";

import Banner from "../Banner";

import styles from "./FooterMobileBanner.scss";

const FooterMobileBanner = () => (
  <div className={styles.docPositionContainer0_560}>
    <Banner bannerSizeId={28} maxScreenWidth={542} />
  </div>
);

export default FooterMobileBanner;
