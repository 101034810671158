import React from "react";
import Helmet from "react-helmet";

function LdJson() {
  const json = {
    "@context": "https://schema.org/",
    "@type": "GeneralContractor",
    "name": "Расчет расстояний автомобильных дорог на карте между городами и  населенными пунктами России, СНГ, Европы | ATI.SU",
    "brand": "ATI.SU",
    "url": "https://trace.ati.su/",
    "logo": "https://files.ati.su/Images/ati-logo-mobile-en.svg",
    "image": "https://files.ati.su/assets/shared/img/big_logo_new.svg",
    "description": "Сервис расчета расстояний и определения самого оптимального маршрута между городами России, СНГ, Европы. Населенные пункты, границы и др.",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4,8",
      "reviewCount": "2477",
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(json)}
      </script>
    </Helmet>
  );
}

export default LdJson;
