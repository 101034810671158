import moment from "moment";
import {emptyArray} from "../constants";

export const isEqual = (value, other) => {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen = type === "[object Array]" ? value.length : Object.keys(value).length;
  const otherLen = type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = (item1, item2) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    } else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else if (item1 !== item2) return false;
    }

    return true;
  };
  // Compare properties
  if (type === "[object Array]") {
    for (let i = 0; i < valueLen; i += 1) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    const keys = Object.keys(value);
    const anyNotEq = keys.some(key => value.hasOwnProperty(key) && compare(value[key], other[key]) === false);

    if (anyNotEq) return false;
  }
  // If nothing failed, return true
  return true;
};

export const createSearchRouteRequest = ({
  allPoints,
  cities,
  calculations,
  restrictions,
  speeds,
  delays,
  excludedCities,
  excludedCountries,
}) => {
  const years = calculations.departureDate.year();
  const months = calculations.departureDate.month();
  const date = calculations.departureDate.date();

  const departureDateUtc = moment({
    years,
    months,
    date,
    hours: calculations.departureHour,
    minutes: calculations.departureMinute,
  })
    .utc()
    .format();

  return {
    cities,
    delays: {
      0: 0,
      1: delays.size1,
      2: delays.size2,
      3: delays.size3,
      4: delays.size4,
      5: delays.size5,
      6: delays.size6,
      7: delays.customs,
      8: delays.loadingPoint,
    },

    speeds: {
      0: speeds.noInfo,
      1: speeds.other,
      2: speeds.main,
      3: speeds.main,
      4: speeds.trunk,
      5: speeds.motorway,
      6: speeds.winterRoad,
      10: speeds.ferry,
    },

    options: {
      shortestRoute: !restrictions.fastWay,
      allowWinterRoads: restrictions.allowWinterRoads,
      allowFerries: restrictions.allowFerries,
      withinCountry: restrictions.withinCountry,
      allowTollRoads: !restrictions.excludeTollRoads,
      excludeMkad: restrictions.excludeMkad,
      useBelarusRule: restrictions.useBelarusRule
    },

    excludedCities: excludedCities.map(city => city.cityId),
    excludedRegions: [],
    excludedCountries: excludedCountries.map(country => country.countryId),

    loadingPoints: allPoints.filter(point => point.isLoadingPoint).map(point => point.suggestion.id),
    unloadingPoints: allPoints.filter(point => point.isUnloadingPoint).map(point => point.suggestion.id),
    departureDateUtc,
    workDay: {
      workDayStartTime: moment({
        hours: calculations.workDayStartHour,
        minutes: calculations.workDayStartMinute,
      }).format("HH:mm:ss"),
      workingHours: calculations.workingDay,
      dailyMileage: calculations.dailyMileage,
    },
  };
};

export const getRouteAllPoints = route => [route.from, ...route.through, route.to];

export const excludeIntermediateCityFromRoute = (route, cityId) => {
  const through = route.through.filter(point => point.suggestion.id !== cityId);
  return {
    ...route,
    through: through.length === 0 ? emptyArray : through,
  };
};

export const checkWithinCountryShouldBeDisabled = suggestions => {
  const countryIds = suggestions.filter(Boolean).map(city => city.countryId);
  const unique = countryIds.filter((item, index, all) => all.indexOf(item) === index);

  return unique.length > 1;
};

export const mapRouteDirectionToFlatList = route => {
  const direction = [route.from, ...route.through, route.to].filter(point => point.suggestion);
  return direction.length > 0 ? direction : emptyArray;
};

export const normalizeRoute = route => {
  const { from, to, through } = route;

  if (through.length === 0) {
    return;
  }

  const sameAsFrom = point => point.suggestion.id === from.suggestion.id;
  const sameAsTo = point => point.suggestion.id === to.suggestion.id;

  while (sameAsFrom(through[0])) {
    through.shift();
  }

  while (sameAsTo(through[through.length - 1])) {
    through.pop();
  }
};

/**
 * Отображать только нас. пункты размера 3 и выше
 */
export const groupRouteSegmentsBySize = routeSegments => {
  const result = [];
  // eslint-disable-next-line no-undef-init
  let currentSegment = undefined;
  for(let i = 0; i < routeSegments.length; i += 1){
    const routeSegment = routeSegments[i];
    const {startPoint} = routeSegment;
    if(startPoint.isSpecialPoint() || startPoint.city.size >= 3) {
      if(currentSegment !== undefined) {
        const changedCountry = currentSegment.startPoint.country.id !== routeSegment.startPoint.country.id ? routeSegment.startPoint.country : undefined;
        const changedRegion = currentSegment.startPoint.region.id !== routeSegment.startPoint.region.id ? routeSegment.startPoint.region : undefined;
        currentSegment.setChangedCountry(changedCountry);
        currentSegment.setChangedRegion(changedRegion);
        
        result.push(currentSegment);
      }
      currentSegment = routeSegment.clone();
    }
    else {
      currentSegment.appendSegment(routeSegment);
    }
  }
  if(currentSegment){
    result.push(currentSegment);
  }
  
  return result;
};
