import moment from "moment";
import * as types from "./action-types";
import api from "../api";
import * as initService from "../bl/initService";
import * as routeService from "../bl/routeService";
import * as preferencesService from "../bl/preferencesService";
import * as advertisingService from "../bl/advertisingService";
import * as directionUtils from "../bl/directionUtils";
import SearchRouteParameters from "../models/SearchRouteParameters";
import {searchRouteByRoadsInCities} from "../bl/searchRouteByRoadsInCities";
import {RouteCity, RouteSegment, RouteSegmentCityPoint, RouteSegmentCityPointVisit} from "../bl/RouteSegment";
import {MAP_POINT_TYPES} from "../constants";

export const increasePendingRequest = () => ({
  type: types.INC_PENDING_REQUESTS,
});

export const decreasePendingRequest = () => ({
  type: types.DEC_PENDING_REQUESTS,
});

export const fetchSuggestionsStarted = () => ({
  type: types.FETCH_SUGGESTIONS_STARTED,
});

export const fetchSuggestionsSucceeded = suggestions => ({
  type: types.FETCH_SUGGESTIONS_SUCCEEDED,
  suggestions,
});

export const fetchSuggestionsFailed = () => ({
  type: types.FETCH_SUGGESTIONS_FAILED,
});

export const clearSuggestions = () => ({
  type: types.CLEAR_SUGGESTIONS,
});

export const fetchSuggestions = query => dispatch => {
  dispatch(fetchSuggestionsStarted());

  api.geo
    .searchCities(query)
    .then(response => dispatch(fetchSuggestionsSucceeded(response.data)))
    .catch(() => dispatch(fetchSuggestionsFailed()));
};

export const showResults = show => ({
  type: types.SHOW_RESULTS,
  show,
});

export const routeNotFoundFlag = () => ({
  type: types.ROUTE_NOT_FOUND,
  routeNotFound: true,
});

export const updateRouteDirection = route => ({
  type: types.UPDATE_ROUTE_DIRECTION,
  route,
  direction: routeService.mapRouteDirectionToFlatList(route),
});

export const changeRouteParams = () => ({
  type: types.CHANGE_ROUTE,
  show: false,
});

const setRouteParams = params => ({
  type: types.SET_ROUTE_SEARCH_PARAMS,
  params,
});

export const setAppInitialized = () => {
  // required for prerender service
  window.prerenderReady = true;

  return {
    type: types.SET_APP_INITIALIZED,
  };
};

export const searchRouteStarted = () => ({
  type: types.SEARCH_ROUTE_STARTED,
});

export const searchRouteSucceeded = result => ({
  type: types.SEARCH_ROUTE_SUCCEEDED,
  result,
});

export const searchRouteFailed = () => ({
  type: types.SEARCH_ROUTE_FAILED,
});

export const toggleShowOnlyLargeCities = checked => {
  return {
    type: types.TOGGLE_SHOW_ONLY_LARGE_CITIES,
    showOnlyLargeCities: checked,
  };
};

export const checkIfAppIsInitialized = () => dispatch => {
  if (initService.isInited()) {
    dispatch(setAppInitialized());
  }
};

// когда маршурут не найден, необходимо отобразить начальную и конечную точки маршрута на карте с поповерами
// этот метод получает координаты этих точек и обновляет стейт
const getBeginEndPoints = route => (dispatch) => {
  const { from, to } = route;

  if (typeof route === "undefined" || typeof from === "undefined" || typeof to === "undefined") {
    return;
  }
  
  const cityIds = [from.suggestion.id, to.suggestion.id];
  const cities = {};
  cities[from.suggestion.id] = from;
  cities[to.suggestion.id] = to;
  
  api.distancer.getCityCoordinates(cityIds)
    .then(response => {
      const routeSegments = response.data.cities
        .map((cityResponse, index) => {
          const city = new RouteCity(cityResponse.cityId, cities[cityResponse.cityId].suggestion.name, cityResponse.size, cityResponse.legacyAttributes || 0);
          
          const geoPoint = [cityResponse.geoPoint.lat, cityResponse.geoPoint.lon];
          const visit = new RouteSegmentCityPointVisit(moment(0), 0, 0);
          const isLastSegment = index === response.data.cities.length - 1;
          visit.addType(isLastSegment ? MAP_POINT_TYPES.END_POINT : MAP_POINT_TYPES.START_POINT);
          const startPoint = new RouteSegmentCityPoint(city, undefined, undefined, true, [visit], geoPoint, undefined);

          return new RouteSegment(startPoint, [], "",
              0, 0, 0, [], moment(0), moment(0), 0, undefined, undefined, false, isLastSegment, false);
        });

      dispatch({
        type: types.SET_BEGIN_END_POINTS,
        routeSegments,
      });
    })
    .catch(reason => {
      console.error(reason);
      dispatch(searchRouteFailed());
    });
};

const routeNotFound = route => dispatch => {
  dispatch(routeNotFoundFlag());
  dispatch(getBeginEndPoints(route));
};

export const calculateRoute = () => (dispatch, getState) => {
  dispatch(searchRouteStarted());

  const state = getState();
  const {
    route,
    calculations,
    restrictions,
    excludedCities,
    excludedCountries,
    speeds,
    delays,
  } = state;

  const searchRouteParameters = new SearchRouteParameters({
    route,
    calculations,
    restrictions,
    excludedCities,
    excludedCountries,
    speeds,
    delays,
  });

  const routeResult = searchRouteByRoadsInCities(searchRouteParameters);

  routeResult
    .then(result => {
      if (result.success) {
        dispatch(searchRouteSucceeded(result.route));
        dispatch(showResults(true));

        advertisingService.changeBannersVisibility();
      } else {
        dispatch(showResults(false));
        dispatch(routeNotFound(route));
      }
      dispatch(setAppInitialized());
    })
    .catch(reason => {
      console.error(reason);
      dispatch(searchRouteFailed());
      dispatch(setAppInitialized());
    });
};

export const searchRoute = request => dispatch => {
  const { route, restrictions, calculations } = request;

  routeService.normalizeRoute(route);

  dispatch(updateRouteDirection(route));
  dispatch(
    setRouteParams({
      restrictions,
      calculations,
    }),
  );
  dispatch(calculateRoute());
};

export const getCommonStatsSucceeded = stats => ({
  type: types.FETCH_COMMON_STATS_SUCCEEDED,
  stats,
});

export const getCommonStatsFailed = stats => ({
  type: types.FETCH_COMMON_STATS_FAILED,
  stats,
});

export const getCommonStats = () => dispatch => {
  api.stats
    .getCommonStats()
    .then(response => dispatch(getCommonStatsSucceeded(response.data)))
    .catch(() => dispatch(getCommonStatsFailed()));
};

const updateExcludedCities = cities => ({
  type: types.UPDATE_EXCLUDED_CITIES,
  payload: cities,
});

const updateExcludedCountries = countries => ({
  type: types.UPDATE_EXCLUDED_COUNTRIES,
  payload: countries,
});

export const addExcludedCity = city => ({
  type: types.ADD_EXCLUDED_CITY,
  city,
});

export const addExcludedCountry = country => ({
  type: types.ADD_EXCLUDED_COUNTRY,
  country,
});

export const removeCityFromExcluded = cityId => ({
  type: types.REMOVE_EXCLUDED_CITY,
  cityId,
});

export const removeCountryFromExcluded = countryId => ({
  type: types.REMOVE_EXCLUDED_COUNTRY,
  countryId,
});

export const resetAllExcluded = () => ({
  type: types.RESET_ALL_EXCLUDED,
});

export const excludeCity = city => (dispatch, getState) => {
  const { cityId } = city;
  const { route } = getState();
  const newRoute = routeService.excludeIntermediateCityFromRoute(route, cityId);
  dispatch(updateRouteDirection(newRoute));
  dispatch(addExcludedCity(city));
  dispatch(calculateRoute());
};

export const excludeCountry = country => dispatch => {
  dispatch(addExcludedCountry(country));
  dispatch(calculateRoute());
};

export const returnCityToRoute = cityId => dispatch => {
  dispatch(removeCityFromExcluded(cityId));
  dispatch(calculateRoute());
};

export const returnCountryToRoute = countryId => dispatch => {
  dispatch(removeCountryFromExcluded(countryId));
  dispatch(calculateRoute());
};

export const resetExcludedAndCalculateRoute = () => dispatch => {
  dispatch(resetAllExcluded());
  dispatch(calculateRoute());
};

export const changeRoute = () => dispatch => {
  dispatch(changeRouteParams());
  dispatch(resetAllExcluded());
};

export const updatePreferences = preferences => ({
  type: types.UPDATE_PREFERENCES,
  preferences,
});

export const resetPreferences = () => {
  const preferences = preferencesService.resetPreferences();
  return {
    type: types.UPDATE_PREFERENCES,
    preferences,
  };
};

export const updatePreferencesAndCalculateRoute = preferences => dispatch => {
  dispatch(updatePreferences(preferences));
  dispatch(calculateRoute());
};

export const resetPreferencesAndCalculateRoute = () => dispatch => {
  dispatch(resetPreferences());
  dispatch(calculateRoute());
};

export const toggleWithinCountry = disabled => ({
  type: types.TOGGLE_WITHIN_COUNTRY,
  disabled,
});

export const setRestrictionInitialValue = payload => ({
  type: types.SET_RESTRICTION_INITIAL_VALUE,
  payload,
});

export const setShowLightVersion = showLightVersion => ({
  type: types.SET_SHOW_LIGHT_VERSION,
  showLightVersion,
});

export const toggleSidebar = sidebarToggled => ({
  type: types.TOGGLE_SIDEBAR,
  sidebarToggled,
});

export const setDirectionInitialValue = payload => (dispatch, getState) => {
  const { name } = payload;

  if (typeof payload.items === "undefined" || payload.items.length === 0) {
    return;
  }

  dispatch(increasePendingRequest());

  const keyPoints = payload.items;
  const ids = keyPoints.map(point => point.id).filter((value, index, self) => self.indexOf(value) === index);

  api.geo
    .getCities(ids)
    .then(response => {
      const cities = response.data;
      const { route } = getState();
      const suggestions = directionUtils.mapKeyPointsToSuggestions(keyPoints, cities);
      const newRoute = directionUtils.mergeRouteDirection(name, suggestions, route);
      const isWithinCountryShouldBeDisabled = routeService.checkWithinCountryShouldBeDisabled([
        newRoute.from.suggestion,
        newRoute.to.suggestion,
        ...newRoute.through.map(i => i.suggestion),
      ]);
      if (isWithinCountryShouldBeDisabled) {
        dispatch(setRestrictionInitialValue({ name: "withinCountry", value: false }));
      }
      dispatch(toggleWithinCountry(isWithinCountryShouldBeDisabled));
      dispatch(updateRouteDirection(newRoute));
      dispatch(decreasePendingRequest());
    })
    .catch(() => dispatch(decreasePendingRequest()));
};

export const setExcludedCitiesInitialValue = ids => dispatch => {
  if (ids.length === 0) return;

  dispatch(increasePendingRequest());

  api.geo
    .getCities(ids)
    .then(response => response.data)
    .then(entities =>
      entities.map(city => ({
        cityId: city.id,
        cityName: city.name,
      })),
    )
    .then(cities => dispatch(updateExcludedCities(cities)))
    .then(() => dispatch(decreasePendingRequest()))
    .catch(() => dispatch(decreasePendingRequest()));
};

export const setExcludedCountriesInitialValue = ids => dispatch => {
  if (ids.length === 0) return;

  dispatch(increasePendingRequest());

  api.geo
    .getCountries(ids)
    .then(response => response.data)
    .then(entities =>
      entities.map(country => ({
        countryId: country.id,
        countryName: country.name,
      })),
    )
    .then(countries => dispatch(updateExcludedCountries(countries)))
    .then(() => dispatch(decreasePendingRequest()))
    .catch(() => dispatch(decreasePendingRequest()));
};

export const hideErrorNotification = () => ({
  type: types.HIDE_ERROR_NOTIFICATION,
});

export const init = () => (dispatch, getState) => {
  const {
    route: {
      from: { value: fromCity },
      to: { value: toCity },
    },
  } = getState();

  if (fromCity && toCity) {
    dispatch(calculateRoute());
  } else {
    dispatch(setAppInitialized());
  }
};