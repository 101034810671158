import React from "react";

import Banner from "../Banner";

import styles from "./RightFixedBanner.scss";

export default () => (
  <div id="doc1501_max" className={styles.docPosition1501Max}>
    <Banner docPreset="allpages_300_300" minScreenWidth={1484} docCloseType="2" />
  </div>
);
