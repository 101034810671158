export const rotateAdvertising = () => {
  // eslint-disable-line
  try {
    const advHelper = window.advHelper || window.atiUserDataHelper; // eslint-disable-line
    if (advHelper) {
      const mngr = advHelper.getAdvManagerInstance();
      if (mngr) {
        mngr.rotateAtiAdv(true);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const changeBannersVisibility = () => {
  try {
    let doc = document.getElementById("docNoTrace1405_1500");
    if (doc) {
      doc.style.display = "none";
    }

    doc = document.getElementById("docNoTrace1201_1404");
    if (doc) {
      doc.style.display = "none";
    }

    doc = document.getElementById("docLeft560_767");
    if (doc) {
      doc.style.marginLeft = "32px";
    }

    doc = document.getElementById("docRight560_767");
    if (doc) {
      doc.style.display = "none";
    }
  } catch (e) {
    console.log(e);
  }
};
